import React from 'react';
import ReactPaginate from "react-paginate";

const Pagination = ({ pageCount, page, handlePageClick }) => {
    return (
        <nav className="pagination-wrapper" aria-label="Pagination">
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                initialPage={page ? --page : null}
                className={'pagination'}
                activeClassName={'active'}
                disabledClassName={'disabled-buttons'}
            />
        </nav>
    );
};

export default Pagination;
