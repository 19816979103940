import React, { useState, useEffect } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { Link, Prompt, useRouteMatch } from 'react-router-dom';
import { ReactComponent as LinkSvg } from '../../../assets/icon/link.svg';

import BezgotovinskiStavkeFieldArray from './BezgotovinskiStavkeFieldArray';
import { useDispatch, useSelector } from 'react-redux';
import {
    getRacun,
    getRacuni,
    getStavke,
    updateRacun,
} from '../../../store/actions/RacuniActions';
import BezgotovinskiPorezi from './BezgotovinskiPorezi';
import BezgotovinskiUkupno from './BezgotovinskiUkupno';
import BezgotovinskiHeader from './BezgotovinskiHeader';
import { PREDRACUNI, RACUNI, ULAZNI_RACUNI } from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { poreziService } from '../../../services/PoreziService';
import BezgotovinskiStavkeForm from "./BezgotovinskiStavkeForm";
import { racunSelector } from "../../../store/selectors/RacuniSelector";
import { updatePredracun } from "../../../store/actions/PredracuniActions";
import { toast } from "react-toastify";
import BezgotovinskiAvansniReferenceNaIkof from './BezgotovinskiAvansniReferenceNaIkof';
import { getUlazniRacun, getUlazniRacuni, updateUlazniRacun } from '../../../store/actions/UlazniRacuniActions';
import { ulazniRacunSelector } from '../../../store/selectors/UlazniRacuniSelector';
import BezgotovinskiZavisniTroskovi from './BezgotovinskiZavisniTroskovi';
import BezgotovinskiZavisniTroskoviFieldArray from './BezgotovinskiZavisniTroskoviFieldArray';
import { renderTitles } from '../../../helpers/bezgotovinskiForm';
import BezgotovinskiFooter from './BezgotovinskiFooter';
import BezgotovinskiDokumenta from "./BezgotovinskiDokumenta";
import BezgotovinskiOdobrenjaReferenceNaIkof from './BezgotovinskiOdobrenjaReferenceNaIkof';

toast.configure();

const toastSettings = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const BezgotovinskiEdit = ({ tip }) => {
    const { params } = useRouteMatch();
    const dispatch = useDispatch();
    const history = useHistory();
    const [porezi, setPorezi] = useState([]);
    const pathName = window.location.pathname;
    const [files, setFiles] = useState([]);

    const titles = renderTitles(pathName)

    useEffect(() => {
        dispatch(getStavke());
        (async () => setPorezi((await poreziService.getPorezi()).data))();
    }, [dispatch]);

    useEffect(() => {
        if (tip === 'ulazniRacun' && params.id) {
            dispatch(getUlazniRacun(params.id));
            return;
        }

        if (params.id) dispatch(getRacun(params.id));
    }, [params.id, dispatch]);

    const odabraniRacun = useSelector(tip === 'ulazniRacun' ? ulazniRacunSelector() : racunSelector());

    let odabraneStavke = [];
    useEffect(() => {
        if (odabraniRacun?.stavke || odabraniRacun?.ulazne_stavke) {
            makeOdabraniRacun(odabraniRacun.stavke ? odabraniRacun.stavke : odabraniRacun.ulazne_stavke);
        }

        if (odabraniRacun?.zavisni_troskovi) {
            makeZavisniTroskovi(odabraniRacun?.zavisni_troskovi);
        }
    }, [odabraneStavke]);

    function makeZavisniTroskovi(zavisni_troskovi) {
        zavisni_troskovi.map((zavisni_trosak) => {
            initialValues.zavisni_troskovi.push({
                id: zavisni_trosak.id,
                cijena_bez_pdv: zavisni_trosak.cijena_bez_pdv,
                ukupna_cijena: zavisni_trosak.ukupna_cijena,
                jedinica_mjere_id: zavisni_trosak.jedinica_id,
                naziv: zavisni_trosak.naziv,
                opis: zavisni_trosak.opis,
                broj_dokumenta: zavisni_trosak.broj_dokumenta,
                datum_izdavanja: zavisni_trosak.datum_izdavanja,
                datum_za_placanje: zavisni_trosak.datum_za_placanje,
                pdv_iznos: zavisni_trosak.pdv_iznos,
                partner_id: zavisni_trosak.partner_id,
            });
        })
    }

    function makeOdabraniRacun(stavke) {
        stavke.map((stavka) => {
            initialValues.stavke.push({
                id: stavka.id,
                cijena_bez_pdv: Math.abs(stavka.jedinicna_cijena_bez_pdv),
                cijena_bez_pdv_popust:  Math.abs(parseFloat(stavka.cijena_bez_pdv_popust)),
                cijena_sa_pdv_popust:  Math.abs(parseFloat(stavka.cijena_sa_pdv_popust)),
                ukupna_cijena:  Math.abs(stavka.cijena_sa_pdv),
                jedinica_mjere_id: stavka.jedinica_id,
                iznos_povrata_bez_pdv: Math.abs(stavka.iznos_povrata_bez_pdv),
                iznos_povrata_sa_pdv: Math.abs(stavka.iznos_povrata_sa_pdv),
                grupa: {
                  popust_iznos: stavka.popust_iznos,
                  popust_procenti: stavka.popust_procenat
                },
                jedinica_mjere: {
                    id: stavka.jedinica_id,
                    naziv: stavka.jedinica_naziv
                },
                kolicina: parseFloat(stavka.kolicina),
                naziv: stavka.naziv,
                opis: stavka.opis,
                porez_id: stavka.porez_id,
                porez: {
                    id: stavka.porez_id,
                    naziv: stavka.porez.naziv
                },
                preduzece_id: odabraniRacun.preduzece.id,
                tip_popusta: "procenat",
                usluga_id: 1,
                popust: parseFloat(stavka.popust_procenat) ? stavka.popust_procenat : null,
            });
        })
    }

    function dodajZavisneTroskoveNaStavke(e, values, setFieldValue) {
        e.preventDefault()
        const ukupnaCijenaZavisnihTroskova = values.zavisni_troskovi
                                        .map(zavisni_trosak => zavisni_trosak.ukupna_cijena)
                                        .reduce((a, b) => +a + +b);

        const zavisniTrosakPoStavki = ukupnaCijenaZavisnihTroskova / values.stavke.length;
        for (let i = 0; i < values.stavke.length; i++) {
            setFieldValue(`stavke[${i}].zavisni_troskovi`, zavisniTrosakPoStavki);
        }

        toast.success('Uspješno ste dodali zavisne troškove na stavke!',
            toastSettings
        );
    }

    const handleSubmit = (values) => {
        let fileArray = [];
        files.map((file) => {
            fileArray.push(file.getFileEncodeDataURL());
        })

        if (values.tip_racuna === 'knjizno' && ! values.referentni_ikofi.length) {
            toast.error('Niste unijeli referentne ikofe sa ovim kupcem',
                toastSettings
            );
            return;
        }

        let datum_izdavanja = new Date(values.datum_izdavanja);
        datum_izdavanja.setDate(datum_izdavanja.getDate() + 1);

        let datum_za_placanje = new Date(values.datum_za_placanje);
        datum_za_placanje.setDate(datum_za_placanje.getDate() + 1);

        const racun = {
            ...values,
            vrsta_racuna: 'bezgotovinski',
            id: odabraniRacun.id,
            popust_procenat: values.tip_popusta === 'procenat' ? values.popust : null,
            popust_iznos: values.tip_popusta === 'iznos' ? values.popust : null,
            popust_na_cijenu_bez_pdv: values.popust_bez_pdv,
            datum_izdavanja: datum_izdavanja.toISOString().split('T')[0],
            datum_za_placanje: datum_za_placanje.toISOString().split('T')[0],
            datum_uplate: values.datum_uplate?.toISOString().split('T')[0],
            korektivni_racun: values.korektivni_racun === '0' ? 0 : 1,
            korektivni_racun_vrsta: values.korektivni_racun === '0' ? null : values.korektivni_racun,
            fiskalizuj: values.fiskalizuj,
            napraviRacunIzPredracuna: values.napraviRacun,
            tip_slanja: values.tip_slanja ? values.tip_slanja : null,
            datum_slanja: values.datum_slanja ? values.datum_slanja : null,
            vrijeme: values.vrijeme ? values.vrijeme : null,
            vazi_do: values.vazi_do ? values.vazi_do : null,
            vrijeme_slanja: values.vrijeme_slanja ? values.vrijeme_slanja : null,
            tekst: values.tekst ? values.tekst : null,
            referentni_ikofi: values?.referentni_ikofi ? values?.referentni_ikofi : [],
            files: fileArray,
        };

        if (! values.partner_id) {
            toast.error('Kupac nedostaje! Izaberite kupca iz liste kupaca',
                toastSettings
            );
            return;
        } else if (! values.stavke.length) {
            toast.error('Stavke nedostaju! Morate unijeti makar jednu stavku. Kada izaberete stavku pritisnite dugme "Dodaj stavku na račun".',
                toastSettings
            );
            return;
        } else if (values.nacin_placanja === 'ADVANCE' && ! values.referentni_ikofi.length) {
            toast.error('Referentni račun mora biti označen kod avansnog plaćanja!',
                toastSettings
            );
            return;
        }

        if (pathName.includes('/predracuni')) {
            dispatch(updatePredracun(racun, history));
            dispatch(getRacuni({tip: 'predracun'}));
            history.push(PREDRACUNI.INDEX);
        } else if (pathName.includes('/ulazni-racuni')) {
            dispatch(updateUlazniRacun(racun, history));
            dispatch(getUlazniRacuni());
            history.push(ULAZNI_RACUNI.INDEX);
        } else {
            dispatch(updateRacun(racun, history));
            dispatch(getRacuni({tip: 'racun'}));
            history.push(RACUNI.INDEX);
        }
    };

    let initialValues = {
        stavke: [],
        zavisni_troskovi: [],
        korektivni_racun: '0',
        tip_racuna: odabraniRacun.tip_racuna,
        datum_izdavanja: isNaN(new Date(odabraniRacun.datum_izdavanja)) ? '' : new Date(odabraniRacun.datum_izdavanja),
        datum_za_placanje: isNaN(new Date(odabraniRacun.datum_za_placanje)) ? '' : new Date(odabraniRacun.datum_za_placanje),
        pdv_obveznik: odabraniRacun?.preduzece?.pdv_obveznik ? 1 : 0,
        status: odabraniRacun.status ?? 'nijeplacen',
        nacin_placanja: odabraniRacun.nacin_placanja ?? 'ACCOUNT',
        partner_id: odabraniRacun?.partner_id,
        niz_stavke: [],
        niz_zavisni_troskovi: [],
        referentni_ikofi: [],
        popustObjekat: {},
        tip_slanja: odabraniRacun?.tip_slanja ? odabraniRacun?.tip_slanja : null,
        datum_slanja: odabraniRacun?.datum_slanja ? odabraniRacun?.datum_slanja : null,
        vrijeme: odabraniRacun?.vrijeme ? odabraniRacun?.vrijeme : null,
        vazi_do: odabraniRacun?.vazi_do ? odabraniRacun?.vazi_do : null,
        vrijeme_slanja: odabraniRacun?.podsjetnici ? odabraniRacun?.podsjetnici[0]?.vrijeme_slanja : null,
        tekst: odabraniRacun?.podsjetnici ? odabraniRacun?.podsjetnici[0]?.tekst : null,
        redni_broj: odabraniRacun?.redni_broj,
    };

    const renderStavkeForm = (values) => {
        if (values.tip_racuna === 'avans' && values.stavke.length > 0) {
            return null;
        }

        return (
            <>
                <h2 className="heading-secondary">
                  {values.tip_racuna === 'racun' || values.tip_racuna === 'predracun' ? (
                    'Nova stavka / pretraga stavki'
                  ) : (
                    'Nova stavka'
                  )}
                </h2>
                <div className="main-content__box" style={{ display: 'block' }}>
                    <div className="main-content__box--header">
                        <div className="row">
                            <div className="col-xl-4 col-md-4 col-12">
                                <h4 className="heading-quaternary">
                                    Naziv usluge / robe
                                </h4>
                            </div>
                            <div className="col-xl-2 col-md-2 tabp-none">
                                <h4 className="heading-quaternary">
                                    jedinična cijena
                                </h4>
                            </div>
                            <div className="col-xl-2 col-md-2 tabp-none">
                                <h4 className="heading-quaternary">
                                    JM / Količina
                                </h4>
                            </div>
                            <div className="col-xl-2 col-md-2 tabp-none">
                                <h4 className="heading-quaternary">
                                    PDV stopa
                                </h4>
                            </div>
                            <div className="col-xl-2 col-md-2 tabp-none">
                                <h4 className="heading-quaternary txt-right">
                                    Tip Popusta/iznos
                                </h4>
                            </div>
                        </div>
                    </div>
                    <BezgotovinskiStavkeForm pathName={pathName} />
                </div>
            </>
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, dirty, isSubmitting }) => (
                <Form>
                    <Prompt
                        when={dirty && ! isSubmitting}
                        message="Ukoliko nastavite podaci koje ste unijeli neće biti sačuvani"
                    />
                    <div className="screen-content">
                        <Link to={titles.link} className="back-link df">
                            <LinkSvg />
                            <p>{titles.povratak}</p>
                        </Link>

                        <h1 className="heading-primary">
                            {titles.title}
                        </h1>

                        <BezgotovinskiHeader
                            pathName={pathName}
                            kupac={
                                odabraniRacun?.partner?.preduzece_partner?.kratki_naziv ??
                                odabraniRacun?.partner?.fizicko_lice.ime + " "  + odabraniRacun?.partner?.fizicko_lice.prezime
                            }
                            tip_racuna={odabraniRacun?.tip_racuna}
                            nacin_placanja={odabraniRacun?.nacin_placanja}
                        />

                        {/* STAVKE */}
                        {renderStavkeForm(values)}

                        <FieldArray name="stavke">
                            {(arrayHelpers) => (
                                <BezgotovinskiStavkeFieldArray {...arrayHelpers} tip={tip} pathName={pathName} />
                            )}
                        </FieldArray>
                        {/* STAVKE */}
                        {console.log('VALUES', values)}
                        {values.nacin_placanja === 'ADVANCE' && values.partner_id && values.tip_racuna === 'racun' ? (
                            <BezgotovinskiAvansniReferenceNaIkof />
                        ) : null}

                        {values.tip_racuna === 'knjizno' && values.partner_id && (
                            <BezgotovinskiOdobrenjaReferenceNaIkof />
                        )}

                        {pathName === '/ulazni-racuni/create' || tip === 'ulazniRacun' && (
                            <>
                                <h2 className="heading-secondary">Novi zavisni troškovi</h2>
                                <div className="main-content__box" style={{ display: 'block' }}>
                                    <div className="main-content__box--header">
                                        <div className="row">
                                            <div className="col-xl-4 col-md-4 col-12">
                                                <h4 className="heading-quaternary">
                                                    Naziv usluge / robe
                                                </h4>
                                            </div>
                                            <div className="col-xl-2 col-md-2 tabp-none">
                                                <h4 className="heading-quaternary">
                                                    jedinična cijena
                                                </h4>
                                            </div>
                                            <div className="col-xl-2 col-md-2 tabp-none">
                                                <h4 className="heading-quaternary">
                                                    JM / Količina
                                                </h4>
                                            </div>
                                            <div className="col-xl-2 col-md-2 tabp-none">
                                                <h4 className="heading-quaternary">
                                                    PDV stopa
                                                </h4>
                                            </div>
                                            <div className="col-xl-2 col-md-2 tabp-none">
                                                <h4 className="heading-quaternary txt-right">
                                                    Tip Popusta/iznos
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <BezgotovinskiZavisniTroskovi />
                                </div>

                                <FieldArray name="zavisni_troskovi">
                                    {(arrayHelpers) => (
                                        <BezgotovinskiZavisniTroskoviFieldArray {...arrayHelpers} />
                                    )}
                                </FieldArray>

                                <button
                                    className="btn btn__primary mb-4"
                                    onClick={(e) => dodajZavisneTroskoveNaStavke(e, values, setFieldValue)}
                                >
                                    Podijeli zavisne troškove po stavkama
                                </button>
                            </>
                        )}

                        <BezgotovinskiPorezi />
                        <BezgotovinskiUkupno odabraniRacun={odabraniRacun} />

                        {pathName.includes('/ulazni-racuni') && (
                            <BezgotovinskiDokumenta files={files} setFiles={setFiles} />
                        )}

                        <BezgotovinskiFooter pathName={pathName} history={history} />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default BezgotovinskiEdit;
