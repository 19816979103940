import React from 'react';
import Moment from "react-moment";
import { useHistory } from 'react-router-dom';

const NovostiTableRow = ({ item, url }) => {
    const history = useHistory();

    const handleClick = () => {
        history.push(`/novosti/${item.slug}`);
    };

    function createMarkup(value) {
        return {__html: value};
    }

    const pageBreak = '<!-- pagebreak -->';
    const breakedText = `${item.tekst.substring(0, item.tekst.lastIndexOf(pageBreak) + pageBreak.length)}`;

    const renderTekst = () => {
        if (item.tekst.includes(pageBreak)) {
            return (
                <span style={{ display: "flex" }}>
                    <p dangerouslySetInnerHTML={createMarkup(breakedText)} />
                    <a onClick={handleClick}>Prikaži kompletnu novost&hellip;</a>
                </span>
            );
        } else {
            return (
                <p dangerouslySetInnerHTML={createMarkup(item.tekst)} />
            );
        }
    }

    return (
        <div className="screen-content">
            <div className="main-content__box">
                <div className="content" style={{ padding: '24px' }}>
                    <h2 className="heading-secondary secondary-news mb-0" onClick={handleClick}>
                        { item.naziv.substring(0, 155) }{ item.naziv.length > 155 ? '...' : '' }
                    </h2>
                    <hr className="mtb-20"/>
                    <div className="df jc-sb ai-c">
                        <p className="txt-light">
                            <Moment locale="me" format="DD. MMM YYYY.">
                                { item.created_at }
                            </Moment>
                        </p>
                        <a className="df">
                            <span className="tag tag__neutral txt-dark">
                                { item.categories.naziv }
                            </span>
                        </a>
                    </div>
                    <img
                        className="img"
                        src={`${url}/storage/${item.slika}`}
                        alt='slika'
                    />
                    <div className="box-news__content txt-light">
                        { renderTekst() }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NovostiTableRow;
