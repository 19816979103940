import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import List from '../shared/lists/List';
import NovostiTableRow from './NovostiTableRow';
import { BASE_URL } from "../../config";
import { getNovosti } from '../../store/actions/NovostiActions';
import Pagination from "../shared/lists/Pagination";

const NovostiTable = ({ novosti, page }) => {
    const dispatch = useDispatch();
    const url = BASE_URL.slice(0, -4)

    const history = useHistory();
    const { last_page } = novosti;

    const handlePageClick = (data) => {
        history.push(`/novosti?page=${++data.selected}`);
        dispatch(getNovosti({ page: data.selected }));
    }

    return (
        <>
            <List
                data={novosti?.data}
                renderItem={NovostiTableRow}
                url={url}
            />
            <Pagination
                pageCount={last_page}
                page={page}
                handlePageClick={handlePageClick}
            />
        </>
    );
};

export default NovostiTable;
