import {
    GET_NOVOSTI,
    SET_NOVOSTI,
    GET_NOVOST,
    SET_NOVOST,
    GET_NEPROCITANE,
    SET_NEPROCITANE,
    GET_POSLEDNJE,
    SET_POSLEDNJE
} from '../actionTypes/NovostiActionTypes';

export const getNovosti = (payload) => ({
    type: GET_NOVOSTI,
    payload,
});

export const setNovosti = (payload) => ({
    type: SET_NOVOSTI,
    payload,
});

export const getNovost = (payload) => ({
    type: GET_NOVOST,
    payload,
});

export const setNovost = (payload) => ({
    type: SET_NOVOST,
    payload,
});

export const getNeprocitaneNovosti = (payload) => ({
    type: GET_NEPROCITANE,
    payload,
});

export const setNeprocitaneNovosti = (payload) => ({
    type: SET_NEPROCITANE,
    payload,
});

export const getPoslednjeNovosti = (payload) => ({
    type: GET_POSLEDNJE,
    payload,
});

export const setPoslednjeNovosti = (payload) => ({
    type: SET_POSLEDNJE,
    payload,
});



