import {
  DELETE_PREDRACUN,
  GET_PREDRACUN,
  SET_PREDRACUN,
  STORE_PREDRACUN,
  UPDATE_PREDRACUN,
} from '../actionTypes/PredracuniActionTypes';

export const storePredracun = (payload, history) => ({
  type: STORE_PREDRACUN,
  payload: {payload, history}
});

export const getPredracun = (payload) => ({
  type: GET_PREDRACUN,
  payload,
});

export const setPredracun = (payload) => ({
  type: SET_PREDRACUN,
  payload,
});

export const updatePredracun = (payload, history) => ({
  type: UPDATE_PREDRACUN,
  payload: {payload, history},
});

export const deletePredracun = (payload) => ({
  type: DELETE_PREDRACUN,
  payload,
});
