import React from 'react';
import { useDispatch } from 'react-redux';
import { getRacuni } from '../../store/actions/RacuniActions';
import PredracuniTableRow from './PredracunTableRow';
import { useHistory } from "react-router-dom";
import Pagination from "../shared/lists/Pagination";

const PredracuniTable = ({ predracuni, searchParams, page }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { last_page } = predracuni;

  const handlePageClick = (data) => {
    history.push(`/predracuni?page=${++data.selected}`);
    dispatch(getRacuni({ page: data.selected, ...searchParams, tip: 'predracun' }));
  }

  return (
    <>
      <div className="table-wrapper">
        <table className="table cursor">
          <thead>
            <tr>
              <th className="w-auto">
                <span className="heading-quaternary bm-none">Broj</span>
              </th>
              <th>
                <span className="heading-quaternary">Preduzeće/Lice</span>
              </th>
              <th className="dshow-cell">
                <span className="heading-quaternary">Iznos bez PDV</span>
              </th>
              <th className="dshow-cell">
                <span className="heading-quaternary">PDV</span>
              </th>
              <th>
                <span className="heading-quaternary">Iznos sa PDV</span>
              </th>
              <th>
                <span className="heading-quaternary">Status</span>
              </th>
              <th>
                <span className="heading-quaternary">Datum</span>
              </th>
              <th className="w-5">
                <span className="heading-quaternary"> </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {predracuni && (
              <List
                data={
                  predracuni.data.filter(
                    (predracun) => predracun.tip_racuna === 'predracun'
                  ) || []
                }
                renderItem={PredracuniTableRow}
              />
            )} */}
            {predracuni &&
              predracuni.data
                .filter((predracun) => predracun.tip_racuna === 'predracun')
                .map((item) => (
                  <PredracuniTableRow
                    key={item.id}
                    item={item}
                    predracuni={predracuni}
                  />
                ))}
          </tbody>
        </table>
      </div>
      <Pagination
        pageCount={last_page}
        page={page}
        handlePageClick={handlePageClick}
      />
    </>
  );
};

export default PredracuniTable;
