import { call, put } from "redux-saga/effects";
import { izvjestajiService } from "../../services/IzvjestajiService";
import { setIzvjestaj } from "../actions/IzvjestajiActions";
import { setGlobalError } from "../actions/ErrorActions";

export function* izvjestajGet({ payload }) {
    try {
        const res = yield call(izvjestajiService.getIzvjestaj, payload);
        yield put(setIzvjestaj(res.data));
    } catch (error) {
        yield put(setGlobalError(error.message));
    }
}