export const STORE_USLUGA = 'STORE_USLUGA';

export const GET_USLUGE = 'GET_USLUGE';
export const SET_USLUGE = 'SET_USLUGE';

export const GET_USLUGA = 'GET_USLUGA';
export const SET_USLUGA = 'SET_USLUGA';

export const UPDATE_USLUGA = 'UPDATE_USLUGA';
export const DELETE_USLUGA = 'DELETE_USLUGA';

export const GET_POREZI = 'GET_POREZI';
export const SET_POREZI = 'SET_POREZI';
