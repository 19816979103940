import { Field, useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { formatirajCijenu } from "../../../helpers/racuni";
import { getIkofReference } from "../../../store/actions/RacuniActions";
import { ikofReferenceSelector } from "../../../store/selectors/RacuniSelector";
import { ReactComponent as DeleteIcon } from '../../../assets/icon/x-delete.svg';
import ReactDOM from "react-dom";
import Modal from "../../shared/forms/Modal";

toast.configure();

const toastSettings = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const BezgotovinskiOdobrenjaReferenceNaIkof = () => {
    const dispatch = useDispatch();
    const { values, setFieldValue } = useFormikContext();
    const [ ukupnaCijenaIzabranihRacuna, setukupnaCijenaIzabranihRacuna ] = useState(0);

    const ikofReference = useSelector(ikofReferenceSelector());

    useEffect(() => {
        dispatch(getIkofReference({partner_id: values.partner_id}));
    }, [dispatch, values.partner_id]);

    useEffect(() => {
        let odabraniRacuni = [];
        values?.referentni_ikofi?.map((ikof) => {
            if (Array.isArray(ikofReference)) {
                ikofReference.filter((racun) => racun.ikof === ikof)
                    .map((item) => (
                        odabraniRacuni.push(parseFloat(item.ukupna_cijena_sa_pdv_popust))
                    ))
            }
        });

        let preostalaCijenaAvansnih = odabraniRacuni.reduce((a, b) =>  a = a + b , 0);

        let ukupnoSaPdv;
        if (values.tip_racuna === 'knjizno') {
            ukupnoSaPdv = Object.values(values.stavke).reduce((r, { cijena_sa_pdv_popust, kolicina, iznos_povrata_sa_pdv }) => r + cijena_sa_pdv_popust * kolicina + iznos_povrata_sa_pdv, 0);
        } else {
            ukupnoSaPdv = Object.values(values.stavke).reduce((r, { cijena_sa_pdv_popust, kolicina }) => r + cijena_sa_pdv_popust * kolicina, 0);
        }

        setukupnaCijenaIzabranihRacuna(preostalaCijenaAvansnih);

        if (preostalaCijenaAvansnih < ukupnoSaPdv && preostalaCijenaAvansnih !== 0) {
            toast.info('Ukupan iznos ovog odobrenja ne može biti veći od izabranog/ih računa!',
                toastSettings
            );
        }
    }, [values.referentni_ikofi]);

    function prikaziOdobrenja(e, racun) {
        e.stopPropagation();
        const node = document.getElementById("custom-prompt");

        const cleanUp = (answer) => {
            ReactDOM.unmountComponentAtNode(node);
        };

        const cancel = () => cleanUp(false);

        ReactDOM.render(
            <Modal
                title="Lista knjižnih odobrenja"
                message="Sva izdata knjižna odobrenja vezana za ovaj račun:"
                racun={racun}
                onConfirm={cancel}
                onCancel={cancel}
                confirmText="Zatvori"
                cancelText=""
            />
            , node
        );
    }

    if (! ikofReference.length) {
        return <div class="main-content__box"><div class="content"><div class="main-content__box--inner-wrapper p-4"><p className="my-4">Nema izdatih računa prema ovom kupcu!</p></div></div></div>;
    }

    return (
        <>
            <h2 className="heading-secondary">Izdati računi</h2>
            <div className="main-content__box">
                <div className="content">
                    <div className="table-wrapper">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="cd">
                                        <p className="heading-quaternary txt-left">
                                            Označite račun
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Redni broj računa
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Ukupna cijena bez pdv
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Ukupan PDV
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Ukupna cijena
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Odobrenja
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                        </p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(ikofReference).map((ikofReferenceId) => {
                                    const racun = ikofReference[ikofReferenceId];

                                    return (
                                        <tr>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="checkbox"
                                                    name="referentni_ikofi"
                                                    value={racun.ikof}
                                                />
                                            </td>
                                            <td>
                                                { racun.redni_broj }/{new Date(racun.datum_izdavanja).getFullYear()}
                                            </td>
                                            <td>{ formatirajCijenu(racun.ukupna_cijena_bez_pdv_popust) }</td>
                                            <td>{ formatirajCijenu(racun.ukupna_cijena_sa_pdv_popust - racun.ukupna_cijena_bez_pdv_popust) }</td>
                                            <td>{ formatirajCijenu(racun.ukupna_cijena_sa_pdv_popust) }</td>
                                            <td>
                                                <a>
                                                    {racun.ikof_referenca.length ? (
                                                        <span className="pointer" onClick={(e) => prikaziOdobrenja(e, racun)}>
                                                            Izdata odobrenja
                                                        </span>
                                                    ) : (
                                                        <span className="btn">
                                                            Nema
                                                        </span>
                                                    )}
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`/racuni/bezgotovinski/show/${racun.id}`} target="_blank">
                                                    <span className="btn btn__close danger">
                                                        <DeleteIcon />
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="m-4">
                        Iznos na odabranim računima: { formatirajCijenu(ukupnaCijenaIzabranihRacuna) }
                    </div>
                </div>
            </div>
        </>
    );
};

export default BezgotovinskiOdobrenjaReferenceNaIkof;
