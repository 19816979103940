import { useFormikContext } from 'formik';
import React from 'react';
import InputField from '../shared/forms/InputField';
import $t from '../../lang';
import { ReactComponent as Plus } from '../../assets/icon/plus.svg';

const ZiroRacuniFieldArray = ({ insert, remove }) => {
  const { values } = useFormikContext();
  const getBank = (broj_racuna) => {
    if (broj_racuna) {
      const prvaTri = broj_racuna.substring(0, 3);

      if (prvaTri.includes('550')) {
        return 'Podgorička';
      } else if (prvaTri.includes('535')) {
        return 'Prva Banka CG';
      } else if (prvaTri.includes('555')) {
        return 'Addiko';
      } else if (prvaTri.includes('510')) {
        return 'CKB';
      } else if (prvaTri.includes('530')) {
        return 'NLB';
      } else if (prvaTri.includes('540')) {
        return 'ERSTE';
      } else if (prvaTri.includes('520')) {
        return 'Hipotekarna';
      } else if (prvaTri.includes('525')) {
        return 'Komercijalna';
      } else if (prvaTri.includes('560')) {
        return 'Universal Capital';
      } else if (prvaTri.includes('570')) {
        return 'Zapad Banka';
      } else if (prvaTri.includes('575')) {
        return 'Ziraat Bank';
      } else if (prvaTri.includes('565')) {
        return 'Lovćen Banka';
      } else if (prvaTri.includes('580')) {
        return 'Adriatic Bank';
      }
    }
  };
  return (
    <div className="form__group w-100">
      <label className="form__label" htmlFor="lista-racuna">
        Lista računa
      </label>

      {values.ziro_racuni.map((ziro_racun, index) => (
        <div className="pr df ai-c mb-20" key={index}>
          <InputField
            label=""
            obavezno
            className="form__input w-100 "
            name={`ziro_racuni.${index}.broj_racuna`}
          />
          <span className="form__span">{getBank(ziro_racun.broj_racuna)}</span>
          <button
            className="btn btn__link danger mb-0 pl-3"
            type="button"
            onClick={() => remove(index)}
          >
            {$t('common.izbrisi')}
          </button>
        </div>
      ))}
      <div className="df ai-c">
        <button
          type="button"
          className="btn btn__link df mt-20"
          onClick={() => insert(values.ziro_racuni.length, '')}
        >
          <Plus className="icon icon__stroke-link sm " />
          <span className="btn btn__link success">{$t('common.dodajNovi')}</span>
        </button>
      </div>
    </div>
  );
};
export default ZiroRacuniFieldArray;
