import React, { useState, useEffect } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { Link, Prompt } from 'react-router-dom';
import { ReactComponent as LinkSvg } from '../../../assets/icon/link.svg';

import BezgotovinskiStavkeFieldArray from './BezgotovinskiStavkeFieldArray';
import { useDispatch } from 'react-redux';
import {
  getRacuni,
  getStavke,
  storeBezgotovinskiRacun,
} from '../../../store/actions/RacuniActions';
import {
    getZavisniTroskovi,
  } from '../../../store/actions/ZavisniTroskoviActions';
import BezgotovinskiPorezi from './BezgotovinskiPorezi';
import BezgotovinskiUkupno from './BezgotovinskiUkupno';
import BezgotovinskiHeader from './BezgotovinskiHeader';
import { RACUNI, PREDRACUNI, ULAZNI_RACUNI } from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { poreziService } from '../../../services/PoreziService';
import BezgotovinskiStavkeForm from "./BezgotovinskiStavkeForm";
import { storePredracun } from "../../../store/actions/PredracuniActions";
import { toast } from "react-toastify";
import BezgotovinskiAvansniReferenceNaIkof from './BezgotovinskiAvansniReferenceNaIkof';
import BezgotovinskiOdobrenjaReferenceNaIkof from './BezgotovinskiOdobrenjaReferenceNaIkof';
import { renderTitles } from '../../../helpers/bezgotovinskiForm';
import { getUlazniRacuni, storeUlazniRacun } from '../../../store/actions/UlazniRacuniActions';
import BezgotovinskiFooter from './BezgotovinskiFooter';
import BezgotovinskiZavisniTroskovi from './BezgotovinskiZavisniTroskovi';
import BezgotovinskiZavisniTroskoviFieldArray from './BezgotovinskiZavisniTroskoviFieldArray';
import BezgotovinskiDokumenta from './BezgotovinskiDokumenta';

toast.configure();

const toastSettings = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const Bezgotovinski = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pathName = window.location.pathname;
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(false);

  const [porezi, setPorezi] = useState([]);

  const titles = renderTitles(pathName)

  useEffect(() => {
    dispatch(getStavke());
    if (pathName.includes('/ulazni-racuni')) {
        dispatch(getZavisniTroskovi());
    }

    (async () => setPorezi((await poreziService.getPorezi()).data))();
  }, [dispatch]);

  const handleSubmit = (values) => {
    let fileArray = [];
    files.map((file) => {
      fileArray.push(file.getFileEncodeDataURL());
    })

    if (values.tip_racuna === 'knjizno' && ! values.referentni_ikofi.length) {
        toast.error('Morate odabrati referentne račune za ovog kupca!',
            toastSettings
        );
        return;
    }

    const noviRacun = {
      ...values,
      vrsta_racuna: 'bezgotovinski',
      popust_procenat: values.tip_popusta === 'procenat' ? values.popust : null,
      popust_iznos: values.tip_popusta === 'iznos' ? values.popust : null,
      popust_na_cijenu_bez_pdv: values.popust_bez_pdv,
      datum_izdavanja: values.datum_izdavanja?.toISOString().split('T')[0],
      datum_za_placanje: values.datum_za_placanje?.toISOString().split('T')[0],
      datum_uplate: values.datum_uplate?.toISOString().split('T')[0],
      korektivni_racun: values.korektivni_racun === '0' ? 0 : 1,
      korektivni_racun_vrsta: values.korektivni_racun === '0' ? null : values.korektivni_racun,
      fiskalizuj: values.fiskalizuj,
      napraviRacunIzPredracuna: values.napraviRacun,
      tip_slanja: values.tip_slanja ? values.tip_slanja : null,
      datum_slanja: values.datum_slanja ? values.datum_slanja : null,
      vrijeme: values.vrijeme ? values.vrijeme : null,
      vazi_do: values.vazi_do ? values.vazi_do : null,
      vrijeme_slanja: values.vrijeme_slanja !== 'bez_slanja' ? values.vrijeme_slanja : null,
      tekst: values.tekst ? values.tekst : null,
      referentni_ikofi: values?.referentni_ikofi ? values?.referentni_ikofi : [],
      files: fileArray,
      status: values?.nacin_placanja === 'ADVANCE' && ! values?.vise_nacina_placanja ? 'placen' : values?.status
    };

    if (! values.partner_id) {
        toast.error('Kupac nedostaje! Izaberite kupca iz liste kupaca',
            toastSettings
        );
        return;
    } else if (values.vrijeme_slanja !== 'bez_slanja' && ! values.tekst) {
        toast.error('Morate unijeti tekst za podsjetnik',
            toastSettings
        );
        return;
    } else if (! values.stavke.length) {
        toast.error('Stavke nedostaju! Morate unijeti makar jednu stavku. Kada izaberete stavku pritisnite dugme "Dodaj stavku na račun".',
            toastSettings
        );
        return;
    } else if (values.nacin_placanja === 'ADVANCE' && ! values.referentni_ikofi.length) {
        toast.error('Morate odbarati referentne račune kod avansnog plaćanja!',
            toastSettings
        );
        return;
    }

    switch(pathName) {
      case '/predracuni/create':
          dispatch(storePredracun(noviRacun, history));
          dispatch(getRacuni({tip: 'predracun'}));
          history.push(PREDRACUNI.INDEX);
          break;
      case '/ulazni-racuni/create':
          dispatch(storeUlazniRacun(noviRacun, history));
          dispatch(getUlazniRacuni());
          history.push(ULAZNI_RACUNI.INDEX);
          break;
      default:
          dispatch(storeBezgotovinskiRacun(noviRacun, history));
          dispatch(getRacuni({tip: 'racun'}));
          history.push(RACUNI.INDEX);
    }
  };

  const today = new Date();
  const seven_days = new Date();
  seven_days.setDate(seven_days.getDate() + 7);

  const initialValues = {
    stavke: [],
    zavisni_troskovi: [],
    korektivni_racun: '0',
    datum_izdavanja: today,
    datum_za_placanje: seven_days,
    pdv_obveznik: 1,
    status: pathName === '/predracuni/create' ? 'kreiran' : 'nijeplacen',
    nacin_placanja: 'ACCOUNT',
    niz_stavke: [],
    niz_zavisni_troskovi: [],
    referentni_ikofi: [],
    popustObjekat: {},
    tip_racuna: 'racun',
    vrijeme_slanja: 'bez_slanja',
  };

  function dodajZavisneTroskoveNaStavke(e, values, setFieldValue) {
    e.preventDefault()
    const ukupnaCijenaZavisnihTroskova = values.zavisni_troskovi
                                    .map(zavisni_trosak => zavisni_trosak.cijena_bez_pdv)
                                    .reduce((a, b) => +a + +b);

    const zavisniTrosakPoStavki = ukupnaCijenaZavisnihTroskova / values.stavke.length;
    for (let i = 0; i < values.stavke.length; i++) {
        setFieldValue(`stavke[${i}].zavisni_troskovi`, zavisniTrosakPoStavki);
    }

    toast.success('Uspješno ste dodali zavisne troškove na stavke!',
        toastSettings
    );
  }

  const renderStavkeForm = (values) => {
      if (values.tip_racuna === 'avans' && values.stavke.length > 0) {
          return null;
      }

      return (
          <>
              <h2 className="heading-secondary">
                  {values.tip_racuna === 'racun' || values.tip_racuna === 'predracun' ? (
                    'Nova stavka / pretraga stavki'
                  ) : (
                    'Nova stavka'
                  )}
              </h2>
              <div className="main-content__box" style={{ display: 'block' }}>
                  <div className="main-content__box--header">
                      <div className="row">
                          <div className="col-xl-4 col-md-4 col-12">
                              <h4 className="heading-quaternary">
                                  Naziv usluge / robe
                              </h4>
                          </div>
                          <div className="col-xl-2 col-md-2 tabp-none">
                              <h4 className="heading-quaternary">
                                  jedinična cijena
                              </h4>
                          </div>
                          <div className="col-xl-2 col-md-2 tabp-none">
                              <h4 className="heading-quaternary">
                                  JM / Količina
                              </h4>
                          </div>
                          <div className="col-xl-2 col-md-2 tabp-none">
                              <h4 className="heading-quaternary">
                                  PDV stopa
                              </h4>
                          </div>
                          <div className="col-xl-2 col-md-2 tabp-none">
                              <h4 className="heading-quaternary txt-right">
                                  {values.tip_racuna === 'knjizno' ? (
                                    'Iznos povrata'
                                  ) : (
                                    'Tip Popusta/iznos'
                                  )}
                              </h4>
                          </div>
                      </div>
                  </div>
                  <BezgotovinskiStavkeForm pathName={pathName} show={show} setShow={setShow} />
              </div>
          </>
      );
  }

  return (
    <Formik
      initialValues={initialValues}
      //innerRef={formikRef}
      // enableReinitialize
      onSubmit={handleSubmit}
      // validationSchema={BezgotovinskiSchema}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {({ values, dirty, setFieldValue, isSubmitting }) => (

        <Form>
          <Prompt
            when={dirty && ! isSubmitting}
            message="Ukoliko nastavite podaci koje ste unijeli neće biti sačuvani"
          />
          <div className="screen-content">
            <Link to={titles.link} className="back-link df">
              <LinkSvg />
              <p>
                {titles.povratak}
              </p>
            </Link>

            <h1 className="heading-primary">
                {titles.title}
            </h1>

            <BezgotovinskiHeader pathName={pathName} />

            {console.log('VALUES', values)}
            {/* STAVKE */}
            {renderStavkeForm(values)}

            <FieldArray name="stavke">
              {(arrayHelpers) => (
                  <BezgotovinskiStavkeFieldArray {...arrayHelpers} pathName={pathName} setShow={setShow} />
              )}
            </FieldArray>
            {/* STAVKE */}

            {values.nacin_placanja === 'ADVANCE' && values.partner_id && values.tip_racuna === 'racun' && (
                <BezgotovinskiAvansniReferenceNaIkof />
            )}

            {values.tip_racuna === 'knjizno' && values.partner_id && (
                <BezgotovinskiOdobrenjaReferenceNaIkof />
            )}

            {pathName === '/ulazni-racuni/create' && (
                <>
                  <h2 className="heading-secondary">Novi zavisni troškovi</h2>
                  <div className="main-content__box" style={{ display: 'block' }}>
                      <div className="main-content__box--header">
                          <div className="row">
                              <div className="col-xl-4 col-md-4 col-12">
                                  <h4 className="heading-quaternary">
                                      naziv / broj dokumenta
                                  </h4>
                              </div>
                              <div className="col-xl-2 col-md-2 tabp-none">
                                  <h4 className="heading-quaternary">
                                      datum
                                  </h4>
                              </div>
                              <div className="col-xl-2 col-md-2 tabp-none">
                                  <h4 className="heading-quaternary">
                                      opšta / snižena
                                  </h4>
                              </div>
                              <div className="col-xl-2 col-md-2 tabp-none">
                                  <h4 className="heading-quaternary">
                                    oslob. / partner
                                  </h4>
                              </div>
                              <div className="col-xl-2 col-md-2 tabp-none">
                                  <h4 className="heading-quaternary txt-right">
                                      Ukupna cijena
                                  </h4>
                              </div>
                          </div>
                      </div>
                      <BezgotovinskiZavisniTroskovi />
                  </div>

                  <FieldArray name="zavisni_troskovi">
                    {(arrayHelpers) => (
                        <BezgotovinskiZavisniTroskoviFieldArray {...arrayHelpers} />
                    )}
                  </FieldArray>

                  <button
                    className="btn btn__primary mb-4"
                    onClick={(e) => dodajZavisneTroskoveNaStavke(e, values, setFieldValue)}
                  >
                    Podijeli zavisne troškove po stavkama
                  </button>
              </>
            )}

            <BezgotovinskiPorezi />
            <BezgotovinskiUkupno />

            {pathName === '/ulazni-racuni/create' && (
                <BezgotovinskiDokumenta files={files} setFiles={setFiles} />
            )}

            <BezgotovinskiFooter pathName={pathName} history={history} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Bezgotovinski;
