import { call, put } from 'redux-saga/effects';
import { setGlobalError } from '../actions/ErrorActions';
import { setRacun } from '../actions/RacuniActions';
import { ulazniRacuniService } from '../../services/UlazniRacuniService';
import {
  getUlazniRacuni,
  setUlazniRacun,
  setUlazniRacuni,
} from '../actions/UlazniRacuniActions';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export function* ulazniRacunStore({ payload }) {
  try {
    localStorage.setItem('previousUrl', window.location.pathname);
    const res = yield call(ulazniRacuniService.storeUlazniRacun, payload.payload);

    yield payload.history.replace('/ulazni-racuni/' + res.data.id);
    let redniBroj = res.data.redni_broj ? res.data.redni_broj : '';

    toast.success(
        'Bezgotovinski račun je sačuvan: ' + redniBroj,
        toastSettings
    );
  } catch (error) {
    yield put(setGlobalError(error.response.data.error));
    yield put(getUlazniRacuni());
  }
}

export function* ulazniRacuniGet({ payload }) {
  try {
    const res = yield call(ulazniRacuniService.getUlazniRacuni, payload);
    yield put(setUlazniRacuni(res.data));
  } catch (error) {
    yield put(setGlobalError(error.message));
  }
}

export function* ulazniRacunGet({ payload }) {
  try {
    const res = yield call(ulazniRacuniService.getUlazniRacun, payload);
    yield put(setUlazniRacun(res.data));
  } catch (error) {
    yield put(setGlobalError(error.message));
  }
}

export function* ulazniRacunUpdate({ payload }) {
  try {
    const res = yield call(ulazniRacuniService.updateUlazniRacun, payload.payload);
    put(setUlazniRacun(res.data));

    yield payload.history.replace('/ulazni-racuni/' + res.data.id);

    let redniBroj = res.data.redni_broj ? res.data.redni_broj : '';
    toast.success(
        'Ulazni račun je izmijenjen: ' + redniBroj,
        toastSettings
    );
  } catch (error) {
    yield put(setGlobalError(error.message));
  }
}

export function* ulazniRacunDelete({ payload }) {
  try {
    yield call(ulazniRacuniService.deleteUlazniRacun, payload);
  } catch (error) {
    yield put(setGlobalError(error.message));
  }
}
