import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";

import { izvjestajSelector } from "../../store/selectors/IzvjestajiSelector";
import IzvjestajDetails from './IzvjestajDetails';
import { getIzvjestaj } from "../../store/actions/IzvjestajiActions";

const Izvjestaj = () => {
    const dispatch = useDispatch();
    const { params } = useRouteMatch();
    const izvjestaj = useSelector(izvjestajSelector());
    const search = useLocation().search;

    useEffect(() => {
        if (params.slug === 'fiskalni-presjek-stanja') {
            dispatch(getIzvjestaj({ slug: params.slug }));
        } else if (params.slug === 'fiskalni-dnevni-izvjestaj') {
            dispatch(getIzvjestaj({ slug: params.slug, datum: new URLSearchParams(search).get('datum') }));
        } else {
            dispatch(getIzvjestaj({ slug: params.slug, datum_od: new URLSearchParams(search).get('datum_od'), datum_do: new URLSearchParams(search).get('datum_do')}));
        }
    }, [dispatch, params, search]);

    return (
        <>
            <IzvjestajDetails izvjestaj={izvjestaj} />
        </>
    );
};

export default Izvjestaj;