import React from 'react';
import { useFormikContext } from 'formik';
import { ReactComponent as EditIcon } from '../../../assets/icon/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icon/x-delete.svg';
import { ReactComponent as SelectorIcon } from '../../../assets/icon/selector.svg';

import 'react-toastify/dist/ReactToastify.css';
import {
    getUkupanPopust,
    getUkupnaCijenaSaPdv,
    getUkupnaCijenaBezPdv, formatirajCijenu,
} from "../../../helpers/racuni";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const BezgotovinskiStavkeFieldArray = ({ remove, pathName, setShow }) => {
    const { values, setFieldValue } = useFormikContext();

    const editNiz = (index, stavka) => {
        setFieldValue(`niz_stavke[0].naziv`, stavka.naziv);
        setFieldValue(`niz_stavke[0].opis`, stavka.opis);
        setFieldValue(`niz_stavke[0].cijena_bez_pdv`, +stavka.cijena_bez_pdv);
        setFieldValue(`niz_stavke[0].cijena_bez_pdv_popust`, +stavka.cijena_bez_pdv_popust);
        setFieldValue(`niz_stavke[0].cijena_sa_pdv_popust`, +stavka.cijena_sa_pdv_popust);
        setFieldValue(`niz_stavke[0].jedinica_mjere`, stavka.jedinica_mjere);
        setFieldValue(`niz_stavke[0].jedinica_mjere_id`, +stavka.jedinica_mjere_id);
        setFieldValue(`niz_stavke[0].kolicina`, +stavka.kolicina);
        setFieldValue(`niz_stavke[0].marza`, +stavka.marza);
        setFieldValue(`niz_stavke[0].nova_cijena_bez_pdv`, +stavka.nova_cijena_bez_pdv);
        setFieldValue(`niz_stavke[0].nova_jedinica_mjere_id`, stavka.nova_jedinica_mjere_id);
        setFieldValue(`niz_stavke[0].nova_ukupna_cijena`, +stavka.nova_ukupna_cijena);
        setFieldValue(`niz_stavke[0].novi_porez_id`, +stavka.novi_porez_id);
        setFieldValue(`niz_stavke[0].popust`, +stavka.popust);
        setFieldValue(`niz_stavke[0].porez`, stavka.porez);
        setFieldValue(`niz_stavke[0].porez_id`, stavka.porez_id);
        setFieldValue(`niz_stavke[0].porez_naziv`, stavka.porez_naziv);
        setFieldValue(`niz_stavke[0].roba_id`, stavka.roba_id);
        setFieldValue(`niz_stavke[0].tip_popusta`, stavka.tip_popusta);
        setFieldValue(`niz_stavke[0].ukupna_cijena`, +stavka.ukupna_cijena);
        setFieldValue(`niz_stavke[0].ukupan_pdv`, +stavka.ukupan_pdv);
        setFieldValue(`niz_stavke[0].zavisni_troskovi`, +stavka.zavisni_troskovi);
        setFieldValue(`niz_stavke[0].nabavna_cijena`, +stavka.nabavna_cijena);

        setShow(true);
        remove(index);
    };

    function handleOnDragEnd(result) {
        if (! result.destination) return;

        const items = Array.from(values.stavke);
        const [ reorderedItem ] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setFieldValue('stavke', items);
    }

    return (
        <>
            <h2 className="heading-secondary">Stavke</h2>
            <div className="main-content__box mt-3">
                <div className="content">
                    <div className="table-wrapper">
                        <table className="table">
                            <thead>
                                <tr>
                                    {pathName.includes('/ulazni-racuni') ? (
                                        <>
                                            <th className="cd">
                                                <p className="heading-quaternary txt-left">
                                                    Naziv
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary nowrap">
                                                    Jed. c.
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary">
                                                    Količina
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary">
                                                    Popust sa PDV
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary">
                                                    Ukupan PDV
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary">
                                                    Ukupno
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary">
                                                    Nova jed.
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary">
                                                    Marža
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary">
                                                    Zavisni trošak
                                                </p>
                                            </th>
                                        </>
                                    ) : (
                                        <>
                                            <th className="cd">
                                                <p className="heading-quaternary txt-left" />
                                            </th>
                                            <th className="cd w-100">
                                                <p className="heading-quaternary txt-left">
                                                    Naziv
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary nowrap">
                                                    Jed. c. bez PDV
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary nowrap">
                                                    Jed. c. sa PDV
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary">
                                                    Količina
                                                </p>
                                            </th>
                                            {values.tip_racuna === 'knjizno' && (
                                                <>
                                                    <th className="cd">
                                                        <p className="heading-quaternary">
                                                            Iznos povrata bez PDV
                                                        </p>
                                                    </th>
                                                    <th className="cd">
                                                        <p className="heading-quaternary">
                                                            Iznos povrata sa PDV
                                                        </p>
                                                    </th>
                                                </>
                                            )}
                                             {values.tip_racuna !== 'knjizno' && (
                                                <th className="cd">
                                                    <p className="heading-quaternary">
                                                        Popust sa PDV
                                                    </p>
                                                </th>
                                            )}
                                            <th className="cd">
                                                <p className="heading-quaternary">
                                                    Ukupno bez PDV
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary">
                                                    Ukupan PDV
                                                </p>
                                            </th>
                                            <th className="cd">
                                                <p className="heading-quaternary">
                                                    Ukupno sa PDV
                                                </p>
                                            </th>
                                        </>
                                    )}
                                    <th className="cd">
                                        <p className="heading-quaternary" />
                                    </th>
                                </tr>
                            </thead>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="stavke">
                                    {(provided) => (
                                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                            {values.stavke.map((stavka, index) => {
                                                return (
                                                    <Draggable key={String(stavka?.id)} draggableId={String(stavka?.id)} index={index}>
                                                        {(provided, snapshot) => (
                                                            <tr
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}>
                                                                {pathName.includes('/ulazni-racuni') ? (
                                                                    <>
                                                                        <td className="w-100">
                                                                            <p className="cd fw-500">
                                                                                { stavka.naziv }
                                                                            </p>
                                                                            <p className="mt-2 cl">
                                                                                {stavka?.opis ? stavka?.opis : ''}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            { formatirajCijenu(parseFloat(stavka.roba ? stavka.roba.cijene_roba[0].cijena_bez_pdv : stavka.cijena_bez_pdv)) }
                                                                            <br />
                                                                            { formatirajCijenu(parseFloat(stavka.roba ? stavka.roba.cijene_roba[0].ukupna_cijena : stavka.ukupna_cijena)) }
                                                                        </td>
                                                                        <td>
                                                                            { `${parseFloat(stavka?.kolicina)} ${stavka?.jedinica_mjere['naziv']}` }
                                                                        </td>
                                                                        <td>
                                                                            { stavka.popust ? getUkupanPopust(stavka) : '' }
                                                                        </td>
                                                                        <td>
                                                                            { formatirajCijenu(parseFloat(getUkupnaCijenaSaPdv(stavka) - getUkupnaCijenaBezPdv(stavka))) }
                                                                        </td>
                                                                        <td>
                                                                            { formatirajCijenu(parseFloat(getUkupnaCijenaBezPdv(stavka))) }
                                                                            <br />
                                                                            { formatirajCijenu(parseFloat(getUkupnaCijenaSaPdv(stavka))) }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                formatirajCijenu(
                                                                                    parseFloat(
                                                                                        +stavka.nova_cijena_bez_pdv + stavka.zavisni_troskovi
                                                                                    )
                                                                                )
                                                                            }
                                                                            <br />
                                                                            {
                                                                                formatirajCijenu(
                                                                                    parseFloat(
                                                                                        +stavka.nova_ukupna_cijena + stavka.zavisni_troskovi
                                                                                    )
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {formatirajCijenu(
                                                                                parseFloat(
                                                                                    +stavka.marza
                                                                                )
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {formatirajCijenu(
                                                                                parseFloat(
                                                                                    +stavka.zavisni_troskovi
                                                                                )
                                                                            )}
                                                                        </td>
                                                                        {stavka.izmijeni_stavku ? (
                                                                            <td>
                                                                                Ažurirano
                                                                            </td>
                                                                        ) : ''}
                                                                        <td>
                                                                            <p
                                                                                onClick={() => {
                                                                                    editNiz(index, stavka);
                                                                                }}
                                                                            >
                                                                                <span className="btn btn__close">
                                                                                    <EditIcon />
                                                                                </span>
                                                                            </p>
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <td>
                                                                            <p>
                                                                                <span title="Pomjerite ovu stavku za preraspored unutar liste">
                                                                                    <SelectorIcon />
                                                                                </span>
                                                                            </p>
                                                                        </td>
                                                                        <td className="w-100">
                                                                            <p className="cd fw-500">
                                                                                { stavka.naziv }
                                                                            </p>
                                                                            <p className="mt-2 cl">
                                                                                {stavka?.opis ? stavka?.opis : ''}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            { formatirajCijenu(parseFloat(stavka.roba ? stavka.roba.cijene_roba[0].cijena_bez_pdv : stavka.cijena_bez_pdv)) }
                                                                        </td>
                                                                        <td>
                                                                            { formatirajCijenu(parseFloat(stavka.roba ? stavka.roba.cijene_roba[0].ukupna_cijena : stavka.ukupna_cijena)) }
                                                                        </td>
                                                                        <td>
                                                                            { `${stavka?.kolicina} ${stavka?.jedinica_mjere['naziv']}` }
                                                                        </td>
                                                                        {values.tip_racuna === 'knjizno' && (
                                                                            <>
                                                                                <td>
                                                                                    {formatirajCijenu(stavka.iznos_povrata_bez_pdv)}
                                                                                </td>
                                                                                <td>
                                                                                    {formatirajCijenu(stavka.iznos_povrata_sa_pdv)}
                                                                                </td>
                                                                            </>
                                                                        )}
                                                                        {values.tip_racuna !== 'knjizno' && (
                                                                             <td>
                                                                                { stavka.popust ? getUkupanPopust(stavka) : '' }
                                                                            </td>
                                                                        )}
                                                                        <td>
                                                                            { formatirajCijenu(parseFloat(getUkupnaCijenaBezPdv(stavka))) }
                                                                        </td>
                                                                        <td>
                                                                            { formatirajCijenu(parseFloat(getUkupnaCijenaSaPdv(stavka) - getUkupnaCijenaBezPdv(stavka))) } ({stavka?.porez?.naziv})
                                                                        </td>
                                                                        <td>
                                                                            { formatirajCijenu(parseFloat(getUkupnaCijenaSaPdv(stavka))) }
                                                                        </td>
                                                                    </>
                                                                )}
                                                                <td>
                                                                    <p
                                                                        onClick={() => {
                                                                            remove(index);
                                                                        }}
                                                                    >
                                                                        <span className="btn btn__close danger">
                                                                            <DeleteIcon />
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </tbody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </table>
                        <hr className="hr-main" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BezgotovinskiStavkeFieldArray;
