import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ERRORS, PREDRACUNI } from '../constants/routes';
import Predracuni from '../components/Predracuni/Predracuni';
import Bezgotovinski from "../components/Racuni/Bezgotovinski/Bezgotovinski";
import BezgotovinskiPreview from "../components/Racuni/Bezgotovinski/BezgotovinskiPreview";
import BezgotovinskiEdit from "../components/Racuni/Bezgotovinski/BezgotovinskiEdit";

const PredracuniRouter = () => {
  return (
    <Switch>
      <Route path={PREDRACUNI.EDIT}>
          <BezgotovinskiEdit tip="predracun" />
      </Route>
      <Route path={PREDRACUNI.CREATE}>
        <Bezgotovinski />
      </Route>
      <Route path={PREDRACUNI.SHOW}>
         <BezgotovinskiPreview tip="predracun" />
      </Route>
      <Route exact path={PREDRACUNI.INDEX}>
        <Predracuni />
      </Route>
      <Redirect to={ERRORS.NOT_FOUND} />
    </Switch>
  );
};

export default PredracuniRouter;
