import { css } from '@emotion/core';

export const spinnerStyleGrid = css`
  display: block;
  margin: 35px auto;
  border-color: red;
`;

export const spinnerStyleFilter = css`
  dislay: block;
  margin: auto;
  border-color: red;
`;
