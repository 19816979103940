import { createSelector } from 'reselect';

const novostiStateSelector = (state) => state.novostiReducer;

export const novostiSelector = () =>
    createSelector(novostiStateSelector, (novostiState) => novostiState.novosti);

export const novostSelector = () =>
  createSelector(novostiStateSelector, (novostiState) => novostiState.novost);

export const neprocitaneSelector = () =>
    createSelector(novostiStateSelector, (novostiState) => novostiState.neprocitane);

export const poslednjeSelector = () =>
    createSelector(novostiStateSelector, (novostiState) => novostiState.poslednje);

