import React, { useState } from 'react';
import { ReactComponent as Success } from '../../assets/icon/success.svg';
import { ReactComponent as IconLg } from '../../assets/icon/icon-lg.svg';
import { ReactComponent as Izmjeni } from '../../assets/icon/izmjeni.svg';
import { ReactComponent as Delete } from '../../assets/icon/stepper-minus.svg';
import { ReactComponent as A4 } from '../../assets/icon/hero-racuni.svg';
import { useDispatch, useSelector } from 'react-redux';
import { racunSelector } from '../../store/selectors/RacuniSelector';

import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/me';
import { racuniService } from '../../services/RacuniService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
import Modal from "../shared/forms/Modal";
import { renderStatus } from '../../helpers/bezgotovinskiForm';
import { formatirajCijenu } from '../../helpers/racuni';
import { getRacuni } from '../../store/actions/RacuniActions';

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const RacuniTableRow = ({ item, racuni }) => {
  const history = useHistory();
  const [_item, setItem] = useState(item);
  const dispatch = useDispatch();

  let fizickaLicaPartneri;
  let preduzecaPartneri;
  if (racuni.partneri) {
    fizickaLicaPartneri = racuni.partneri
      .map(
        (tmp) =>
          tmp.fizicko_lice && {
            ime: tmp?.fizicko_lice?.ime + ' ' + tmp?.fizicko_lice?.prezime,
            id: tmp?.id,
          }
      )
      .filter((tmp) => tmp != null);
    preduzecaPartneri = racuni?.partneri
      ?.map(
        (tmp) =>
          !tmp.fizicko_lice && {
            ime: tmp?.preduzece_partner?.kratki_naziv,
            id: tmp?.id,
          }
      )
      .filter((tmp) => tmp != null);
  }

  let status = renderStatus(_item.status);

  const handleClick = () => {
    // TODO: item.vrsta_racuna.toLowerCase() === 'gotovinski'
    const vrstaRacuna = item.vrsta_racuna.toLowerCase();
    if (
      item.vrsta_racuna === 'GOTOVINSKI' ||
      item.vrsta_racuna === 'gotovinski'
      // item.status === 'KREIRAN' &&
      // !item.partner
    ) {
      history.push(`/racuni/show/${item.id}`);
    } else {
      history.push(`/racuni/bezgotovinski/show/${item.id}`);
    }
  };

  const storniranjeConfirm = (e) => {
    e.stopPropagation();
    const node = document.getElementById("custom-prompt");

    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
    };

    const confirm = () => cleanUp(true);
    const cancel = () => cleanUp(false);

    let date = new Date();

    ReactDOM.render(
        <Modal
            title="Storniraj račun?"
            message=""
            onConfirm={(value) => {handleStorniraj(value); confirm()}}
            onCancel={cancel}
            confirmText="Storniraj"
            cancelText="Obustavi"
            racunIzOvogMjeseca={new Date(item.datum_izdavanja) > new Date(date.getFullYear(), date.getMonth(), 1)}
        />
        , node
    );
  };

  const handleStorniraj = (vrijeme) => {
    if (_item.status !== 'storniran') {
      racuniService
        .stornirajRacun(item.id, {vrijemeStorniranja: vrijeme})
        .then(() => {
          toast.success(
            `Storniranje računa broj ${item.redni_broj} je uspjelo!`,
            toastSettings
          );
        })
        .catch((err) => {
          let message = err?.response?.data?.error
            ? err.response.data.error
            : err.message;
          toast.error(
            'Storniranje računa nije moguća: ' + message,
            toastSettings
          );
        });
    }
  };

  const handleFiskalizuj = (e) => {
    e.stopPropagation();
    racuniService
      .fiskalizujRacun(item.id)
      .then((data) => {
        dispatch(getRacuni())
        toast.success(
          `Fiskalizacija računa je uspjela!`,
          toastSettings
        );
      })
      .catch((err) => {
        let message = err?.response?.data?.error
          ? err.response.data.error
          : err.message;
        toast.error(
          'Fiskalizacija računa nije moguća: ' + message,
          toastSettings
        );
      });
  };

  const handleIzmjeni = (e) => {
    e.stopPropagation();
    history.push(`/racuni/bezgotovinski/edit/${item.id}`);
  };
  const handlePogledajA4 = (e) => {
    e.stopPropagation();
    history.push(`/racuni/bezgotovinski/show/${item.id}`);
  };

  const vrstaRacuna = (racun) => {
    let value;
    if (racun === 'gotovinski' || racun === 'GOTOVINSKI') {
      // value = racun.substring(0, 3) + '.';
      value = 'g';
    } else if (racun === 'bezgotovinski' || racun === 'BEZGOTOVINSKI') {
      // value = racun.substring(0, 6) + '.';
      value = 'b';
    } else {
      value = racun;
    }
    return value;
  };

  const renderKupac = () => {
    if (preduzecaPartneri?.find((fl) => fl.id === _item?.partner_id)?.ime) {
      return (
          <td className="cd fw-500">
            {preduzecaPartneri?.find((fl) => fl.id === _item?.partner_id)?.ime}
          </td>
      );
    }

    if (fizickaLicaPartneri?.find((fl) => fl.id === _item?.partner_id)?.ime) {
      return (
          <td className="cd fw-500">
            {fizickaLicaPartneri?.find((fl) => fl.id === _item?.partner_id)?.ime}
          </td>
      )
    }
  }

  const renderStornirajDugme = (item) => {
    if (! item?.qr_url || item?.status === 'storniran' || item?.status === 'korektivni') {
      return null;
    }

    if (item?.tip_racuna === 'avans' && item?.ikof_reference.length) {
      return null;
    }

    return (
        <a
            onClick={storniranjeConfirm}
            className={`${_item?.qr_url ? 'disabled' : ''}`}
        >
          <Delete className="icon icon__dark md" />
          Storniraj
        </a>
    )
  }

  return (
    <tr onClick={handleClick} className="mob-relative-block">
      <td className="cl">
        <div className="inner-td-wrapper lowercase">
          {_item?.qr_url && <Success />}
          &nbsp;
          {
            <span title={_item.vrsta_racuna} className="tag tag__neutral">
              {vrstaRacuna(_item?.vrsta_racuna)}
            </span>
          }
        </div>
      </td>
      <td className="cl">{ ! _item?.redni_broj ?
          (
              <span title="Privremeni" className="tag tag__neutral">
                p
              </span>
          ) : _item?.redni_broj }
      </td>

      {renderKupac()}

      {! preduzecaPartneri && ! fizickaLicaPartneri && (
          <td className="cd fw-500">
            {_item?.partner?.fizicko_lice_id
                ? _item?.partner?.fizicko_lice?.ime +
                ' ' +
                _item?.partner?.fizicko_lice?.prezime
                : _item?.partner?.preduzece_partner?.kratki_naziv}
          </td>
      )}
      <td className="cl dshow-cell">
        {formatirajCijenu(_item?.ukupna_cijena_bez_pdv_popust)}
      </td>
      <td className="cl dshow-cell">
        {formatirajCijenu(_item?.ukupan_iznos_pdv)}
      </td>
      <td className="cd fw-500">
        {formatirajCijenu(_item?.ukupna_cijena_sa_pdv_popust)}
      </td>
      <td className="cd">
        {/* <span className={bojaStatus[item.status].klasa}>
          {bojaStatus[item.status].naziv}
        </span> */}
        {<span className={status.color}>{status.value}</span>}
      </td>

      <td className="cl">
        {/* {new Date(item.created_at).toLocaleDateString('en-GB')} */}
        <Moment locale="me" format="DD. MMM YYYY.">
          {_item?.datum_izdavanja}
        </Moment>
      </td>
      <td className="mob-absolute-topright">
        <div className="df jc-end ai-c">
          <button className="btn btn__light-dd btn__xs">
            <IconLg />
            <div className="drop-down">
              {_item.vrsta_racuna == 'gotovinski' && (
                <a onClick={handlePogledajA4}>
                  <A4 className="icon icon__dark md" />
                  Prikaži na A4
                </a>
              )}

              {! _item?.qr_url && _item?.vrsta_racuna === 'bezgotovinski' && ! _item?.ikof && (
                <a
                  onClick={handleIzmjeni}
                  className={`${_item?.qr_url ? 'disabled' : ''}`}
                >
                  <Izmjeni />
                  Izmijeni
                </a>
              )}
              {!_item?.qr_url && (
                <a
                  onClick={handleFiskalizuj}
                  className={`${_item?.qr_url ? 'disabled' : ''}`}
                >
                  <Success />
                  Fiskalizuj
                </a>
              )}

              {renderStornirajDugme(_item)}
            </div>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default RacuniTableRow;
