import Modal from "./forms/Modal";

const CustomPrompt = ({ message, cleanUp }) => {
    const cancel = () => cleanUp(false);
    const confirm = () => cleanUp(true);

    return (
        <Modal
            title="Da li ste sigurni?"
            message={message}
            onConfirm={confirm}
            onCancel={cancel}
            confirmText="Nastavi"
            cancelText="Obustavi"
        />
    );
};

export default CustomPrompt;
