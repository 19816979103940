import React, { useState } from 'react';
import { ReactComponent as Success } from '../../assets/icon/success.svg';
import { ReactComponent as IconLg } from '../../assets/icon/icon-lg.svg';
import { ReactComponent as Izmjeni } from '../../assets/icon/izmjeni.svg';
import { ReactComponent as A4 } from '../../assets/icon/hero-racuni.svg';

import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/me';
import { renderStatus } from '../../helpers/bezgotovinskiForm';
import { formatirajCijenu } from '../../helpers/racuni';

const UlazniRacuniTableRow = ({ item, ulazniRacuni }) => {
  const history = useHistory();
  const [_item, setItem] = useState(item);

  let fizickaLicaPartneri;
  let preduzecaPartneri;
  if (ulazniRacuni.partneri) {
    fizickaLicaPartneri = ulazniRacuni.partneri
      .map(
        (tmp) =>
          tmp.fizicko_lice && {
            ime: tmp?.fizicko_lice?.ime + ' ' + tmp?.fizicko_lice?.prezime,
            id: tmp?.id,
          }
      )
      .filter((tmp) => tmp != null);
    preduzecaPartneri = ulazniRacuni?.partneri
      ?.map(
        (tmp) =>
          !tmp.fizicko_lice && {
            ime: tmp?.preduzece_partner?.kratki_naziv,
            id: tmp?.id,
          }
      )
      .filter((tmp) => tmp != null);
  }

  let status = renderStatus(_item.status);

  const handleIzmjeni = (e) => {
    e.stopPropagation();
    history.push(`/ulazni-racuni/edit/${item.id}`);
  };
  const handlePogledajA4 = (e) => {
    e.stopPropagation();
    history.push(`/ulazni-racuni/bezgotovinski/show/${item.id}`);
  };

  const vrstaRacuna = (racun) => {
    let value;
    if (racun === 'gotovinski' || racun === 'GOTOVINSKI') {
      // value = racun.substring(0, 3) + '.';
      value = 'g';
    } else if (racun === 'bezgotovinski' || racun === 'BEZGOTOVINSKI') {
      // value = racun.substring(0, 6) + '.';
      value = 'b';
    } else {
      value = racun;
    }
    return value;
  };

  const renderKupac = () => {
    if (preduzecaPartneri?.find((fl) => fl.id === _item?.partner_id)?.ime) {
      return (
          <td className="cd fw-500">
            {preduzecaPartneri?.find((fl) => fl.id === _item?.partner_id)?.ime}
          </td>
      );
    }

    if (fizickaLicaPartneri?.find((fl) => fl.id === _item?.partner_id)?.ime) {
      return (
          <td className="cd fw-500">
            {fizickaLicaPartneri?.find((fl) => fl.id === _item?.partner_id)?.ime}
          </td>
      )
    }
  }

  function showRacun() {
    if (item.tip_racuna === 'ulazni') {
      history.push(`/ulazni-racuni/${item.id}`);
    } else if (item.tip_racuna === 'racun') {
      history.push(`/racuni/bezgotovinski/show/${item.id}`);
    } else if (item.tip_racuna === 'predracun') {
      history.push(`/predracuni/${item.id}`);
    }
  }

  return (
    <tr onClick={() => showRacun()} className="mob-relative-block">
      <td className="cl">
        <div className="inner-td-wrapper lowercase">
          {_item?.dokumenta?.length ?
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www. w3.org/2000/svg">
              <path d="M9.11484 3.66661L4.72418 8.05728C4.59683 8.18028 4.49525 8.3274 4.42537 8.49007C4.3555 8.65275 4.31871 8.82771 4.31718 9.00474C4.31564 9.18178 4.34937 9.35736 4.41641 9.52122C4.48345 9.68508 4.58246 9.83395 4.70765 9.95914C4.83284 10.0843 4.98171 10.1833 5.14557 10.2504C5.30943 10.3174 5.48501 10.3512 5.66204 10.3496C5.83908 10.3481 6.01404 10.3113 6.17671 10.2414C6.33939 10.1715 6.48651 10.07 6.60951 9.94261L10.8855 5.55195C11.3713 5.04901 11.64 4.3754 11.634 3.67621C11.6279 2.97702 11.3474 2.30819 10.853 1.81376C10.3586 1.31934 9.68977 1.03889 8.99058 1.03282C8.29139 1.02674 7.61778 1.29552 7.11484 1.78128L2.83818 6.17128C2.08797 6.92149 1.6665 7.93899 1.6665 8.99995C1.6665 10.0609 2.08797 11.0784 2.83818 11.8286C3.58838 12.5788 4.60589 13.0003 5.66684 13.0003C6.7278 13.0003 7.7453 12.5788 8.49551 11.8286L12.6668 7.66661" stroke="#9CA3AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        : ''}
        </div>
      </td>
      <td className="cl">
        { _item?.redni_broj }
      </td>

      {renderKupac()}

      {! preduzecaPartneri && ! fizickaLicaPartneri && (
          <td className="cd fw-500">
            {_item?.partner?.fizicko_lice_id
                ? _item?.partner?.fizicko_lice?.ime +
                ' ' +
                _item?.partner?.fizicko_lice?.prezime
                : _item?.partner?.preduzece_partner?.kratki_naziv}
          </td>
      )}
      <td className="cl dshow-cell">
        {formatirajCijenu(_item?.ukupna_cijena_bez_pdv_popust)}
      </td>
      <td className="cl dshow-cell">
        {formatirajCijenu(_item?.ukupan_iznos_pdv)}
      </td>
      <td className="cd fw-500">
        {formatirajCijenu(_item?.ukupna_cijena_sa_pdv_popust)}
      </td>
      <td className="cd">
        {<span className={status.color}>{status.value}</span>}
      </td>

      <td className="cl">
        {/* {new Date(item.created_at).toLocaleDateString('en-GB')} */}
        <Moment locale="me" format="DD. MMM YYYY.">
          {_item?.created_at}
        </Moment>
      </td>
      <td className="mob-absolute-topright">
        <div className="df jc-end ai-c">
          <button className="btn btn__light-dd btn__xs">
            <IconLg />
            <div className="drop-down">
              {_item.vrsta_racuna == 'gotovinski' && (
                <a onClick={handlePogledajA4}>
                  <A4 className="icon icon__dark md" />
                  Prikaži na A4
                </a>
              )}

              {/*{! _item?.qr_url && _item?.vrsta_racuna === 'bezgotovinski' && (*/}
              {/*  <a*/}
              {/*    onClick={handleIzmjeni}*/}
              {/*    className={`${_item?.qr_url ? 'disabled' : ''}`}*/}
              {/*  >*/}
              {/*    <Izmjeni />*/}
              {/*    Izmijeni*/}
              {/*  </a>*/}
              {/*)}*/}
            </div>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default UlazniRacuniTableRow;
