import {
        GET_ZAVISNI_TROSAK,
        GET_ZAVISNI_TROSKOVI,
        SET_ZAVISNI_TROSAK,
        SET_ZAVISNI_TROSKOVI
    }
from "../actionTypes/ZavisniTroskoviTypes";

export const getZavisniTroskovi = (payload) => ({
    type: GET_ZAVISNI_TROSKOVI,
    payload,
});

export const setZavisniTroskovi = (payload) => ({
    type: SET_ZAVISNI_TROSKOVI,
    payload,
});

export const getZavisniTrosak = (payload) => ({
    type: GET_ZAVISNI_TROSAK,
    payload,
});

export const setZavisniTrosak = (payload) => ({
    type: SET_ZAVISNI_TROSAK,
    payload,
});
