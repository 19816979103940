import { ErrorMessage, useField, useFormikContext } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { getZavisniTrosak } from '../../../store/actions/ZavisniTroskoviActions';
import {
  stavkeUslugeSelector,
} from '../../../store/selectors/RacuniSelector';
import { zavisniTroskoviSelector } from '../../../store/selectors/ZavisniTroskoviSelector';
import Label from '../../shared/forms/Label';

const ZavisniTroskoviDropdown = ({
  label,
  defaultValue,
  onChangeExtra = null,
  ...props
}) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();

  const zavisniTroskovi = useSelector(zavisniTroskoviSelector()) || { data: [] };

  let options;
  options = [
    ...zavisniTroskovi.data.map((zavisniTrosak) => ({ value: zavisniTrosak, label: zavisniTrosak.naziv })),
  ];

  const onKeyUp = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13 || e.keyCode === 38 || e.keyCode === 40) {
      return;
    }

    if (onChangeExtra) onChangeExtra(e.target.value, 'addManually');

    setValue(e.target.value);
  };

  const onStavkaChange = (option) => {
    dispatch(getZavisniTrosak(option.value.id));

    setValue({ ...option.value, kolicina: 0, tip_popusta: 'procenat', usluga_id: option.value.id });

    if (onChangeExtra) onChangeExtra(option.value, '');
  };

  const [field, meta, helpers] = useField(props);

  const { error } = meta;
  const { setValue } = helpers;
  const selectStyle = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#F3F4F6',
      borderRadius: 4,
      height: '45px',
      minHeight: 'unset',
    }),
  };

  return (
    <div onKeyUp={onKeyUp}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Select
        styles={selectStyle}
        options={values.tip_racuna !== 'avans' && values.tip_racuna !== 'knjizno' ? options : []}
        isOptionDisabled={(option) => option.disabled}
        name={field.name}
        onChange={onStavkaChange}
        noOptionsMessage={() => 'Nova stavka'}
        value={{
          label:
            field.value?.roba?.naziv ||
            field.value?.naziv ||
            field.value ||
            'Unesite naziv zavisnog troška...',
          value: field.value,
        }}
      />
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

export default ZavisniTroskoviDropdown;
