import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ERRORS, NOVOSTI } from '../constants/routes';
import Novosti from '../components/Novosti/Novosti';
import Novost from '../components/Novosti/Novost';

const NovostiRouter = () => {
    return (
        <Switch>
            <Route exact path={NOVOSTI.INDEX}>
                <Novosti />
            </Route>
            <Route exact path={NOVOSTI.SHOW}>
                <Novost />
            </Route>
            <Redirect to={ERRORS.NOT_FOUND} />
        </Switch>
    );
};

export default NovostiRouter;