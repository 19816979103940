import { useFormikContext } from "formik";
import { renderButtons } from "../../../helpers/bezgotovinskiForm";
import BezgotovinskiStatusPodsjetnici from "./BezgotovinskiStatusPodsjetnici";

const BezgotovinskiFooter = ({ pathName, history }) => {
    const { values, setFieldValue } = useFormikContext();

    const renderTekstZaPodsjetnike = (pathName) => {
        if (pathName === '/predracuni/create') {
            return 'Status';
        } else {
            if (values.tip_racuna === 'avans' || values.tip_racuna === 'knjizno') {
                return 'Sačuvaj';
            }

            return 'Status i podsjetnici';
        }
    }

    return (
        <>
            <h2 className="heading-secondary">{renderTekstZaPodsjetnike(pathName)}</h2>
            <div className="main-content__box">
              <div className="form" style={{width: '100%'}}>
                <div className="content pt-12"></div>
                <BezgotovinskiStatusPodsjetnici pathName={pathName} />
                <div className="form__footer">
                    {renderButtons(setFieldValue, history, pathName)}
                </div>
              </div>
            </div>
        </>
    )
}

export default BezgotovinskiFooter;