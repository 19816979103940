import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { NOVOSTI } from '../../constants/routes';

import { getNovost, getPoslednjeNovosti } from "../../store/actions/NovostiActions";
import { poslednjeSelector } from "../../store/selectors/NovostiSelector";
import { novostSelector } from "../../store/selectors/NovostiSelector";
import NovostDetails from "./NovostDetails";
import { ReactComponent as LinkSvg } from '../../assets/icon/link.svg';
import PoslednjeNovostiTable from './PoslednjeNovostiTable';

const Novost = () => {
  const dispatch = useDispatch();
  const novost = useSelector(novostSelector());
  const poslednjeNovosti = useSelector(poslednjeSelector());

  const { params } = useRouteMatch();

  useEffect(() => {
    if (params.slug) dispatch(getNovost(params.slug));

    dispatch(getPoslednjeNovosti());
  }, [dispatch, params]);

  return (
    <>
      <Link to={NOVOSTI.INDEX} className="back-link df mb-30">
        <LinkSvg /> <p>Povratak na Novosti</p>
      </Link>
      <div className="row">
        <div className="col-md-7 col-xl-8 col-sm-12 tab-pr-0">
          <NovostDetails novost={novost} />
        </div>
        <div className="col-md-5 col-xl-4 col-sm-12">
          <PoslednjeNovostiTable novosti={poslednjeNovosti} />
        </div>
      </div>
    </>
  );
};

export default Novost;
