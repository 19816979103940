import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as ExternalLinkIcon } from '../../../assets/icon/external-link.svg';


const Modal = ({ title, message, confirmText, cancelText, onCancel, onConfirm, url, racun = null, newTabButton = null, racunIzOvogMjeseca = true }) => {
    const [vrijemeStorniranja, setVrijemeStorniranja] = useState('trenutni');

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            onCancel();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction);

        return () => {
            document.removeEventListener("keydown", escFunction);
        };
    }, [escFunction]);

    const renderFile = () => {
        const extension = url.substr(url.lastIndexOf('.') + 1);

        if (extension === 'pdf') {
            return (
                <object data={url} width="100%" height="800" />
            )
        } else {
            return (
                <img
                    src={url}
                    style={{ maxWidth: "1000px", maxHeight: "750px" }}
                    className="mt-3"
                />
            )
        }
    }

    return (
        <div className="modal open" onClick={onCancel}>
            <div className="modal__content" onClick={(event) => event.stopPropagation()}>
                <div className="modal__header">
                    <span className="modal__close" onClick={onCancel}>
                      &times;
                    </span>
                    <h2 className="heading-secondary m-0">{title}</h2>
                </div>
                <div className="modal__body">
                    <p>
                        {message}
                        {url && (
                            renderFile()
                        )}
                    </p>
                    {racun ? (
                        racun.ikof_referenca.map((racun) => (
                            <>
                                <a href={`/racuni/bezgotovinski/show/${racun.id}`} target="_blank" className="btn btn__link success">
                                    Knjižno odobrenje {racun.redni_broj}/{new Date(racun.datum_izdavanja).getFullYear()}
                                    <ExternalLinkIcon />
                                </a>
                            </>
                        ))
                     ) : ''}
                    {confirmText === 'Storniraj' && (
                        <form>
                            <input
                                type="radio"
                                id="trenutni"
                                className="mr-3 mt-2"
                                name="vrijeme"
                                onClick={() => setVrijemeStorniranja('trenutni')}
                                defaultChecked
                            />
                            <label htmlFor="trenutni">Kreiraj korektivni račun u trenutnom mjesecu</label>
                            <br></br>
                            {! racunIzOvogMjeseca && (
                                <>
                                    <input
                                        type="radio"
                                        id="prosli"
                                        className="mr-3"
                                        name="vrijeme"
                                        onClick={() => setVrijemeStorniranja('prosli')}
                                    />
                                    <label htmlFor="prosli">Kreiraj korektivni račun u prethodnom mjesecu</label>
                                </>
                            )}
                        </form>
                    )}
                </div>
                <div className="modal__footer">
                    <button
                        type="submit"
                        className="btn btn__primary"
                        onClick={() => onConfirm(vrijemeStorniranja)}
                    >
                        {confirmText}
                    </button>
                    {newTabButton && (
                        <button
                            type="button"
                            className="btn btn__link ml-m"
                        >
                            <a href={newTabButton} target="_blank">
                                Otvori u novom tabu
                            </a>
                        </button>
                    )}
                    <button
                        type="button"
                        className="btn btn__link ml-m"
                        onClick={onCancel}
                    >
                        {cancelText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
