import React from 'react';
import Moment from "react-moment";
import { BASE_URL } from "../../config";

const NovostDetails = ({ novost }) => {
    const url = BASE_URL.slice(0, -4)

    function createMarkup(value) {
        return {__html: value};
    }

    return (
        <div className="screen-content">
            <div className="main-content__box">
                <div className="content" style={{ padding: '24px' }}>
                    <h2 className="heading-secondary mb-0">
                        { novost.naziv }
                    </h2>
                    <hr className="mtb-20"/>
                    <div className="df jc-sb ai-c">
                        <p className="txt-light">
                            <Moment locale="me" format="DD. MMM YYYY.">
                                { novost.created_at }
                            </Moment>
                        </p>
                        <a className="df">
                            <span className="tag tag__neutral txt-dark">
                                { novost?.categories?.naziv }
                            </span>
                        </a>
                    </div>
                    <img
                        className="img"
                        src={`${url}/storage/${novost.slika}`}
                        alt='slika'
                    />
                    <div className="box-news__content txt-light">
                        <p dangerouslySetInnerHTML={createMarkup(novost.tekst)} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NovostDetails;