import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setKolicinaRobe,
  setKolicinaUsluge,
} from '../../../store/actions/RacuniActions';
import {
  noviRacunRobaSelector,
  noviRacunSelector,
  noviRacunUslugaSelector,
} from '../../../store/selectors/RacuniSelector';
import KolicinaStavke from './KolicinaStavke';
import {TIPOVI_POPUSTA} from "../../../constants/racuni";
import Select from "react-select";

const NoviRacunTableRow = ({ usluga = {}, roba = {} }) => {
  const [tipPopusta, setTipPopusta] = useState('procenat');
  const [popust, setPopust] = useState(0);

  const stavka = useSelector(
    usluga?.id
      ? noviRacunUslugaSelector(usluga?.id)
      : noviRacunRobaSelector(roba.id)
  ) || { kolicina: 0 };
  const noviRacun = useSelector(noviRacunSelector());

  const dispatch = useDispatch();

  const handleClick = () => {
    if (stavka.kolicina > 0) return;

    if (usluga?.id) {
      if (popust) {
          usluga.popust = popust;
      }

      dispatch(setKolicinaUsluge(usluga, stavka.kolicina + 1));
    }
    if (roba.id) {
      if (popust) {
        roba.popust = popust;
      }

      dispatch(setKolicinaRobe(roba, stavka.kolicina + 1));
    }
  };

  return (
    <tr className={stavka.kolicina ? 'active' : ''}>
      <td>
        <p>{usluga?.naziv || roba?.roba?.naziv}</p>
        <h3 className="heading-quaternary">
          {usluga?.opis?.length > 50
            ? usluga?.opis.substring(1, 50)
            : usluga?.opis || roba?.roba?.opis}
        </h3>
      </td>
      <td className="cl">
        {usluga?.jedinica_mjere?.naziv || roba?.roba?.jedinica_mjere?.naziv}
      </td>
      <td className="cd fw-500 txt-right mob-txt-left">
        <p>
          {Number(usluga?.ukupna_cijena)?.toFixed(2).replace('.', ',') + '€'}
        </p>
      </td>
      <td className="cd fw-500 txt-right mob-txt-left">
          <Select
              options={TIPOVI_POPUSTA}
              name="tip_popusta"
              onChange={(option) => {
                  setTipPopusta(option)
              }}
          />
      </td>
      <td className="cd fw-500 txt-right mob-txt-left">
          <input
              name="popust"
              type="number"
              className="form__input"
              step=".01"
              placeholder="Popust"
              title="Iznos povrata bez PDV-a"
              onChange={(e) => setPopust(e.target.value)}
          />
      </td>
      <td>
        <KolicinaStavke usluga={usluga} roba={roba} stavka={stavka} popust={popust} tipPopusta={tipPopusta.value} />
      </td>
    </tr>
  );
};

export default NoviRacunTableRow;
