import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import Moment from 'moment';

import ReactDatePicker from "react-datepicker";

const Izvjestaji = () => {
  const history = useHistory();

  const handleSubmit = (values) => {
    if(values.izvjestaj === 'fiskalni-presjek-stanja') {
      history.push(`/izvjestaji/${values.izvjestaj}`)
    } else if (values.izvjestaj === 'fiskalni-dnevni-izvjestaj') {
      history.push(`/izvjestaji/${values.izvjestaj}?datum=${Moment(values.datum).format('YYYY-MM-DD')}`)
    } else {
      history.push(`/izvjestaji/${values.izvjestaj}?datum_od=${Moment(values.datum_od).format('YYYY-MM-DD')}&datum_do=${Moment(values.datum_do).format('YYYY-MM-DD')}`)
    }
  };

  return (
      <>
        <h1 className="heading-primary">Izvještaji</h1>
          <hr class="mtb-30 tabp-mtb-20"></hr>
          <div className="screen-content">
            <div className="box-modules-wrapper">
              <div className="title">
                <h2 className="heading-secondary fw-500">
                  Dnevni i periodični izvještaji
                  <p className="txt-light">
                    Periodični analitički pregledi izdatih računa
                  </p>
                </h2>
              </div>
              <hr class="mtb-30 tabp-mtb-20"></hr>
              <div className="row">
                <div className="col-md-4">
                  <Formik
                      initialValues={{
                        izvjestaj: 'fiskalni-presjek-stanja'
                      }}
                      onSubmit={handleSubmit}
                      enableReinitialize
                  >
                    <Form>
                    <div className="box-modules">
                      <h3 className="heading-tertiary fw-500">
                        Fiskalni presjek stanja
                      </h3>
                      <p className="txt-light">Za današnji dan</p>
                      <hr />
                      <p className="fw-500 mb-20">
                        Presjek stanja za današnji dan do momenta štampanja
                        izvještaja
                      </p>
                      <button className="btn btn__primary btn__dark" type="submit">Prikaži</button>
                    </div>
                    </Form>
                  </Formik>
                </div>

                <div className="col-md-4">
                  <Formik
                      initialValues={{
                        datum: new Date(),
                        izvjestaj: 'fiskalni-dnevni-izvjestaj'
                      }}
                      onSubmit={handleSubmit}
                      enableReinitialize
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div className="box-modules">
                          <h3 className="heading-tertiary fw-500">
                              Fiskalni dnevni izvještaj
                          </h3>
                          <p className="txt-light">Kraj radnog dana</p>
                          <hr />
                          <div className="form__group">
                            <ReactDatePicker
                              popperProps={{
                                positionFixed: true
                              }}
                              selected={values.datum}
                              dateFormat="yyyy-MM-dd"
                              className="form__input w-100"
                              name="datum"
                              autoComplete="off"
                              onChange={date => setFieldValue('datum', date)}
                            />
                          </div>
                          <button className="btn btn__primary btn__dark" type="submit">
                            Prikaži
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className="col-md-4">
                  <Formik
                      initialValues={{
                        datum_od: new Date(),
                        datum_do: new Date(),
                        izvjestaj: 'periodicni-fiskalni-izvjestaj'
                      }}
                      onSubmit={handleSubmit}
                      enableReinitialize
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div className="box-modules">
                          <h3 className="heading-tertiary fw-500">
                              Periodični fiskalni izvještaj
                          </h3>
                          <p className="txt-light">Za odabrane datume</p>
                          <hr />
                          <div className="section-box__right--bottom-wrap">
                            <div className="half">
                              <div className="form__group mb-15">
                                <ReactDatePicker
                                    popperProps={{
                                      positionFixed: true
                                    }}
                                    selected={values.datum_od}
                                    dateFormat="yyyy-MM-dd"
                                    className="form__input"
                                    name="datum_od"
                                    autoComplete="off"
                                    onChange={date => setFieldValue('datum_od', date)}
                                />
                              </div>
                            </div>
                            <div className="half">
                              <div className="form__group">
                                <ReactDatePicker
                                    popperProps={{
                                      positionFixed: true
                                    }}
                                    selected={values.datum_do}
                                    dateFormat="yyyy-MM-dd"
                                    className="form__input"
                                    name="datum_do"
                                    autoComplete="off"
                                    onChange={date => setFieldValue('datum_do', date)}
                                />
                              </div>
                            </div>
                          </div>
                          <button className="btn btn__primary btn__dark" type="submit">Prikaži</button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className="col-md-4">
                  <Formik
                      initialValues={{
                        datum_od: new Date(),
                        datum_do: new Date(),
                        izvjestaj: 'periodični-analiticki-pregled'
                      }}
                      onSubmit={handleSubmit}
                      enableReinitialize
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div className="box-modules">
                          <h3 className="heading-tertiary fw-500">
                              Elektronski žurnal
                          </h3>
                          <p className="txt-light">Periodični analitički pregled</p>
                          <hr />
                          <div className="section-box__right--bottom-wrap">
                            <div className="half">
                              <div className="form__group mb-15">
                                <ReactDatePicker
                                    popperProps={{
                                      positionFixed: true
                                    }}
                                    selected={values.datum_od}
                                    dateFormat="yyyy-MM-dd"
                                    className="form__input"
                                    name="datum_od"
                                    autoComplete="off"
                                    onChange={date => setFieldValue('datum_od', date)}
                                />
                              </div>
                            </div>
                            <div className="half">
                              <div className="form__group">
                                <ReactDatePicker
                                    popperProps={{
                                      positionFixed: true
                                    }}
                                    selected={values.datum_do}
                                    dateFormat="yyyy-MM-dd"
                                    className="form__input"
                                    name="datum_do"
                                    autoComplete="off"
                                    onChange={date => setFieldValue('datum_do', date)}
                                />
                              </div>
                            </div>
                          </div>
                          <button className="btn btn__primary btn__dark" type="submit">Prikaži</button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className="col-md-4">
                  <Formik
                      initialValues={{
                        datum_od: new Date(),
                        datum_do: new Date(),
                        izvjestaj: 'periodicni-analiticki-pregled-offline'
                      }}
                      onSubmit={handleSubmit}
                      enableReinitialize
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div className="box-modules">
                          <h3 className="heading-tertiary fw-500">
                              Offline Elektronski žurnal
                          </h3>
                          <p className="txt-light">Periodični analitički pregled - offline</p>
                          <hr />
                          <div className="section-box__right--bottom-wrap">
                            <div className="half">
                              <div className="form__group mb-15">
                                <ReactDatePicker
                                    popperProps={{
                                      positionFixed: true
                                    }}
                                    selected={values.datum_od}
                                    dateFormat="yyyy-MM-dd"
                                    className="form__input"
                                    name="datum_od"
                                    autoComplete="off"
                                    onChange={date => setFieldValue('datum_od', date)}
                                />
                              </div>
                            </div>
                            <div className="half">
                              <div className="form__group">
                                <ReactDatePicker
                                    popperProps={{
                                      positionFixed: true
                                    }}
                                    selected={values.datum_do}
                                    dateFormat="yyyy-MM-dd"
                                    className="form__input"
                                    name="datum_do"
                                    autoComplete="off"
                                    onChange={date => setFieldValue('datum_do', date)}
                                />
                              </div>
                            </div>
                          </div>
                          <button className="btn btn__primary btn__dark" type="submit">Prikaži</button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className="col-md-4">
                  <Formik
                      initialValues={{
                        datum_od: new Date(),
                        datum_do: new Date(),
                        izvjestaj: 'periodicni-analiticki-pregled-korektivni'
                      }}
                      onSubmit={handleSubmit}
                      enableReinitialize
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div className="box-modules">
                          <h3 className="heading-tertiary fw-500">
                              Elektronski žurnal korektivni
                          </h3>
                          <p className="txt-light">Analitički pregled korektivnih</p>
                          <hr />
                          <div className="section-box__right--bottom-wrap">
                            <div className="half">
                              <div className="form__group mb-15">
                                <ReactDatePicker
                                    popperProps={{
                                      positionFixed: true
                                    }}
                                    selected={values.datum_od}
                                    dateFormat="yyyy-MM-dd"
                                    className="form__input"
                                    name="datum_od"
                                    autoComplete="off"
                                    onChange={date => setFieldValue('datum_od', date)}
                                />
                              </div>
                            </div>
                            <div className="half">
                              <div className="form__group">
                                <ReactDatePicker
                                    popperProps={{
                                      positionFixed: true
                                    }}
                                    selected={values.datum_do}
                                    dateFormat="yyyy-MM-dd"
                                    className="form__input"
                                    name="datum_do"
                                    autoComplete="off"
                                    onChange={date => setFieldValue('datum_do', date)}
                                />
                              </div>
                            </div>
                          </div>
                          <button className="btn btn__primary btn__dark" type="submit">Prikaži</button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
        </div>
      </>
    );
};

export default Izvjestaji;
