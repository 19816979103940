import React from 'react';

import List from '../shared/lists/List';
import PoslednjeNovostiTableRow from './PoslednjeNovostiTableRow';

const PoslednjeNovostiTable = ({ novosti }) => {
    if (! novosti.data) {
        return null;
    }

    return (
        <>
            <List
                data={novosti?.data}
                renderItem={PoslednjeNovostiTableRow}
            />
        </>
    );
};

export default PoslednjeNovostiTable;