import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import {
  formatirajCijenu,
} from '../../../helpers/racuni';
import DropDownStatic from "../../shared/forms/DropDownStatic";
import { NACIN_PLACANJA_BEZGOTOVINSKI } from "../../../constants/racuni";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {avansniRacuniSelector} from "../../../store/selectors/RacuniSelector";

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const BezgotovinskiUkupno = (odabraniRacun = null) => {
  const { values, setFieldValue } = useFormikContext();
  const [ ostatakRacuna, setOstatakRacuna ] = useState(0);

  const avansniRacuni = useSelector(avansniRacuniSelector());

  let ukupnoBezPdv;
  let ukupnoSaPdv;
  if (values.tip_racuna === 'knjizno') {
    ukupnoBezPdv = Object.values(values.stavke).reduce((r, { cijena_bez_pdv_popust, kolicina, iznos_povrata_bez_pdv }) => (r + cijena_bez_pdv_popust * kolicina) + iznos_povrata_bez_pdv, 0);

    ukupnoSaPdv = Object.values(values.stavke).reduce((r, { cijena_sa_pdv_popust, kolicina, iznos_povrata_sa_pdv }) => r + cijena_sa_pdv_popust * kolicina + iznos_povrata_sa_pdv, 0);
  } else {
    ukupnoBezPdv = Object.values(values.stavke).reduce((r, { cijena_bez_pdv_popust, kolicina }) => r + cijena_bez_pdv_popust * kolicina, 0);

    ukupnoSaPdv = Object.values(values.stavke).reduce((r, { cijena_sa_pdv_popust, kolicina }) => r + cijena_sa_pdv_popust * kolicina, 0);
  }

  let ukupnoPdv = ukupnoSaPdv - ukupnoBezPdv;

  let preostalaCijenaAvansnihRacuna;
  if (values.nacin_placanja === 'ADVANCE') {
    let odabraniRacuni = [];
    values?.referentni_ikofi?.map((ikof) => {
      avansniRacuni.filter((racun) => racun.ikof === ikof)
          .map((item) => (
              odabraniRacuni.push(parseFloat(item.ukupna_cijena_sa_pdv_popust) - (item.ikof_reference_sum_ukupna_cijena_sa_pdv_popust * -1))
          ))
    });

    preostalaCijenaAvansnihRacuna = odabraniRacuni.reduce((a, b) =>  a = a + b , 0);
  }

  useEffect(() => {
    if (values.nacin_placanja === 'ADVANCE') {
      let odabraniRacuni = [];
      if (values?.referentni_ikofi && values?.nacin_placanja === 'ADVANCE') {
        values?.referentni_ikofi?.map((ikof) => {
          avansniRacuni.filter((racun) => racun.ikof === ikof)
              .map((item) => (
                  odabraniRacuni.push(parseFloat(item.ukupna_cijena_sa_pdv_popust) - (item.ikof_reference_sum_ukupna_cijena_sa_pdv_popust * -1))
              ))
        });
      }

      let preostalaCijenaAvansnihRacuna = odabraniRacuni.reduce((a, b) =>  a = a + b , 0);

      setOstatakRacuna(preostalaCijenaAvansnihRacuna);

      if (preostalaCijenaAvansnihRacuna < ukupnoSaPdv && preostalaCijenaAvansnihRacuna !== 0) {
        toast.info('Ukupan iznos računa je veći od izabranih avansnih računa. Odaberite dodatni način plaćanja!',
            toastSettings
        );
        setFieldValue('vise_nacina_placanja', true);
        setFieldValue('dodatni_nacin_placanja', 'ACCOUNT');
      } else {
        setFieldValue('vise_nacina_placanja', false);
        setFieldValue('dodatni_nacin_placanja', null);
      }
    }
  }, [ukupnoSaPdv]);

  return (
    <>
      <h2 className="heading-secondary">Ukupno</h2>
      <div className="main-content__box">
        <div className="content">
          <div className="main-content__box--inner-wrapper">
            <div className="section-box">
              <div className="section-box__left">
                <div className="form-group">
                  <label className="form__label" htmlFor="">
                    Napomena
                  </label>
                  <textarea
                    name="opis"
                    id=""
                    cols="30"
                    rows="6"
                    className="form__textarea df"
                    defaultValue={odabraniRacun ? odabraniRacun?.odabraniRacun?.opis : ''}
                    onBlur={(event) =>
                      setFieldValue('opis', event.target.value)
                    }
                  ></textarea>
                </div>
              </div>
              <div className="section-box__right">
                <div className="section-box__right--full-h">
                  <div className="price">
                    <div className="form__box h-100">
                      <div>
                        <p className="txt-light">Ukupan iznos PDV-a</p>
                      </div>
                      <div className="heading-secondary mb-0">
                        {formatirajCijenu(ukupnoPdv)}
                      </div>
                    </div>
                  </div>
                  <div className="price">
                    <div className="form__box h-100">
                      <div>
                        <p className="txt-light">Ukupna cijena bez PDV-a</p>
                      </div>
                      <div className="heading-secondary mb-0">
                        {' '}
                        {formatirajCijenu(ukupnoBezPdv)}
                      </div>
                    </div>
                  </div>
                  <div className="price">
                    <div className="form__box h-100">
                      <div>
                        <p className="txt-light">Ukupna cijena sa PDV-om</p>
                      </div>
                      <div className="heading-secondary mb-0">
                        {' '}
                        {formatirajCijenu(ukupnoSaPdv)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {values.vise_nacina_placanja && values.nacin_placanja === 'ADVANCE' ? (
                <div className="half mt-4 mb-4">
                <div className="section-box bl__top pt-md-3">
                  <div className="section-box__left">
                    <h3 class="heading-tertiary">Dodatni način plaćanja</h3>
                    <p className="txt-light b-mob-mb-20">Ukupan iznos računa je veći od iznosa na odabranim Avansnim računima pa je potrebno izabrati način plaćanja za preostali iznos.</p>
                  </div>
                  <div className="section-box__right">
                    <div className="section-box__right--full-h pt-md-0">
                      <div className="price">
                        <div className="form__box h-100">
                          <div>
                            <p className="txt-light">Preostali iznos za dodatni način plaćanja</p>
                          </div>
                          <div className="heading-secondary mb-0">
                          {formatirajCijenu(ukupnoSaPdv - preostalaCijenaAvansnihRacuna)}
                          </div>
                        </div>
                      </div>
                      <div className="price" style={{ width: '66%' }}>
                        <label className="form__label">Dodatni način plaćanja</label>
                        <DropDownStatic
                            name="dodatni_nacin_placanja"
                            options={NACIN_PLACANJA_BEZGOTOVINSKI}
                            defaultValue={NACIN_PLACANJA_BEZGOTOVINSKI[0]}
                        />
                      </div>
                    </div>

                  </div>
                </div>
                </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default BezgotovinskiUkupno;
