import React, { useCallback } from 'react';
import ReactDOM from "react-dom";
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import {
  AUTH,
  ERRORS,
} from '../constants/routes';
import AuthRoute from './AuthRoute';
import Login from '../components/auth/Login';
import PasswordRouter from './PasswordRouter';
import SidebarLayout from '../components/layout/sidebar/SidebarLayout';
import CustomPrompt from "../components/shared/CustomPrompt";
import ScrollToTop from "./ScrollToTop";

const AppRouter = () => {
  const userConfirmation = useCallback((message, callback) => {
    const node = document.getElementById("custom-prompt");

    const cleanUp = (answer) => {
        ReactDOM.unmountComponentAtNode(node);
        callback(answer);
    };

    ReactDOM.render(<CustomPrompt message={message} cleanUp={cleanUp} />, node);
  }, []);

  return (
    <BrowserRouter getUserConfirmation={userConfirmation}>
        <ScrollToTop />
        <Switch>
          <AuthRoute path={AUTH.LOGIN}>
            <Login />
          </AuthRoute>
          <AuthRoute path={AUTH.PASSWORD}>
            <PasswordRouter />
          </AuthRoute>

          <SidebarLayout/>

          <Route path={ERRORS.NOT_FOUND}>Page not found.</Route>
          <Redirect to={ERRORS.NOT_FOUND} />
        </Switch>
     </BrowserRouter>
  );
};

export default AppRouter;
