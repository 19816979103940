export const STORE_ULAZNI_RACUN = 'STORE_ULAZNI_RACUN';

export const GET_ULAZNI_RACUNI = 'GET_ULAZNI_RACUNI';
export const SET_ULAZNI_RACUNI = 'SET_ULAZNI_RACUNI';

export const GET_ULAZNI_RACUN = 'GET_ULAZNI_RACUN';
export const SET_ULAZNI_RACUN = 'SET_ULAZNI_RACUN';

export const UPDATE_ULAZNI_RACUN = 'UPDATE_ULAZNI_RACUN';
export const DELETE_ULAZNI_RACUN = 'DELETE_ULAZNI_RACUN';
