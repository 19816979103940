import React from 'react';
import { useDispatch } from 'react-redux';
import {getRacuni} from '../../store/actions/RacuniActions';
import Moment from 'react-moment';
import 'moment/locale/me';
import { useHistory } from "react-router-dom";
import { ReactComponent as IconLg } from "../../assets/icon/icon-lg.svg";
import { ReactComponent as Izmjeni } from "../../assets/icon/izmjeni.svg";
import { ReactComponent as Success } from "../../assets/icon/success.svg";
import { racuniService } from "../../services/RacuniService";
import { toast} from "react-toastify";
import { renderStatus } from '../../helpers/bezgotovinskiForm';
import { formatirajCijenu } from '../../helpers/racuni';

const toastSettings = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const PredracuniTableRow = ({ item, predracuni }) => {
  const dispatch = useDispatch();
  const history = useHistory();

    const handleNapraviRacun = (e) => {
        e.stopPropagation();
        if (
            window.confirm('Jeste li sigurni da želite da napravite privremeni račun od ovog predračuna?')
        ) {
            racuniService
                .napraviRacun(item.id, false)
                .then((data) => {
                    toast.success(
                        `Uspješno ste napravili račun od ovog predračuna!`,
                        toastSettings
                    );
                    dispatch(getRacuni({tip: 'racun'}));
                    history.push('/racuni/bezgotovinski/show/' + data.data.id);
                })
                .catch((err) => {
                    let message = err?.response?.data?.error
                        ? err.response.data.error
                    : err.message;
                    toast.error(
                        'Račun nije napravljen!',
                        toastSettings
                    );
                });
        }
    };

    const handleNapraviRacunIFiskalizuj = (e) => {
        e.stopPropagation();
        if (
            window.confirm('Jeste li sigurni da želite da napravite i fiskalizujete račun od ovog predračuna?')
        ) {
            racuniService
                .napraviIFiskalizujRacun(item.id, true)
                .then((data) => {
                    toast.success(
                        `Uspješno ste napravili i fiskalizovali račun od ovog predračuna!`,
                        toastSettings
                    );
                    dispatch(getRacuni({tip: 'racun'}));
                    history.push('/racuni/bezgotovinski/show/' + data.data.id);
                })
                .catch((err) => {
                    let message = err?.response?.data?.error
                        ? err.response.data.error
                        : err.message;
                    toast.error(
                        'Račun nije napravljen i fiskalizovan!',
                        toastSettings
                    );
                });
        }
    };

    let status = renderStatus(item.status);

    let fizickaLicaPartneri;
    let preduzecaPartneri;
    if (predracuni.partneri) {
        fizickaLicaPartneri = predracuni.partneri
            .map(
                (tmp) =>
                    tmp.fizicko_lice && {
                        ime: tmp?.fizicko_lice?.ime + ' ' + tmp?.fizicko_lice?.prezime,
                        id: tmp?.id,
                    }
            )
            .filter((tmp) => tmp != null);
        preduzecaPartneri = predracuni?.partneri
            ?.map(
                (tmp) =>
                    !tmp.fizicko_lice && {
                        ime: tmp?.preduzece_partner?.kratki_naziv,
                        id: tmp?.id,
                    }
            )
            .filter((tmp) => tmp != null);
    }

    const handleClick = () => {
        history.push(`/predracuni/${item.id}`);
    };

    const handleIzmjeni = (e) => {
        e.stopPropagation();
        history.push(`/predracuni/edit/${item.id}`);
    };

    const renderKupac = () => {
        if (preduzecaPartneri?.find((fl) => fl.id === item?.partner_id)?.ime) {
            return (
                <td className="cd fw-500">
                    {preduzecaPartneri?.find((fl) => fl.id === item?.partner_id)?.ime}
                </td>
            );
        }

        if (fizickaLicaPartneri?.find((fl) => fl.id === item?.partner_id)?.ime) {
            return (
                <td className="cd fw-500">
                    {fizickaLicaPartneri?.find((fl) => fl.id === item?.partner_id)?.ime}
                </td>
            )
        }
    }

  return (
    <tr onClick={handleClick}>
      <td className="cl">{item.redni_broj}</td>

        {renderKupac()}

        {! preduzecaPartneri && ! fizickaLicaPartneri && (
            <td className="cd fw-500">
                {item?.partner?.fizicko_lice_id
                    ? item?.partner?.fizicko_lice?.ime +
                    ' ' +
                    item?.partner?.fizicko_lice?.prezime
                    : item?.partner?.preduzece_partner?.kratki_naziv}
            </td>
        )}
      <td className="cl dshow-cell">
          {formatirajCijenu(item?.ukupna_cijena_bez_pdv_popust)}
      </td>
      <td className="cl dshow-cell">
          {formatirajCijenu(item?.ukupan_iznos_pdv)}
      </td>
      <td className="cd fw-500">
          {formatirajCijenu(item?.ukupna_cijena_sa_pdv_popust)}
      </td>
      <td className="cd fw-500">
        {<span className={status.color}>{status.value}</span>}
      </td>
      <td className="cl">
        {/* {new Date(item.created_at).toLocaleDateString('en-GB')} */}
        <Moment locale="me" format="DD. MMM YYYY.">
          {item.created_at}
        </Moment>
      </td>
        <td className="mob-absolute-topright">
            <div className="df jc-end ai-c">
                <button className="btn btn__light-dd btn__xs">
                    <IconLg />
                    <div className="drop-down">
                        <a onClick={handleIzmjeni}>
                            <Izmjeni />
                            Izmijeni
                        </a>
                        <a onClick={handleNapraviRacun}>
                            <Success />
                            Napravi račun od predračuna
                        </a>
                        <a onClick={handleNapraviRacunIFiskalizuj}>
                            <Success />
                            Napravi i fiskalizuj račun od predračuna
                        </a>
                    </div>
                </button>
            </div>
        </td>
    </tr>
  );
};

export default PredracuniTableRow;
