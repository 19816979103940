export const STORE_ROBE = 'STORE_ROBE';

export const GET_ROBA = 'GET_ROBA';
export const SET_ROBA = 'SET_ROBA';

export const GET_ROBE = 'GET_ROBE';
export const SET_ROBE = 'SET_ROBE';

export const UPDATE_ROBE = 'UPDATE_ROBE';
export const DELETE_ROBE = 'DELETE_ROBE';
