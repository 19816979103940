import React, { useRef } from 'react';
import { useReactToPrint } from "react-to-print";

import ObicniIzvjestaj from "./Tipovi/ObicniIzvjestaj";
import PeriodicniIzvjestaj from "./Tipovi/PeriodicniIzvjestaj";

const IzvjestajDetails = izvjestaj => {
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const renderButton = () => {
        return (
            <div className="title">
                <h1 className="heading-primary">
                    {izvjestaj.izvjestaj.tip_dokumenta_izvjestaja}
                </h1>
                <button
                    className="btn btn__primary mob-mb-20 ml-m mob-ml-0"
                    onClick={handlePrint}
                >
                    <svg
                        className="icon icon__light lg mr-xs"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                        />
                    </svg>
                    Štampaj
                </button>
            </div>
        );
    }

    if (izvjestaj.izvjestaj.tip_dokumenta_izvjestaja?.substring(0, 21) === 'Periodični analitički') {
        return (
            <div className="screen-content">
                { renderButton() }
                <PeriodicniIzvjestaj izvjestaj={izvjestaj} ref={componentRef} />
            </div>
        );
    }

    return (
        <div className="screen-content">
            { renderButton() }
            <ObicniIzvjestaj izvjestaj={izvjestaj} ref={componentRef} />
        </div>
    );
};

export default IzvjestajDetails;