import React from 'react';
import { useDispatch } from 'react-redux';
import { getRacuni } from '../../store/actions/RacuniActions';
import RacuniTableRow from './RacuniTableRow';
import { useHistory } from "react-router-dom";
import Pagination from "../shared/lists/Pagination";

const RacuniTable = ({ racuni, searchParams, page }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { last_page } = racuni;

  const handlePageClick = (data) => {
    history.push(`/racuni?page=${++data.selected}`);
    dispatch(getRacuni({ page: data.selected, ...searchParams, tip: 'racun' }));
  }

  return (
    <>
      <div className="table-wrapper">
        <table className="table cursor">
          <thead>
            <tr>
              <th className="w-5 b-mob-w-100">
                <span className="heading-quaternary"> </span>
              </th>
              <th>
                <span className="heading-quaternary">Broj</span>
              </th>
              <th>
                <span className="heading-quaternary">Preduzeće/Lice</span>
              </th>
              <th className="dshow-cell">
                <span className="heading-quaternary">Iznos bez PDV</span>
              </th>
              <th className="dshow-cell">
                <span className="heading-quaternary">PDV</span>
              </th>
              <th>
                <span className="heading-quaternary">Iznos sa PDV</span>
              </th>
              <th>
                <span className="heading-quaternary">Status</span>
              </th>

              <th className="date-width">
                <span className="heading-quaternary">Datum</span>
              </th>
              <th className="w-5">
                <span className="heading-quaternary"> </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* <List data={racuni.data || []} renderItem={RacuniTableRow} /> */}
            {racuni &&
              racuni.data
                .map((item) => (
                  <RacuniTableRow key={item.id} item={item} racuni={racuni} />
                ))}
          </tbody>
        </table>
      </div>
      <Pagination
          pageCount={last_page}
          page={page}
          handlePageClick={handlePageClick}
      />
    </>
  );
};

export default RacuniTable;
