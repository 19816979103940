import produce from 'immer';
import { SET_ZAVISNI_TROSAK, SET_ZAVISNI_TROSKOVI } from '../actionTypes/ZavisniTroskoviTypes';

const initialState = {
  zavisniTroskovi: {
    current_page: 1,
    last_page: 1,
    total: 0,
    data: [],
  },
  zavisniTrosak: {},
};

const zavisniTroskoviReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    /*eslint-disable indent */
    switch (action.type) {
      case SET_ZAVISNI_TROSKOVI:
        draft.zavisniTroskovi = action.payload;
        break;
      case SET_ZAVISNI_TROSAK:
        draft.zavisniTrosak = action.payload;
        break;
      default:
        break;
    }
  });

export default zavisniTroskoviReducer;
