import produce from 'immer';
import { SET_GRUPE } from "../actionTypes/GrupeActionTypes";

const initialState = {
    grupe: []
};

const grupeReducer = (state = initialState, action) =>
    produce(state, draft => {
        /*eslint-disable indent */
        switch (action.type) {
            case SET_GRUPE:
                draft.grupe = action.payload;
                break;
            default:
                break;
        }
    });

export default grupeReducer;
