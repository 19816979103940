import React from 'react';

class ObicniIzvjestaj extends React.Component {
    render() {
        const izvjestajDetails = this.props.izvjestaj.izvjestaj;

        if (izvjestajDetails.racuni_kod === undefined) {
            return null;
        }

        const listCodes = izvjestajDetails.racuni_kod.map((racun) =>
            <>
                <tr>
                    <td width="340">
                        <p>Broj računa</p>
                    </td>
                    <td colSpan="6" width="296" className="word-break">
                        <p>{racun.broj_racuna}</p>
                    </td>
                </tr>
                <tr>
                    <td width="340">
                        <p>IKOF</p>
                    </td>
                    <td colSpan="6" width="296" className="word-break">
                        <p>{racun.ikof}</p>
                    </td>
                </tr>
                <tr>
                    <td width="340">
                        <p>JIKR</p>
                    </td>
                    <td colSpan="6" width="296">
                        <p>{racun.jikr}</p>
                    </td>
                </tr>
                {/* <tr>
                    <td width="340">
                        <p>QR CODE</p>
                    </td>
                    <td colSpan="6" width="296">
                        <img src={`${url}/storage/${racun.qr_code}`} alt='qr_code'/>
                    </td>
                </tr> */}
                <tr>
                    <td width="340">
                        <p>&nbsp;</p>
                    </td>
                    <td colSpan="6" width="296">
                        <p>&nbsp;</p>
                    </td>
                </tr>
            </>
        );

        const renderFiskalniDani = () => {
            if (izvjestajDetails.tip_dokumenta_izvjestaja === 'PERIODIČNI FISKALNI IZVJEŠTAJ') {
                return (
                    <>
                        <tr>
                            <td colSpan="7" width="636">
                                <p>
                                    <strong>
                                        FISKALNI DANI OBUHVAĆENI IZVJE&Scaron;TAJEM
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>
                                    <strong>
                                        FISKALNI DANI OBUHVAĆENI IZVJE&Scaron;TAJEM (OD BROJA-DO
                                        BROJA)
                                    </strong>
                                </p>
                            </td>
                            <td colSpan="3" width="135">
                                <p>
                                    <strong>
                                        {izvjestajDetails.fiskalni_dani_obuhvaceni_izvjestajem.od}
                                    </strong>
                                </p>
                            </td>
                            <td colSpan="3" width="161">
                                <p>
                                    <strong>
                                        {izvjestajDetails.fiskalni_dani_obuhvaceni_izvjestajem.do}
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>
                                    <strong>
                                        FISKALNI RAČUNI (OD BROJA-DO BROJA)
                                    </strong>
                                </p>
                            </td>
                            <td colSpan="3" width="135">
                                <p>
                                    {izvjestajDetails.fiskalni_racuni_obuhvaceni_izvjestajem.od}
                                </p>
                            </td>
                            <td colSpan="3" width="161">
                                <p>
                                    {izvjestajDetails.fiskalni_racuni_obuhvaceni_izvjestajem.do}
                                </p>
                            </td>
                        </tr>
                    </>
                );
            }

            return null;
        }

        const renderFiskalniRacuni = () => {
            if (izvjestajDetails.tip_dokumenta_izvjestaja === 'PERIODIČNI FISKALNI IZVJEŠTAJ') {
                return (
                    <>
                        <tr>
                            <td width="340">
                                <p>
                                    <strong>
                                        FISKALNI RAČUNI (OD BROJA-DO BROJA)
                                    </strong>
                                </p>
                            </td>
                            <td colSpan="4" width="145">
                                <p>{izvjestajDetails.fiskalni_racuni.od}</p>
                            </td>
                            <td width="151">
                                <p>{izvjestajDetails.fiskalni_racuni.do}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>
                                    <strong>
                                        FISKALNI DANI (OD BROJA &ndash;DO BROJA)
                                    </strong>
                                </p>
                            </td>
                            <td colSpan="4" width="145">
                                <p>
                                    {izvjestajDetails.fiskalni_dani.od}
                                </p>
                            </td>
                            <td width="151">
                                <p>
                                    {izvjestajDetails.fiskalni_dani.do}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>&nbsp;</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                    </>
                );
            }

            return null;
        }

        return (
            <div className="page-izvjestaji">
                <h2 className="mb-20">{izvjestajDetails.tip_dokumenta_izvjestaja}</h2>
                <table width="636">
                    <colgroup>
                        <col span="1" style={{width: '65%'}} />
                        <col span="1" style={{width: '35%'}} />
                    </colgroup>
                    <tbody>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    Naziv poreskog obveznika
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                <em>
                                    {izvjestajDetails.naziv_poreskog_obveznika}
                                </em>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    Sjedi&scaron;te poreskog obveznika
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                <em>
                                    {izvjestajDetails.sjediste_poreskog_obveznika}
                                </em>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    Pib:
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                <em>
                                    {izvjestajDetails.pib}
                                </em>
                            </p>
                        </td>
                    </tr>
                    {/* <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    Naziv objekta:
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                <em>
                                    {izvjestajDetails.naziv_objekta}
                                </em>
                            </p>
                        </td>
                    </tr> */}
                    {/* <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    Adresa objekta:
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                <em>
                                    {izvjestajDetails.adresa_objekta}
                                </em>
                            </p>
                        </td>
                    </tr> */}
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    ENU KOD:
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                <em>
                                    {izvjestajDetails.enu_kod}
                                </em>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>&nbsp;</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    Tip dokumenta (izvje&scaron;taja):
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.tip_dokumenta_izvjestaja}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>&nbsp;</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>OD</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.od}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>DO</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.do}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>&nbsp;</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="325">
                            <p>
                                <strong>
                                    Broj promjena poreza:
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="343">
                            <p>
                                {izvjestajDetails.broj_promjena_poreza}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>&nbsp;</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p><strong>PORESKA STOPA = 21%</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    OSNOVICA 21%
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.poreska_stopa_21.osnovica_21.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    IZNOS POREZA 21%
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.poreska_stopa_21.iznos_poreza_21.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    PROMET 21%
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.poreska_stopa_21.promet_po_stopi_21.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>&nbsp;</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p><strong>PORESKA STOPA = 7%</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    OSNOVICA 7%
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.poreska_stopa_7.osnovica_7.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    IZNOS POREZA 7%
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.poreska_stopa_7.iznos_poreza_7.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    PROMET 7%
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.poreska_stopa_7.promet_po_stopi_7.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>&nbsp;</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p><strong>PORESKA STOPA = 0%</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    OSNOVICA 0%
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.poreska_stopa_0.osnovica_0.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    IZNOS POREZA 0%
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.poreska_stopa_0.iznos_poreza_0.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    PROMET 0%
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.poreska_stopa_0.promet_po_stopi_0.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>&nbsp;</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p><strong>OSLOBOĐENI PROMET</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    OSLOBOĐENI PROMET
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.oslobodjeni_promet.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="7" width="636">
                            <p>
                                <strong>
                                    UKUPNO &ndash; OSNOVICA/POREZ/PROMET
                                </strong>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    UKUPNO OSNOVICA (21%, 7%, 0%)
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.ukupno.ukupno_osnovica_po_stopama_21_7_0.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    UKUPNO POREZ (21%, 7%, 0%)
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.ukupno.ukupno_porez_po_stopama_21_7_0.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    UKUPNO PROMET (21%, 7%, 0%)
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.ukupno.ukupan_promet.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="7" width="636">
                            <p>
                                <strong>
                                    PROMET UKUPNO
                                </strong>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    UKUPNO PROMET (21%, 7%, 0%)
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.ukupno.ukupan_promet.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>&nbsp;</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    {renderFiskalniRacuni()}
                    </tbody>
                </table>
                <table>
                    <tbody>
                    <tr>
                        <td width="310">
                            <p><strong>&nbsp;</strong></p>
                        </td>
                        <td colspan="2" width="98">
                            <p>
                                <strong>
                                    UKUPAN BROJ RAČUNA
                                </strong>
                            </p>
                        </td>
                        <td colspan="2" width="98">
                            <p>
                                <strong>
                                    UKUPAN PROMET
                                </strong>
                            </p>
                        </td>
                        <td colspan="2" width="98">
                            <p>
                                <strong>
                                    UKUPAN POREZ
                                </strong>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    RAČUNI SA KOREKCIJOM
                                </strong>
                            </p>
                        </td>
                        <td colspan="2" width="98">
                            <p>
                                <strong>
                                    {izvjestajDetails.racuni_sa_korekcijom.ukupan_broj_racuna}
                                </strong>
                            </p>
                        </td>
                        <td colspan="2" width="98">
                            <p>
                                <strong>
                                    {izvjestajDetails.racuni_sa_korekcijom.ukupan_promet.toFixed(2)}
                                </strong>
                            </p>
                        </td>
                        <td colspan="2" width="98">
                            <p>
                                <strong>
                                    {izvjestajDetails.racuni_sa_korekcijom.ukupan_porez.toFixed(2)}
                                </strong>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    RAČUNI U OFFLINE REŽIMU RADA
                                </strong>
                            </p>
                        </td>
                        <td colspan="2" width="98">
                            <p>
                                <strong>
                                    {izvjestajDetails.racuni_u_offline_rezimu.ukupan_broj_racuna}
                                </strong>
                            </p>
                        </td>
                        <td colspan="2" width="98">
                            <p>
                                <strong>
                                    {izvjestajDetails.racuni_u_offline_rezimu.ukupan_promet.toFixed(2)}
                                </strong>
                            </p>
                        </td>
                        <td colspan="2" width="98">
                            <p>
                                <strong>
                                    {izvjestajDetails.racuni_u_offline_rezimu.ukupan_porez.toFixed(2)}
                                </strong>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    RAČUNI ''ORDER''
                                </strong>
                            </p>
                        </td>
                        <td colspan="2" width="98">
                            <p>
                                <strong>
                                    {izvjestajDetails.racuni_order.ukupan_broj_racuna}
                                </strong>
                            </p>
                        </td>
                        <td colspan="2" width="98">
                            <p>
                                <strong>
                                    {izvjestajDetails.racuni_order.ukupan_promet.toFixed(2)}
                                </strong>
                            </p>
                        </td>
                        <td colspan="2" width="98">
                            <p>
                                <strong>
                                    {izvjestajDetails.racuni_order.ukupan_porez.toFixed(2)}
                                </strong>
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                        <col span="1" style={{width: '75%'}} />
                        <col span="1" style={{width: '25%'}} />
                    </colgroup>
                    <tbody>
                    <tr>
                        <td colSpan="7" width="636">
                            <p>
                                <strong>VRIJEDNOST PROMETA ISKAZANA PO NAČINU PLAĆANJA</strong>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="7" width="636">
                            <p>
                                -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <strong>PROMET EVIDENTIRAN U GOTOVINI - </strong>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>1. BANKNOTE (Novčanice i kovanice)</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.promet_evidentiran_u_gotovini.banknote.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                2. CARD (Kreditna i debitna kartica banke izdata fizičkom licu)
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.promet_evidentiran_u_gotovini.card.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                3. ORDER (Račun jo&scaron; nije plaćen. Biće plaćen zbirnim/
                                sumarnim računom)
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.promet_evidentiran_u_gotovini.order.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>4. OTHER-CASH (Ostala gotovinska plaćanja)</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.promet_evidentiran_u_gotovini.other_cash.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="7" width="636">
                            <p>
                                -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <strong>PROMET EVIDENTIRAN U BEZGOTOVINI -</strong>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                1. BUSINESSCARD (Kreditna i debitna kartica banke izdata
                                poreskom obvezniku)
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.promet_evidentiran_u_bezgotovini.business_card.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>2. SVOUCHER (Jednokratni vaučer)</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.promet_evidentiran_u_bezgotovini.svoucher.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                3. COMPANY (Kartice izdate od preduzeća prodavca, poklon kartice
                                i slične prepaid kartice)
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.promet_evidentiran_u_bezgotovini.company.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                4. ORDER (Račun jo&scaron; nije plaćen. Biće plaćen zbirnim/
                                sumarnim računom)
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.promet_evidentiran_u_bezgotovini.order.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>5.ADVANCE (Plaćanje avansom)</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.promet_evidentiran_u_bezgotovini.advance.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>6. ACCOUNT (Transakcioni račun (virman))</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.promet_evidentiran_u_bezgotovini.account.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>7. FACTORING (Faktoring)</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.promet_evidentiran_u_bezgotovini.factoring.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>8. OTHER (Ostala bezgotovinska plaćanja)</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.promet_evidentiran_u_bezgotovini.other.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="7" width="636">
                            <p>
                                <strong>UKUPAN IZNOS POVUČENOG NOVCA IZ ENU - </strong
                                ><strong>WITHDRAW </strong><strong>(GOTOVINA)</strong>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>WITHDRAW</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.ukupno_withdraw.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="7" width="636">
                            <p>
                                <strong>
                                    KALKULACIJA GOTOVINE
                                </strong>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    INICIJALNI GOTOVINSKI DEPOZIT
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.inicijalni_gotovinski_depozit.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    UKUPAN PROMET
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.ukupan_promet.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    NON-CASH PROMET (KARTICA, VIRMAN...)
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.non_cash_promet.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>CARD I ORDER (CASH) PROMET</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.card_i_order_promet.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    UKUPNO WITHDRAW
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.ukupno_withdraw.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    GOTOVINA (NOVČANICE I KOVANICE) U ENU:
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.gotovina_u_enu.toFixed(2)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>&nbsp;</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p>
                                <strong>
                                    DATUM DOKUMENTA
                                </strong>
                            </p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.datum_dokumenta}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p><strong>VRIJEME DOKUMENTA</strong></p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>{izvjestajDetails.vrijeme_dokumenta}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="340">
                            <p><strong>OPERATER (OZNAKA) </strong></p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>
                                {izvjestajDetails.operater}
                            </p>
                        </td>
                    </tr>
                    {renderFiskalniDani()}
                    <tr>
                        <td width="340">
                            <p>&nbsp;</p>
                        </td>
                        <td colSpan="6" width="296">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    {listCodes}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ObicniIzvjestaj;
