import ApiService from "./ApiService";

const ENDPOINTS = {
    NOVOSTI: 'blogs',
    NOVOST: 'blogs/{slug}',
    NEPROCITANE: 'neprocitane-novosti',
};

class NovostiService extends ApiService {
    getNovosti = (params) => this.apiClient.get(ENDPOINTS.NOVOSTI, { params });

    getNovost = (slug) => this.apiClient.get(ENDPOINTS.NOVOST.replace('{slug}', slug));

    getNeprocitane = (params) => this.apiClient.get(ENDPOINTS.NEPROCITANE, { params });

    getPoslednje = (params) => this.apiClient.get(ENDPOINTS.NOVOSTI, { params });
}

export const novostiService = new NovostiService();