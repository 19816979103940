import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  PDV_OBVEZNIK,
  NACIN_PLACANJA_BEZGOTOVINSKI,
  TIPOVI_RACUNA
} from '../../../constants/racuni';
import { partneriService } from '../../../services/PartneriService';
import DropDown from '../../shared/forms/DropDown';
import DropDownStatic from '../../shared/forms/DropDownStatic';
import { Field } from "formik";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const BezgotovinskiHeader = ({ pathName, tip_racuna, kupac, nacin_placanja }) => {
  const { values, setFieldValue } = useFormikContext();

  const [ prosliMjesec, setProsliMjesec ] = useState(false);

  useEffect(() => {
    if (values.tip_racuna === 'avans') {
      setFieldValue('status', 'avansni')
    }

    if (values.tip_racuna === 'knjizno') {
        setFieldValue('status', 'knjizno')
      }

    if (values.nacin_placanja === 'ADVANCE' && ! values.partner_id) {
      toast.error('Kupac je neophodan', toastSettings);
    }
  }, [values]);

  const checkDatumIzdavanja = (date) => {
    if (date?.target?.value) {
      const dateParts = date?.target?.value.split("/");
      date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    }

    if (date > new Date()) {
      return toast.error('Datum izdavanja ne može biti veći od današnjeg!', toastSettings);
    }

    let firstDayOfLastMonth = getFirstDayOfLastMonth();

    let lastDayOfLastMonth = new Date();
    lastDayOfLastMonth.setDate(0);
    lastDayOfLastMonth.setHours(23,59,59,0);

    if (date < firstDayOfLastMonth) {
      return toast.error('Datum izdavanja mora biti u tekućem ili prethodnom mjesecu!', toastSettings);
    }

    date >= firstDayOfLastMonth && date <= lastDayOfLastMonth ? setProsliMjesec(true) : setProsliMjesec(false);

    setFieldValue('datum_izdavanja', date);
  }

  const getFirstDayOfLastMonth = () => {
    let firstDayOfLastMonth = new Date();
    firstDayOfLastMonth.setDate(0);
    firstDayOfLastMonth.setDate(1);
    firstDayOfLastMonth.setHours(0,0,0,0);

    return firstDayOfLastMonth;
  }

  const getNaciniPlacanja = () => {
    if (pathName === '/predracuni/create') {
      return [
        { value: 'ACCOUNT', label: 'ACCOUNT - Transakcioni Račun, Virman' },
        { value: 'BUSINESSCARD', label: 'BUSINESSCARD - Biznis kartica' },
        { value: 'SVOUCHER', label: 'SVOUCHER - Jednokratni Vaučer' },
        { value: 'COMPANY', label: 'COMPANY - Poklon Kartice, Prepaid Kartice itd.' },
        { value: 'FACTORING', label: 'FACTORING - Faktoring' },
        { value: 'OTHER', label: 'OTHER - Ostala Plaćanja' },
      ];
    }

    return NACIN_PLACANJA_BEZGOTOVINSKI;
  }

  const getTipRacuna = () => {
    if (pathName.includes('/ulazni-racuni')) {
      return (
        <div className="half">
          <div className="form__group">
            <label className="form__label">Broj računa</label>
            <Field
              type="text"
              name={`redni_broj`}
              className="form__input"
              placeholder="Broj računa"
              value={values.redni_broj ?? ''}
            />
          </div>
        </div>
      );
    }

    if (! pathName.includes('/predracuni')) {
      return (
        <div className="half">
          <div className="form__group">
            <label className="form__label">Tip računa</label>
            <DropDownStatic
                className="mb-12 mob-mb-20"
                name="tip_racuna"
                options={TIPOVI_RACUNA}
                defaultValue={
                  {label: tip_racuna ? tip_racuna.charAt(0).toUpperCase() + tip_racuna.slice(1) : TIPOVI_RACUNA[0].label}
                }
            />
          </div>
        </div>
      )
    } else {
      return (
          <div className="half">
            <div className="form__group">
              <label className="form__label">Tip računa</label>
              <DropDownStatic
                  className="mb-12 mob-mb-20"
                  name="tip_racuna"
                  options={[
                    { value: 'racun', label: 'Predračun' },
                  ]}
                  defaultValue={{label: 'Predračun'}}
              />
            </div>
          </div>
      )
    }
  }

  return (
    <div className="main-content__box">
      <div className="main-content__box--inner-wrapper">
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-12 pr-0">
            <h2 className="heading-secondary">Novi račun</h2>
            <p className="txt-light b-mob-mb-20">
              Unesite kupca, tip računa i krajnji datum za plaćanje
            </p>
          </div>
          <div className="col-xl-8 col-md-8">
            <div className="section-box__right tabp-fd-row">
              <div className="section-box__right--top-wrap">
                <div className="half">
                  <div className="form__group mb-15">
                    <label className="form__label">
                      {pathName === '/ulazni-racuni/create' ? 'Dobavljač' : 'Kupac'}
                    </label>
                    <DropDown
                      className="mb-12 mob-mb-20"
                      name="partner_id"
                      loadOptions={partneriService.getPartneriDropdown}
                      isSearchable
                      defaultValue={
                        {label: kupac ? kupac : 'Select...'}
                      }
                      onBlur={() => {
                        if (
                          values.partner_id == null ||
                          values.partner_id === 0
                        ) {
                          toast.error('Kupac je neophodan', toastSettings);
                        }
                      }}
                    />
                  </div>
                </div>
                { getTipRacuna() }
              </div>
              <div className="section-box__right--bottom-wrap">
                <div className="half">
                  <div className="form__group mb-15">
                    <label className="form__label">Datum izdavanja</label>
                    <ReactDatePicker
                      selected={values.datum_izdavanja}
                      onSelect={(date) => checkDatumIzdavanja(date)}
                      onBlur={(date) => checkDatumIzdavanja(date)}
                      className="form__input w-100"
                      placeholderText="Datum izdavanja"
                      dateFormat="dd/MM/yyyy"
                      minDate={getFirstDayOfLastMonth()}
                      maxDate={new Date()}
                    />
                    <p style={{ display: prosliMjesec ? '' : 'none' }} className="mt-1">
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" className="mr-s vm">
                      <path className="icon icon__fill-link" d="M8.36719 16.3281C12.7266 16.3281 16.3359 12.7109 16.3359 8.35938C16.3359 4 12.7188 0.390625 8.35938 0.390625C4.00781 0.390625 0.398438 4 0.398438 8.35938C0.398438 12.7109 4.01562 16.3281 8.36719 16.3281ZM8.36719 15C4.67969 15 1.73438 12.0469 1.73438 8.35938C1.73438 4.67188 4.67188 1.71875 8.35938 1.71875C12.0469 1.71875 15 4.67188 15.0078 8.35938C15.0156 12.0469 12.0547 15 8.36719 15ZM8.35938 9.76562C8.72656 9.76562 8.94531 9.55469 8.95312 9.14844L9.07031 5.02344C9.07812 4.625 8.76562 4.32812 8.35156 4.32812C7.92969 4.32812 7.63281 4.61719 7.64062 5.01562L7.74219 9.14844C7.75 9.54688 7.96875 9.76562 8.35938 9.76562ZM8.35938 12.3047C8.80469 12.3047 9.19531 11.9453 9.19531 11.4922C9.19531 11.0391 8.8125 10.6797 8.35938 10.6797C7.89844 10.6797 7.51562 11.0469 7.51562 11.4922C7.51562 11.9375 7.90625 12.3047 8.35938 12.3047Z" fill="#2563EB"/>
                      </svg>
                      Odabrali ste poreski period za prošli mjesec
                    </p>
                  </div>
                </div>
                <div className="half">
                  <div className="form__group">
                    <label className="form__label">Rok za plaćanje</label>
                    <ReactDatePicker
                      selected={values.datum_za_placanje}
                      onChange={(date) => date < values.datum_izdavanja.setUTCHours(0,0,0,0) ?
                          toast.error('Rok za plaćanje ne može biti prije datuma izdavanja!', toastSettings) :
                          setFieldValue('datum_za_placanje', date)
                      }
                      className="form__input w-100"
                      placeholderText="Rok za plaćanje"
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                    />
                  </div>
                </div>
              </div>
              <div className="section-box__right--bottom-wrap">
                {! pathName.includes('/ulazni-racuni') && (
                  <div className="half">
                    <div className="form__group">
                      <label className="form__label">U sistemu PDV-a?</label>
                      <DropDownStatic
                        name="pdv_obveznik"
                        options={PDV_OBVEZNIK}
                        defaultValue={PDV_OBVEZNIK[0]}
                        onChange={(pdv) => setFieldValue('pdv_obveznik', pdv)}
                      />
                    </div>
                </div>
                )}
                <div className="half">
                  <div className="form__group">
                    <label className="form__label">Način Plaćanja</label>
                    <DropDownStatic
                      name="nacin_placanja"
                      options={getNaciniPlacanja()}
                      defaultValue={{label: nacin_placanja ? nacin_placanja : NACIN_PLACANJA_BEZGOTOVINSKI[0].label}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BezgotovinskiHeader;
