export const STORE_PREDUZECE = 'STORE_PREDUZECE';

export const GET_PREDUZECA = 'GET_PREDUZECA';
export const SET_PREDUZECA = 'SET_PREDUZECA';

export const GET_PREDUZECE = 'GET_PREDUZECE';
export const SET_PREDUZECE = 'SET_PREDUZECE';

export const GET_AUTH_PREDUZECE = 'GET_AUTH_PREDUZECE';
export const SET_AUTH_PREDUZECE = 'SET_AUTH_PREDUZECE';

export const UPDATE_PREDUZECE = 'UPDATE_PREDUZECE';
export const DELETE_PREDUZECE = 'DELETE_PREDUZECE';
