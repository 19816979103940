export const STORE_PARTNERI = 'STORE_PARTNERI';

export const GET_PARTNERI = 'GET_PARTNERI';
export const SET_PARTNERI = 'SET_PARTNERI';

export const GET_PARTNER = 'GET_PARTNER';
export const SET_PARTNER = 'SET_PARTNER';

export const UPDATE_PARTNER = 'UPDATE_PARTNER';

export const DELETE_PARTNER = 'DELETE_PARTNER';
