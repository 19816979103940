import React, { useState } from 'react';
import { Field, useFormikContext } from "formik";

import { useDispatch, useSelector } from 'react-redux';
import DropDown from "../../shared/forms/DropDown";
import { jediniceMjereService } from "../../../services/JediniceMjereService";
import DropDownStatic from "../../shared/forms/DropDownStatic";
import { TIPOVI_POPUSTA, TIPOVI_POREZA } from "../../../constants/racuni";
import {
    formatirajCijenu,
    getUkupanPdv,
    getUkupnaCijenaSaPdv,
    getUkupnaCijenaBezPdv,
    getUkupnaCijenaSaPdvBezPopusta,
    getUkupnaCijenaBezPdvBezPopusta,
    getUkupanPdvBezPopusta
} from "../../../helpers/racuni";
import { toast } from "react-toastify";
import StavkeDropdown from '../NoviRacun/StavkeDropdown';
import { authPreduzeceSelector } from "../../../store/selectors/PreduzecaSelector";
import {storeUsluga} from "../../../store/actions/UslugeActions";
import BezgotovinskiNoveCijeneStavkiForm from './BezgotovinskiNoveCijeneStavkiForm';

toast.configure();

const toastSettings = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const BezgotovinskiStavkeForm = ({ pathName }) => {
    const { values, setFieldValue } = useFormikContext();
    const [ odabranaStavka, setOdabranaStavka ] = useState(false);
    const [ checkbox, setCheckbox ] = useState(false);
    const [ lastClickedInput, setLastClickedInput ] = useState('bez_pdv');
    const dispatch = useDispatch();
    const authPreduzece = useSelector(authPreduzeceSelector());
    const [ azurirajStavku, setAzurirajStavku ] = useState(false);
    const [ show, setShow ] = useState(false);

    const setSelectedType = (option, type) => {
        if (type === 'addManually') {
            setShow(true);
            setOdabranaStavka(false);
            clearAllValues();

            setFieldValue(`niz_stavke[0].naziv`, option);
            if (! values?.niz_stavke[0]?.porez_id) {
                if (authPreduzece.pdv_obveznik) {
                    setFieldValue(`niz_stavke[0].porez`, 0.21);
                    setFieldValue(`niz_stavke[0].porez_id`, 4);
                    setFieldValue(`niz_stavke[0].porez_naziv`, '21%');
                } else {
                    setFieldValue(`niz_stavke[0].porez`, 0.00);
                    setFieldValue(`niz_stavke[0].porez_id`, 1);
                    setFieldValue(`niz_stavke[0].porez_naziv`, 'Oslobodjen PDV');
                }
            }

            setFieldValue(`niz_stavke[0].jedinica_mjere_id`, 1);
            setFieldValue(`niz_stavke[0].jedinica_mjere[naziv]`, 'kom');
            setFieldValue(`niz_stavke[0].odabranaUsluga`, null);
        } else {
            setOdabranaStavka(true);
            setShow(true);

            const roba = option.roba ? option.roba : null;
            setFieldValue(`niz_stavke[0].naziv`, roba ? roba.naziv : option.naziv);
            setFieldValue(`niz_stavke[0].opis`, roba ? roba.opis : option.opis);
            setFieldValue(`niz_stavke[0].cijena_bez_pdv`, roba ? roba.cijene_roba[0].cijena_bez_pdv : option.cijena_bez_pdv);

            setFieldValue(`niz_stavke[0].cijena_bez_pdv_popust`, roba ? roba.cijene_roba[0].cijena_bez_pdv_popust : option.cijena_bez_pdv_popust);

            setFieldValue(`niz_stavke[0].roba_id`, option.id);
            setFieldValue(`niz_stavke[0].ukupna_cijena`, roba ? +roba.cijene_roba[0].ukupna_cijena : +option.ukupna_cijena);

            setFieldValue(`niz_stavke[0].cijena_sa_pdv_popust`, roba ? +roba.cijene_roba[0].cijena_sa_pdv_popust : option.cijena_sa_pdv_popust);

            setFieldValue(`niz_stavke[0].kolicina`, 1);
            setFieldValue(`niz_stavke[0].porez`, roba ? roba.cijene_roba[0].porez.stopa : option?.porez?.stopa);
            setFieldValue(`niz_stavke[0].porez_id`, roba ? roba.cijene_roba[0].porez.id : option?.porez?.id);
            setFieldValue(`niz_stavke[0].porez_naziv`, roba ? roba.cijene_roba[0].porez.naziv : option?.porez?.naziv);
            setFieldValue(`niz_stavke[0].jedinica_mjere[naziv]`, option?.jedinica_mjere?.naziv);
            if (roba) {
                setFieldValue(`niz_stavke[0].popust`, option?.atribut_robe?.popust_procenti);
            } else {
                setFieldValue(`niz_stavke[0].popust`, option?.grupa?.popust_procenti > 0 ? option?.grupa?.popust_procenti : null);
            }

            setFieldValue(`niz_stavke[0].odabranaUsluga.popust`, roba ? parseFloat(option.atribut_robe.popust_procenti) : parseFloat(option.grupa.popust_procenti));

            roba ?
                setFieldValue(`niz_stavke[0].odabranaUsluga.roba.jedinica_mjere_id`, 1) :
                setFieldValue(`niz_stavke[0].jedinica_mjere_id`, option.jedinica_mjere.id);

            setFieldValue(`niz_stavke[0].tip_popusta`, values.niz_stavke[0]?.odabranaUsluga?.tip_popusta ?? 'procenat');
        }
    };

    const setManualValues = (values, event, type) => {
        setFieldValue(`niz_stavke[0].porez`, values.porez);

        if (! values?.kolicina) {
            setFieldValue(`niz_stavke[0].kolicina`, 1);
        }

        if (type === 'bez_pdv') {
            setFieldValue(`niz_stavke[0].cijena_bez_pdv`, event.target.value);
            setFieldValue(`niz_stavke[0].cijena_bez_pdv_popust`, event.target.value);
            setFieldValue(`niz_stavke[0].nabavna_cijena`, event.target.value)

            setFieldValue(`niz_stavke[0].ukupna_cijena`, event.target.value * (1 + +values.porez));
            setFieldValue(`niz_stavke[0].cijena_sa_pdv_popust`, event.target.value * (1 + +values.porez));

            if (odabranaStavka) {
                setFieldValue(`niz_stavke[0].odabranaUsluga.cijena_bez_pdv`, event.target.value);
                setFieldValue(`niz_stavke[0].odabranaUsluga.cijena_bez_pdv_popust`, event.target.value);
                setFieldValue(`niz_stavke[0].odabranaUsluga.nabavna_cijena`, event.target.value)

                setFieldValue(`niz_stavke[0].odabranaUsluga.ukupna_cijena`, event.target.value * (1 + +values.odabranaUsluga.porez.stopa));
                setFieldValue(`niz_stavke[0].odabranaUsluga.cijena_sa_pdv_popust`, event.target.value * (1 + +values.odabranaUsluga.porez.stopa));
            }
        }

        if (type === 'sa_pdv') {
            setFieldValue(`niz_stavke[0].ukupna_cijena`, event.target.value);
            setFieldValue(`niz_stavke[0].cijena_sa_pdv_popust`, event.target.value);

            setFieldValue(`niz_stavke[0].cijena_bez_pdv`, event.target.value / (1 + +values.porez));
            setFieldValue(`niz_stavke[0].cijena_bez_pdv_popust`, event.target.value / (1 + +values.porez));
            setFieldValue(`niz_stavke[0].nabavna_cijena`, event.target.value / (1 + +values.porez))

            if (odabranaStavka) {
                setFieldValue(`niz_stavke[0].odabranaUsluga.ukupna_cijena`, +event.target.value);
                setFieldValue(`niz_stavke[0].odabranaUsluga.cijena_sa_pdv_popust`, +event.target.value);

                setFieldValue(`niz_stavke[0].odabranaUsluga.cijena_bez_pdv`, +event.target.value / (1 + +values.odabranaUsluga.porez.stopa));
                setFieldValue(`niz_stavke[0].odabranaUsluga.cijena_bez_pdv_popust`, +event.target.value / (1 + +values.porez));
                setFieldValue(`niz_stavke[0].odabranaUsluga.nabavna_cijena`, +event.target.value / (1 + +values.odabranaUsluga.porez.stopa))
            }
        }
    };

    const setManualPdv = (values, option) => {
        setFieldValue(`niz_stavke[0].porez`, option.value);
        setFieldValue(`niz_stavke[0].porez_id`, option.id);

        if (lastClickedInput === 'bez_pdv') {
            if (values?.cijena_bez_pdv) {
                setFieldValue(`niz_stavke[0].ukupna_cijena`, values.cijena_bez_pdv * (1 + +option.value));
                setFieldValue(`niz_stavke[0].cijena_sa_pdv_popust`, values.cijena_bez_pdv * (1 + +option.value));
            }

            if (values?.iznos_povrata_bez_pdv) {
                setFieldValue(`niz_stavke[0].iznos_povrata_sa_pdv`, values.iznos_povrata_bez_pdv * (1 + +option.value));
            }
        }

        if (lastClickedInput === 'sa_pdv') {
            if (values?.ukupna_cijena) {
                setFieldValue(`niz_stavke[0].cijena_bez_pdv`, values.ukupna_cijena / (1 + +option.value));
                setFieldValue(`niz_stavke[0].cijena_bez_pdv_popust`, values.ukupna_cijena / (1 + +option.value));
            }

            if (values?.iznos_povrata_sa_pdv) {
                setFieldValue(`niz_stavke[0].iznos_povrata_bez_pdv`, values.iznos_povrata_sa_pdv * (1 + +option.value));
            }
        }
    };

    const setManualJedinicaMjere = (option) => {
        setFieldValue(`niz_stavke[0].jedinica_mjere_id`, option.value);
        setFieldValue(`niz_stavke[0].jedinica_mjere[naziv]`, option.label);
    };

    const clearAllValues = () => {
        setFieldValue("niz_stavke[0].odabranaUsluga", undefined);
        setFieldValue("niz_stavke[0].naziv", '');
        setFieldValue("niz_stavke[0].opis", '');
        setFieldValue("niz_stavke[0].popust", '');
        setFieldValue("niz_stavke[0].cijena_bez_pdv", '');
        setFieldValue("niz_stavke[0].cijena_bez_pdv_popust", '');
        setFieldValue("niz_stavke[0].cijena_sa_pdv_popust", '');
        setFieldValue("niz_stavke[0].ukupna_cijena", '');
        setFieldValue("niz_stavke[0].kolicina", 1);
        setFieldValue("niz_stavke[0].jedinica_mjere.naziv", '');
        setFieldValue("niz_stavke[0].nova_cijena_bez_pdv", '');
        setFieldValue("niz_stavke[0].nova_ukupna_cijena", '');
        setFieldValue("niz_stavke[0].marza", '');
        setFieldValue("niz_stavke[0].nabavna_cijena", '');
        setFieldValue("niz_stavke[0].nova_jedinica_mjere_id", '');
        setFieldValue("niz_stavke[0].novi_porez_id", '');
        setFieldValue("niz_stavke[0].zavisni_troskovi", '');
        setFieldValue("niz_stavke[0].novi_pdv", '');
        setFieldValue("niz_stavke[0].iznos_povrata_bez_pdv", '');
        setFieldValue("niz_stavke[0].iznos_povrata_sa_pdv", '');

        setCheckbox(false);
    }

    function exists(arr, search) {
        return arr.some(row => row.porez_id === search);
    }

    function makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }

       return result;
    }

    const dodajStavku = (values) => {
        if (values.tip_racuna === 'knjizno') {
            if (! values?.niz_stavke[0]?.cijena_bez_pdv && ! values?.niz_stavke[0]?.iznos_povrata_bez_pdv) {
                toast.error('Morate unijeti ili cijenu ili iznos povrata!',
                    toastSettings
                );
            }
        }

        if (! values?.niz_stavke[0]?.naziv) {
            toast.error('Stavka mora imati naziv!',
                toastSettings
            );
        } else if (! values?.niz_stavke[0]?.cijena_bez_pdv && values.tip_racuna !== 'knjizno') {
            toast.error('Stavka mora imati cijenu!',
                toastSettings
            );
        } else if (! values) {
            toast.error('Stavka mora imati jedinicu mjere!',
                toastSettings
            );
        } else if (! values?.niz_stavke[0]?.porez_id) {
            toast.error('Stavka mora imati porez!',
                toastSettings
            );
        } else if (! values?.niz_stavke[0]?.kolicina) {
            toast.error('Stavka mora imati količinu!',
                toastSettings
            );
        } else if (getUkupnaCijenaBezPdv(values.niz_stavke[0]) <= 0 || getUkupnaCijenaSaPdv(values.niz_stavke[0]) <= 0) {
            toast.error('Cijena stavke ne može biti negativna!',
                toastSettings
            );
        } else {
            const odabranaUsluga = values.niz_stavke[0].odabranaUsluga;
            if (odabranaUsluga?.roba || odabranaUsluga?.ukupna_cijena) {
                odabranaUsluga.popust = parseFloat(values.niz_stavke[0].popust);

                if (odabranaUsluga.roba) {
                    if (values.niz_stavke[0].tip_popusta === 'iznos') {
                        odabranaUsluga.cijena_bez_pdv_popust = parseFloat(odabranaUsluga.roba?.cijene_roba[0].cijena_bez_pdv) - parseFloat(values.niz_stavke[0].popust);

                        odabranaUsluga.cijena_sa_pdv_popust = parseFloat(odabranaUsluga.roba?.cijene_roba[0].ukupna_cijena) - parseFloat(values.niz_stavke[0].popust);
                    }

                    if (values.niz_stavke[0].tip_popusta === 'procenat') {
                        odabranaUsluga.cijena_bez_pdv_popust = parseFloat(odabranaUsluga.roba?.cijene_roba[0].cijena_bez_pdv) - (parseFloat(odabranaUsluga.roba?.cijene_roba[0].cijena_bez_pdv) * (parseFloat(values.niz_stavke[0].popust) / 100));

                        odabranaUsluga.cijena_sa_pdv_popust = parseFloat(odabranaUsluga.roba?.cijene_roba[0].ukupna_cijena) - (parseFloat(odabranaUsluga.roba?.cijene_roba[0].ukupna_cijena) * (parseFloat(values.niz_stavke[0].popust) / 100));
                    }
                } else {
                    if (values.niz_stavke[0].tip_popusta === 'iznos_popusta_bez_pdv') {
                        odabranaUsluga.cijena_bez_pdv_popust = parseFloat(odabranaUsluga.cijena_bez_pdv) - parseFloat(values.niz_stavke[0].popust ? values.niz_stavke[0].popust : 0);

                        odabranaUsluga.cijena_sa_pdv_popust = parseFloat(odabranaUsluga.cijena_bez_pdv_popust) * parseFloat(odabranaUsluga.porez);
                    }

                    if (values.niz_stavke[0].tip_popusta === 'iznos_popusta_sa_pdv') {
                        odabranaUsluga.cijena_sa_pdv_popust = parseFloat(odabranaUsluga.ukupna_cijena) - parseFloat(values.niz_stavke[0].popust ? values.niz_stavke[0].popust : 0);

                        odabranaUsluga.cijena_bez_pdv_popust = parseFloat(odabranaUsluga.cijena_sa_pdv_popust) / parseFloat(odabranaUsluga.porez);
                    }

                    if (values.niz_stavke[0].tip_popusta === 'procenat') {
                        odabranaUsluga.cijena_bez_pdv_popust = parseFloat(odabranaUsluga.cijena_bez_pdv) - (parseFloat(odabranaUsluga?.cijena_bez_pdv) * (parseFloat(values.niz_stavke[0].popust ? values.niz_stavke[0].popust : 0) / 100));

                        odabranaUsluga.cijena_sa_pdv_popust = parseFloat(odabranaUsluga.ukupna_cijena) - (parseFloat(odabranaUsluga.ukupna_cijena) * (parseFloat(values.niz_stavke[0].popust ? values.niz_stavke[0].popust : 0) / 100));
                    }
                }

               odabranaUsluga.kolicina = values.niz_stavke[0]?.kolicina;
               odabranaUsluga.nova_cijena_bez_pdv = values.niz_stavke[0]?.nova_cijena_bez_pdv;
               odabranaUsluga.nova_ukupna_cijena = values.niz_stavke[0]?.nova_ukupna_cijena;
               odabranaUsluga.marza = values.niz_stavke[0]?.marza;
               odabranaUsluga.novi_porez_id = values.niz_stavke[0]?.novi_porez_id;
               odabranaUsluga.izmijeni_stavku = azurirajStavku;
               odabranaUsluga.zavisni_troskovi = values.niz_stavke[0]?.zavisni_troskovi;
               odabranaUsluga.nabavna_cijena = values.niz_stavke[0]?.nabavna_cijena;
               odabranaUsluga.id = makeId(20);
               odabranaUsluga.iznos_povrata_bez_pdv = values.niz_stavke[0]?.iznos_povrata_bez_pdv ? values.niz_stavke[0]?.iznos_povrata_bez_pdv : 0;
               odabranaUsluga.iznos_povrata_sa_pdv = values.niz_stavke[0]?.iznos_povrata_sa_pdv ? values.niz_stavke[0]?.iznos_povrata_sa_pdv : 0;
               odabranaUsluga.opis = values?.niz_stavke[0]?.opis;
               odabranaUsluga.jedinica_mjere_id = values?.niz_stavke[0]?.jedinica_mjere_id;
               odabranaUsluga.jedinica_mjere = values?.niz_stavke[0]?.jedinica_mjere;

               values.stavke.push(odabranaUsluga);
            } else {
                if (values?.niz_stavke[0]?.tip_popusta === 'iznos_popusta_bez_pdv' && values?.niz_stavke[0]?.popust) {
                    values.niz_stavke[0].cijena_bez_pdv_popust -= values.niz_stavke[0].popust;

                    values.niz_stavke[0].cijena_sa_pdv_popust = values.niz_stavke[0].cijena_bez_pdv_popust * (values.niz_stavke[0].porez + 1);
                }

                if (values?.niz_stavke[0]?.tip_popusta === 'iznos_popusta_sa_pdv' && values?.niz_stavke[0]?.popust) {
                    values.niz_stavke[0].cijena_sa_pdv_popust -= values.niz_stavke[0].popust;

                    values.niz_stavke[0].cijena_bez_pdv_popust = values.niz_stavke[0].cijena_sa_pdv_popust / (values.niz_stavke[0].porez + 1);
                }

                if (values?.niz_stavke[0]?.tip_popusta === 'procenat' && values?.niz_stavke[0]?.popust) {
                    const popust_bez_pdv_iznos = values.niz_stavke[0].cijena_bez_pdv_popust * (values.niz_stavke[0].popust / 100);

                    const popust_sa_pdv_iznos = values.niz_stavke[0].cijena_sa_pdv_popust * (values.niz_stavke[0].popust / 100);

                    values.niz_stavke[0].cijena_bez_pdv_popust -= parseFloat(popust_bez_pdv_iznos);
                    values.niz_stavke[0].cijena_sa_pdv_popust -= parseFloat(popust_sa_pdv_iznos);
                }

                values.stavke.push({
                    naziv: values.niz_stavke[0].naziv,
                    opis: values.niz_stavke[0].opis,
                    popust: values.niz_stavke[0].popust,
                    cijena_bez_pdv: values.niz_stavke[0].cijena_bez_pdv,
                    cijena_bez_pdv_popust: values.niz_stavke[0].cijena_bez_pdv_popust,
                    cijena_sa_pdv_popust: values.niz_stavke[0].cijena_sa_pdv_popust,
                    kolicina: values.niz_stavke[0].kolicina,
                    ukupna_cijena: values.niz_stavke[0].ukupna_cijena,
                    porez_id: values.niz_stavke[0].porez_id,
                    jedinica_mjere_id: values.niz_stavke[0].jedinica_mjere_id,
                    jedinica_mjere: values.niz_stavke[0].jedinica_mjere,
                    tip_popusta: values.niz_stavke[0].tip_popusta,
                    marza: values.niz_stavke[0].marza ? values.niz_stavke[0].marza : null,
                    nova_cijena_bez_pdv: values.niz_stavke[0].nova_cijena_bez_pdv ? values.niz_stavke[0].nova_cijena_bez_pdv : null,
                    nova_ukupna_cijena: values.niz_stavke[0].nova_ukupna_cijena ? values.niz_stavke[0].nova_ukupna_cijena : null,
                    novi_porez_id: values.niz_stavke[0].novi_porez_id ? values.niz_stavke[0].novi_porez_id : null,
                    izmijeni_stavku: azurirajStavku,
                    zavisni_troskovi: values.niz_stavke[0].zavisni_troskovi,
                    nabavna_cijena: values.niz_stavke[0].nabavna_cijena,
                    usluga_id: 1,
                    id: makeId(20),
                    porez: {naziv: `${parseInt(values.niz_stavke[0].porez * 100)}%`, id: values.niz_stavke[0].porez_id, stopa: values.niz_stavke[0].porez},
                    iznos_povrata_bez_pdv: values?.niz_stavke[0]?.iznos_povrata_bez_pdv ? values?.niz_stavke[0]?.iznos_povrata_bez_pdv : 0,
                    iznos_povrata_sa_pdv: values?.niz_stavke[0]?.iznos_povrata_sa_pdv ? values?.niz_stavke[0]?.iznos_povrata_sa_pdv : 0
                });
            }

            if (checkbox) {
                dispatch(storeUsluga({
                    naziv: values.niz_stavke[0].naziv,
                    opis: values.niz_stavke[0].opis,
                    cijena_bez_pdv: values.niz_stavke[0].cijena_bez_pdv,
                    ukupna_cijena: values.niz_stavke[0].ukupna_cijena,
                    jedinica_mjere_id: values.niz_stavke[0].jedinica_mjere_id,
                    porez_id: values.niz_stavke[0].porez_id,
                    status: true,
                    grupa_id: 1,
                }));
            }

           clearAllValues();
           setShow(false);
        }
    };

    const setIznosPovrata = (value, type, values) => {
        if (type === 'bez_pdv') {
            setFieldValue('niz_stavke[0].iznos_povrata_bez_pdv', +value);
            setFieldValue(`niz_stavke[0].iznos_povrata_sa_pdv`, +value * (1 + +values.porez))
        }

        if (type === 'sa_pdv') {
            setFieldValue('niz_stavke[0].iznos_povrata_sa_pdv', +value);
            setFieldValue(`niz_stavke[0].iznos_povrata_bez_pdv`, +value / (1 + +values.porez))
        }
    }

    function renderPopustPolja() {
        if (values.tip_racuna === 'avans') {
            return null;
        }

        if (values.tip_racuna === 'knjizno') {
            return (
                <>
                    <div className="form__group">
                        <label htmlFor="" className="form__label bm-show">
                            Bez PDV
                        </label>
                        <input
                            name={`niz_stavke[0].iznos_povrata_bez_pdv`}
                            type="number"
                            className="form__input"
                            step=".01"
                            placeholder="Bez PDV"
                            title="Iznos povrata bez PDV-a"
                            value={values?.niz_stavke[0]?.iznos_povrata_bez_pdv ?? ''}
                            onChange={(event) => {
                                setIznosPovrata(event.target.value, 'bez_pdv', values.niz_stavke[0]);
                            }}
                        />
                    </div>
                    <div className="form__group">
                        <label htmlFor="" className="form__label bm-show">
                            Sa pdv
                        </label>
                        <input
                            name={`niz_stavke[0].iznos_povrata_sa_pdv`}
                            type="number"
                            className="form__input"
                            step=".01"
                            placeholder="Sa PDV"
                            title="Iznos povrata sa PDV-om"
                            value={values?.niz_stavke[0]?.iznos_povrata_sa_pdv ?? ''}
                            onChange={(event) => {
                                setIznosPovrata(event.target.value, 'sa_pdv', values.niz_stavke[0]);
                            }}
                        />
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="form__group">
                    <label htmlFor="" className="form__label bm-show">
                        Tip popusta
                    </label>
                    <DropDownStatic
                        name={`niz_stavke[0].tip_popusta`}
                        defaultValue={
                            values.niz_stavke[0]?.tip_popusta ?
                                {
                                    label: 'Procenat %',
                                    value: 'procenat'
                                } :
                                'Procenat %'
                        }
                        onChangeExtra={(option) => {
                            if (! show) {
                                setFieldValue(`niz_stavke[0].odabranaUsluga.tip_popusta`, option.value)
                            }
                        }}
                        options={TIPOVI_POPUSTA}
                    />
                </div>
                <div className="form__group">
                    <label htmlFor="" className="form__label bm-show">
                        Popust
                    </label>
                    <input
                        name={`niz_stavke[0].popust`}
                        type="number"
                        className="form__input"
                        step=".01"
                        placeholder="Popust"
                        value={values?.niz_stavke[0]?.popust ?? ''}
                        onChange={(event) => {
                            setFieldValue(`niz_stavke[0].popust`, event.target.value);
                        }}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            <div className="main-content__box--body mb-20 mt-4">
                <div className="container">
                    <div className="section-box">
                        <div className="section-box__left">
                            <div className="section-box__left--top">
                                <div className="form-group mb-0">
                                    <StavkeDropdown
                                        key='1'
                                        id='1'
                                        name={`niz_stavke[0].odabranaUsluga`}
                                        className="form__input"
                                        onChangeExtra={(option, type) =>
                                            setSelectedType(option, type)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="section-box__left--bottom">
                                <div className="form-group mt-15">
                                    <Field
                                        name={`niz_stavke[0].opis`}
                                        id={`niz_stavke[0].opis`}
                                        cols="30"
                                        rows="8"
                                        value={values?.niz_stavke[0]?.opis ?? ''}
                                        className="form__textarea df"
                                        placeholder="Opis stavke"
                                        component="textarea"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section-box__right">
                            <div className="section-box__right--top-wrap">
                                <div className="el">
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            Bez PDV
                                        </label>
                                        <Field
                                            type="text"
                                            name={`niz_stavke[0].cijena_bez_pdv`}
                                            className="form__input"
                                            value={values?.niz_stavke[0]?.cijena_bez_pdv ?? ''}
                                            placeholder="Bez PDV"
                                            title="Jedinična cijena bez PDV-a"
                                            readOnly={! show}
                                            onChange={(event) => {
                                                setLastClickedInput('bez_pdv')
                                                setManualValues(values.niz_stavke[0], event, 'bez_pdv')
                                            }}
                                        />
                                    </div>
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            Sa PDV
                                        </label>
                                        {/* TODO: izgaseno edit Cijena dok se ne rijesi slanje izmijenjene cijene */}
                                        <input
                                            name={`niz_stavke[0].ukupna_cijena`}
                                            type="number"
                                            value={values.niz_stavke[0]?.ukupna_cijena ?? ''}
                                            readOnly={! show}
                                            className="form__input"
                                            placeholder="Sa PDV"
                                            title="Jedinična cijena sa PDV-om"
                                            onChange={(event) => {
                                                setLastClickedInput('sa_pdv')
                                                setManualValues(values.niz_stavke[0], event, 'sa_pdv')
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="el">
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            Jedinica mjere
                                        </label>
                                        <DropDown
                                            name={`niz_stavke[0].jedinica_mjere_id`}
                                            isDisabled={! show || values.tip_racuna === 'avans'}
                                            onChangeExtra={(option) => {
                                                setManualJedinicaMjere(option)
                                            }}
                                            defaultValue={
                                                values.niz_stavke[0]?.jedinica_mjere?.naziv ?
                                                {
                                                    value: values.niz_stavke[0]?.jedinica_mjere?.id,
                                                    label: values.niz_stavke[0]?.jedinica_mjere?.naziv,
                                                } :
                                                'Select...'
                                            }
                                            loadOptions={
                                                jediniceMjereService.getJediniceMjereDropdown
                                            }
                                        />
                                    </div>
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            Količina
                                        </label>
                                        <Field
                                            name={`niz_stavke[0].kolicina`}
                                            type="number"
                                            className="form__input"
                                            step=".001"
                                            defaultValue={1}
                                        />
                                    </div>
                                </div>
                                <div className="el">
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            Stopa PDV-a
                                        </label>
                                        <label htmlFor="stavke.0.jedinica_mjere_id" className="form__label"/>
                                        <DropDownStatic
                                            name={`niz_stavke[0].porez`}
                                            disabled={! show || values.tip_racuna === 'avans' || ! authPreduzece.pdv_obveznik}
                                            options={TIPOVI_POREZA}
                                            defaultValue={
                                                values.niz_stavke[0]?.porez ?
                                                {
                                                    value: values.niz_stavke[0]?.porez,
                                                    label: values.niz_stavke[0]?.porez_naziv,
                                                } :
                                                {
                                                    value: authPreduzece.pdv_obveznik ? 0.21 : 0.00,
                                                    label: authPreduzece.pdv_obveznik ? '21%' : 'Oslobodjen PDV'
                                                }
                                            }
                                            onChangeExtra={(option) => {
                                                setManualPdv(values.niz_stavke[0], option)
                                            }}
                                        />
                                    </div>
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            PDV
                                        </label>
                                        <input
                                            type="text"
                                            name={`niz_stavke[0].ukupan_pdv`}
                                            className="form__input"
                                            placeholder="PDV iznos"
                                            value={
                                                values.niz_stavke[0]?.cijena_bez_pdv ?
                                                values.niz_stavke[0]?.cijena_bez_pdv * values.niz_stavke[0]?.porez :
                                                ''
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="el">
                                    {renderPopustPolja()}
                                </div>
                            </div>
                            <div className="section-box__right--bottom-wrap">
                                <div className="price">
                                    <div className="form__box">
                                        <div>
                                            <p className="txt-light">Ukupan iznos PDV-a</p>
                                        </div>
                                        <div className="heading-secondary mb-0">
                                            {values?.niz_stavke[0]?.popust ? (
                                                <del style={{ marginRight: '10px' }}>
                                                    {formatirajCijenu(getUkupanPdvBezPopusta(values.niz_stavke[0]))}
                                                </del>
                                            ) : (
                                                ''
                                            )}
                                            { formatirajCijenu(getUkupanPdv(values.niz_stavke[0])) }
                                        </div>
                                    </div>
                                </div>
                                <div className="price">
                                    <div className="form__box">
                                        <div>
                                            <p className="txt-light">Ukupna cijena bez PDV-a</p>
                                        </div>
                                        <div className="heading-secondary mb-0" name="tekst">
                                            {values?.niz_stavke[0]?.popust ? (
                                                <del style={{ marginRight: '10px' }}>
                                                    {formatirajCijenu(getUkupnaCijenaBezPdvBezPopusta(values.niz_stavke[0]))}
                                                </del>
                                            ) : (
                                                ''
                                            )}
                                            { formatirajCijenu(getUkupnaCijenaBezPdv(values.niz_stavke[0]))}
                                        </div>
                                    </div>
                                </div>
                                <div className="price">
                                    <div className="form__box">
                                        <div>
                                            <p className="txt-light">Ukupna cijena sa PDV-om</p>
                                        </div>
                                        <div className="heading-secondary mb-0">
                                            {values?.niz_stavke[0]?.popust ? (
                                                <del style={{ marginRight: '10px' }}>
                                                    {formatirajCijenu(getUkupnaCijenaSaPdvBezPopusta(values.niz_stavke[0]))}
                                                </del>
                                            ) : (
                                                ''
                                            )}
                                            <del style={{marginRight: '10px'}}>
                                            </del>
                                            { formatirajCijenu(getUkupnaCijenaSaPdv(values.niz_stavke[0])) }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{display: show ? '' : 'none'}}>
                            <input
                                type="checkbox"
                                id="checkbox"
                                name="checkbox"
                                onChange={() => setCheckbox(! checkbox)}
                            />
                            <label htmlFor="checkbox" className="ml-2">Sačuvaj stavku u listi svih stavki</label>
                        </div>
                    </div>
                </div>
            </div>
            {pathName.includes('/ulazni-racuni') && (
                <>
                    <div className="main-content__box--header">
                        <div className="row">
                            <div className="col-xl-4 col-md-4 col-12">
                                <h4 className="heading-quaternary">
                                    Nabavna cijena / Ukupna cijena
                                </h4>
                            </div>
                            <div className="col-xl-2 col-md-2 tabp-none">
                                <h4 className="heading-quaternary">
                                    trošak / PDV
                                </h4>
                            </div>
                            <div className="col-xl-2 col-md-2 tabp-none">
                                <h4 className="heading-quaternary">
                                    Marža
                                </h4>
                            </div>
                            <div className="col-xl-2 col-md-2 tabp-none">
                                <h4 className="heading-quaternary">
                                    Cijena bez pdv
                                </h4>
                            </div>
                            <div className="col-xl-2 col-md-2 tabp-none">
                                <h4 className="heading-quaternary txt-right">
                                    PDV iznos
                                </h4>
                            </div>
                        </div>
                    </div>
                    <BezgotovinskiNoveCijeneStavkiForm azurirajStavku={azurirajStavku} setAzurirajStavku={setAzurirajStavku} authPreduzece={authPreduzece} odabranaStavka={odabranaStavka} />
                </>
            )}
            <hr className="hr-main" />
            <div title="Dodaj stavku na račun (Enter)" onClick={() => dodajStavku(values)} className="main-content__box--footer nova-stavka__hover">
                <span className="link">+ Dodaj stavku na račun</span>
            </div>
        </>
    );
};

export default BezgotovinskiStavkeForm;
