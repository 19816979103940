export const STORE_PREDRACUN = 'STORE_PREDRACUN';

export const GET_PREDRACUN = 'GET_PREDRACUN';
export const SET_PREDRACUN = 'SET_PREDRACUN';

export const UPDATE_PREDRACUN = 'UPDATE_PREDRACUN';
export const DELETE_PREDRACUN = 'DELETE_PREDRACUN';

export const STORE_PREDPREDRACUN = 'STORE_PREDPREDRACUN';

export const GET_PREDPREDRACUNI = 'GET_PREDPREDRACUNI';
export const SET_PREDPREDRACUNI = 'SET_PREDPREDRACUNI';

export const GET_PREDPREDRACUN = 'GET_PREDPREDRACUN';
export const SET_PREDPREDRACUN = 'SET_PREDPREDRACUN';

export const UPDATE_PREDPREDRACUN = 'UPDATE_PREDPREDRACUN';
export const DELETE_PREDPREDRACUN = 'DELETE_PREDPREDRACUN';
