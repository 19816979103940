import { call, put } from 'redux-saga/effects';
import { setGlobalError } from '../actions/ErrorActions';
import { novostiService } from '../../services/NovostService';
import {
    setNovosti,
    setNovost,
    setNeprocitaneNovosti,
    setPoslednjeNovosti
} from '../actions/NovostiActions';

import { emptyPaginated } from '../reducers/NovostiReducer';


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const toastSettings = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

export function* novostiGet({ payload }) {
    try {
        if (payload?.kategorija_id) {
            yield put(setNovosti(emptyPaginated));
          } else {
            const res = yield call(novostiService.getNovosti, payload);
            yield put(setNovosti(res.data));
        }
    } catch (error) {
        yield put(setGlobalError(error));
    }
}

export function* novostGet({ payload }) {
    try {
      const res = yield call(novostiService.getNovost, payload);
      yield put(setNovost(res.data));
    } catch (error) {
      yield put(setGlobalError(error.message));
    }
  }

export function* neprocitaneGet({ payload }) {
    try {
        const res = yield call(novostiService.getNeprocitane, payload);
        yield put(setNeprocitaneNovosti(res.data));
    } catch (error) {
        yield put(setGlobalError(error.message));
    }
}

export function* poslednjeGet({ payload }) {
    try {
        const res = yield call(novostiService.getPoslednje, payload);
        yield put(setPoslednjeNovosti(res.data));
    } catch (error) {
        yield put(setGlobalError(error.message));
    }
}



