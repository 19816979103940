import React from 'react';
import Moment from "react-moment";
import { useHistory } from 'react-router-dom';

const PoslednjeNovostiTableRow = ({ item }) => {
    const history = useHistory();

    const handleClick = () => {
        history.push(`/novosti/${item.slug}`);
    };

    return (
        <div onClick={handleClick} className="box-news">
            <h3 className="heading-tertiary fw-500">
                { item.naziv.substring(0, 75) }{ item.naziv.length > 75 ? '...' : '' }
            </h3>
            <div className="df jc-sb ai-c">
                <p className="txt-light">
                    <Moment locale="me" format="DD. MMM YYYY.">
                        { item.created_at }
                    </Moment>
                </p>
                <a className="df">
                    <span className="tag tag__neutral txt-dark">
                        { item.categories.naziv }
                    </span>
                </a>
            </div>
            <hr/>
            <p className="txt-light">
              { item.tekst.replace(/<[^>]+>/g, '').substring(0, 155) }{ item.tekst.length > 155 ? '...' : '' }
            </p>
        </div>
    );
};

export default PoslednjeNovostiTableRow;
