import React, { useState } from 'react';
import { Field, useFormikContext } from "formik";

import { useDispatch, useSelector } from 'react-redux';
import DropDownStatic from "../../shared/forms/DropDownStatic";
import { TIPOVI_POREZA } from "../../../constants/racuni";
import {
    formatirajCijenu,
    getUkupanPdv,
    getUkupnaCijenaSaPdv,
    getUkupnaCijenaBezPdv,
} from "../../../helpers/racuni";
import { toast } from "react-toastify";
import { authPreduzeceSelector } from "../../../store/selectors/PreduzecaSelector";
import ReactDatePicker from 'react-datepicker';
import ZavisniTroskoviDropdown from '../NoviRacun/ZavisniTroskoviDropdown';
import DropDown from '../../shared/forms/DropDown';
import { partneriService } from '../../../services/PartneriService';

toast.configure();

const toastSettings = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const BezgotovinskiZavisniTroskovi = () => {
    const { values, setFieldValue } = useFormikContext();
    const [ show, setShow ] = useState(false);
    const [ lastClickedInput, setLastClickedInput ] = useState('');
    const dispatch = useDispatch();
    const authPreduzece = useSelector(authPreduzeceSelector());

    const setSelectedType = (option, type) => {
        if (type === 'addManually') {
            setShow(true);
            clearAllValues();

            setFieldValue(`niz_zavisni_troskovi[0].naziv`, option);
            if (authPreduzece.pdv_obveznik) {
                setFieldValue(`niz_zavisni_troskovi[0].porez`, 0.21);
                setFieldValue(`niz_zavisni_troskovi[0].porez_id`, 4);
                setFieldValue(`niz_zavisni_troskovi[0].porez_naziv`, '21%');
            } else {
                setFieldValue(`niz_zavisni_troskovi[0].porez`, 0.00);
                setFieldValue(`niz_zavisni_troskovi[0].porez_id`, 1);
                setFieldValue(`niz_zavisni_troskovi[0].porez_naziv`, 'Oslobodjen PDV');
            }

            setFieldValue(`niz_zavisni_troskovi[0].odabranaUsluga`, null);
        } else {
            setShow(true);

            const roba = option.roba ? option.roba : null;
            setFieldValue(`niz_zavisni_troskovi[0].naziv`, roba ? roba.naziv : option.naziv);
            setFieldValue(`niz_zavisni_troskovi[0].opis`, roba ? roba.opis : option.opis);
            setFieldValue(`niz_zavisni_troskovi[0].cijena_bez_pdv`, roba ? roba.cijene_roba[0].cijena_bez_pdv : option.cijena_bez_pdv);

            setFieldValue(`niz_zavisni_troskovi[0].roba_id`, option.id);
            setFieldValue(`niz_zavisni_troskovi[0].ukupna_cijena`, roba ? +roba.cijene_roba[0].ukupna_cijena : +option.ukupna_cijena);

            setFieldValue(`niz_zavisni_troskovi[0].kolicina`, 1);
            setFieldValue(`niz_zavisni_troskovi[0].porez`, roba ? roba.cijene_roba[0].porez.stopa : option?.porez?.stopa);
            setFieldValue(`niz_zavisni_troskovi[0].porez_id`, 4);
            setFieldValue(`niz_zavisni_troskovi[0].porez`, 0.21);
            setFieldValue(`niz_zavisni_troskovi[0].porez_naziv`, '21%');

            setFieldValue(`niz_zavisni_troskovi[0].usluga_id`, 1);
        }
    };

    const setManualValues = (values, event, type) => {
        setFieldValue(`niz_zavisni_troskovi[0].usluga_id`, 1);
        setFieldValue(`niz_zavisni_troskovi[0].porez`, values.porez);

        if (! values?.kolicina) {
            setFieldValue(`niz_zavisni_troskovi[0].kolicina`, 1);
        }

        if (type === 'bez_pdv') {
            setFieldValue(`niz_zavisni_troskovi[0].cijena_bez_pdv`, +event.target.value);
            setFieldValue(`niz_zavisni_troskovi[0].ukupna_cijena`, +event.target.value * (1 + +values.porez));
            setFieldValue(`niz_zavisni_troskovi[0].pdv_iznos`, +event.target.value * +values.porez);
        }

        if (type === 'sa_pdv') {
            setFieldValue(`niz_zavisni_troskovi[0].ukupna_cijena`, +event.target.value);
            setFieldValue(`niz_zavisni_troskovi[0].cijena_bez_pdv`, +event.target.value / (1 + +values.porez));
            setFieldValue(`niz_zavisni_troskovi[0].pdv_iznos`, +event.target.value / (1 + +values.porez) * +values.porez);
        }
    };

    const setManualPdv = (values, option) => {
        setFieldValue(`niz_zavisni_troskovi[0].porez`, option.value);
        setFieldValue(`niz_zavisni_troskovi[0].porez_id`, option.id);

        if (lastClickedInput === 'bez_pdv') {
            if (values?.cijena_bez_pdv) {
                setFieldValue(`niz_zavisni_troskovi[0].ukupna_cijena`, values.cijena_bez_pdv * (1 + +option.value));
            }
        }

        if (lastClickedInput === 'sa_pdv') {
            if (values?.ukupna_cijena) {
                setFieldValue(`niz_zavisni_troskovi[0].cijena_bez_pdv`, values.ukupna_cijena / (1 + +option.value));
            }
        }
    };

    const clearAllValues = () => {
        setFieldValue("niz_zavisni_troskovi[0].odabranaUsluga", undefined);
        setFieldValue("niz_zavisni_troskovi[0].naziv", '');
        setFieldValue("niz_zavisni_troskovi[0].opis", '');
        setFieldValue("niz_zavisni_troskovi[0].cijena_bez_pdv", '');
        setFieldValue("niz_zavisni_troskovi[0].ukupna_cijena", '');
        setFieldValue("niz_zavisni_troskovi[0].kolicina", 1);
        setFieldValue("niz_zavisni_troskovi[0].porez", '');
        setFieldValue("niz_zavisni_troskovi[0].broj_dokumenta", '');
        setFieldValue("niz_zavisni_troskovi[0].datum_izdavanja", '');
        setFieldValue("niz_zavisni_troskovi[0].datum_za_placanje", '');
    }

    const dodajZavisniTrosak = (values) => {
        if (! values?.niz_zavisni_troskovi[0]?.naziv) {
            toast.error('Stavka mora imati naziv!',
                toastSettings
            );
        } else if (! values?.niz_zavisni_troskovi[0]?.cijena_bez_pdv) {
            toast.error('Stavka mora imati cijenu!',
                toastSettings
            );
        } else if (! values) {
            toast.error('Stavka mora imati jedinicu mjere!',
                toastSettings
            );
        } else if (getUkupnaCijenaBezPdv(values.niz_zavisni_troskovi[0]) <= 0 || getUkupnaCijenaSaPdv(values.niz_zavisni_troskovi[0]) <= 0) {
            toast.error('Cijena stavke ne može biti negativna!',
                toastSettings
            );
        } else {
            const odabranaUsluga = values.niz_zavisni_troskovi[0].odabranaUsluga;
            if (odabranaUsluga?.roba || odabranaUsluga?.ukupna_cijena) {

               odabranaUsluga.kolicina = values.niz_zavisni_troskovi[0]?.kolicina;
               odabranaUsluga.pdv_iznos = values.niz_zavisni_troskovi[0]?.ukupna_cijena * values.niz_zavisni_troskovi[0]?.porez;

               odabranaUsluga.broj_dokumenta = values.niz_zavisni_troskovi[0]?.broj_dokumenta;
               odabranaUsluga.datum_izdavanja = values.niz_zavisni_troskovi[0]?.datum_izdavanja;
               odabranaUsluga.datum_za_placanje = values.niz_zavisni_troskovi[0]?.datum_za_placanje;
               odabranaUsluga.partner_id = values.niz_zavisni_troskovi[0]?.partner_id;
               odabranaUsluga.porez_id = values.niz_zavisni_troskovi[0]?.porez_id;
               odabranaUsluga.oslobodjena_stopa = values.niz_zavisni_troskovi[0]?.oslobodjena_stopa;
               odabranaUsluga.opsta_stopa = values.niz_zavisni_troskovi[0]?.opsta_stopa;
               odabranaUsluga.snizena_stopa = values.niz_zavisni_troskovi[0]?.snizena_stopa;
               odabranaUsluga.stopa_0 = values.niz_zavisni_troskovi[0]?.stopa_0;

               values.zavisni_troskovi.push(odabranaUsluga);
            } else {
                values.zavisni_troskovi.push({
                    naziv: values.niz_zavisni_troskovi[0].naziv,
                    opis: values.niz_zavisni_troskovi[0].opis,
                    cijena_bez_pdv: values.niz_zavisni_troskovi[0].cijena_bez_pdv,
                    pdv_iznos: values.niz_zavisni_troskovi[0].pdv_iznos,
                    ukupna_cijena: values.niz_zavisni_troskovi[0].ukupna_cijena,
                    broj_dokumenta: values.niz_zavisni_troskovi[0].broj_dokumenta,
                    datum_izdavanja: values.niz_zavisni_troskovi[0].datum_izdavanja,
                    datum_za_placanje: values.niz_zavisni_troskovi[0].datum_za_placanje,
                    partner_id: values.niz_zavisni_troskovi[0].partner_id,
                    porez_id: values.niz_zavisni_troskovi[0].porez_id,
                    opsta_stopa: values.niz_zavisni_troskovi[0].opsta_stopa,
                    snizena_stopa: values.niz_zavisni_troskovi[0].snizena_stopa,
                    oslobodjena_stopa: values.niz_zavisni_troskovi[0].oslobodjena_stopa,
                    stopa_0: values.niz_zavisni_troskovi[0].stopa_0,
                });
            }

           clearAllValues();
           setShow(false);
        }
    };

    function setOpstaStopa(value) {
        setFieldValue(`niz_zavisni_troskovi[0].opsta_stopa`, +value);

        if (isNaN(values?.niz_zavisni_troskovi[0]?.cijena_bez_pdv)) {
            setFieldValue(`niz_zavisni_troskovi[0].cijena_bez_pdv`, +value);
        } else {
            setFieldValue(`niz_zavisni_troskovi[0].cijena_bez_pdv`, +values.niz_zavisni_troskovi[0].cijena_bez_pdv + +value);
        }

        setPdv(+value, 0.21);
    }

    function setSnizenaStopa(value) {
        setFieldValue(`niz_zavisni_troskovi[0].snizena_stopa`, +value);

        if (isNaN(values?.niz_zavisni_troskovi[0]?.cijena_bez_pdv)) {
            setFieldValue(`niz_zavisni_troskovi[0].cijena_bez_pdv`, 0 + +value);
        } else {
            setFieldValue(`niz_zavisni_troskovi[0].cijena_bez_pdv`, +values.niz_zavisni_troskovi[0].cijena_bez_pdv + +value);
        }

        setPdv(+value, 0.07);
    }

    function setStopa0(value) {
        setFieldValue(`niz_zavisni_troskovi[0].stopa_0`, +value);

        if (isNaN(values?.niz_zavisni_troskovi[0]?.cijena_bez_pdv)) {
            setFieldValue(`niz_zavisni_troskovi[0].cijena_bez_pdv`, 0 + +value);
        } else {
            setFieldValue(`niz_zavisni_troskovi[0].cijena_bez_pdv`, +values.niz_zavisni_troskovi[0].cijena_bez_pdv + +value);
        }

        setPdv(+value, 0.00);
    }

    function setOslobodjenaStopa(value) {
        setFieldValue(`niz_zavisni_troskovi[0].oslobodjena_stopa`, +value);

        if (isNaN(values?.niz_zavisni_troskovi[0]?.cijena_bez_pdv)) {
            setFieldValue(`niz_zavisni_troskovi[0].cijena_bez_pdv`, 0 + +value);
        } else {
            setFieldValue(`niz_zavisni_troskovi[0].cijena_bez_pdv`, +values.niz_zavisni_troskovi[0].cijena_bez_pdv + +value);
        }

        setPdv(+value, 0.00);
    }

    function setPdv(value, pdv) {
        if (! values?.niz_zavisni_troskovi[0]?.ukupna_cijena) {
            setFieldValue(`niz_zavisni_troskovi[0].ukupna_cijena`, 0 + (value * (pdv + 1)));
        } else {
            setFieldValue(`niz_zavisni_troskovi[0].ukupna_cijena`, values.niz_zavisni_troskovi[0].ukupna_cijena + (value * (pdv + 1)));

        }

        setFieldValue(`niz_zavisni_troskovi[0].pdv_iznos`, values.niz_zavisni_troskovi[0].ukupna_cijena - values.niz_zavisni_troskovi[0].cijena_bez_pdv);
    }

    return (
        <>
            <div className="main-content__box--body mb-20 mt-4">
                <div className="container">
                    <div className="section-box">
                        <div className="section-box__left">
                            <div className="el">
                                <div className="form__group mb-4">
                                    <label htmlFor="" className="form__label bm-show">
                                        Broj dokumenta
                                    </label>
                                    <Field
                                        name={`niz_zavisni_troskovi[0].broj_dokumenta`}
                                        type="text"
                                        value={values.niz_zavisni_troskovi[0]?.broj_dokumenta ?? ''}
                                        className="form__input"
                                        placeholder="Broj dokumenta"
                                    />
                                </div>
                            </div>
                            <div className="section-box__left--top">
                                <div className="form-group mb-0">
                                    <ZavisniTroskoviDropdown
                                        key='1'
                                        id='1'
                                        name={`niz_zavisni_troskovi[0].odabranaUsluga`}
                                        className="form__input"
                                        onChangeExtra={(option, type) =>
                                            setSelectedType(option, type)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="section-box__left--bottom">
                                <div className="form-group mt-15">
                                    <Field
                                        name={`niz_zavisni_troskovi[0].opis`}
                                        id={`niz_zavisni_troskovi[0].opis`}
                                        cols="30"
                                        rows="8"
                                        value={values?.niz_zavisni_troskovi[0]?.opis ?? ''}
                                        className="form__textarea df"
                                        placeholder="Opis stavke"
                                        component="textarea"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section-box__right">
                            <div className="section-box__right--top-wrap">
                                <div className="el">
                                    <div className="form__group">
                                        <label className="form__label bm-show">
                                            Partner
                                        </label>
                                        <DropDown
                                            className="mb-12 mob-mb-20"
                                            name="niz_zavisni_troskovi[0].partner_id"
                                            loadOptions={partneriService.getPartneriDropdown}
                                            isSearchable
                                            placeholder="Partner"
                                        />
                                    </div>
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            Iznos po opštoj stopi
                                        </label>
                                        <Field
                                            name={`niz_zavisni_troskovi[0].opsta_stopa`}
                                            type="text"
                                            value={values.niz_zavisni_troskovi[0]?.opsta_stopa ?? ''}
                                            className="form__input"
                                            placeholder="Iznos po opštoj stopi"
                                            onBlur={((event) => setOpstaStopa(event.target.value))}
                                        />
                                    </div>


                                </div>
                                <div className="el">
                                    <div className="form__group">
                                        <label className="form__label bm-show">
                                            Datum za placanje
                                        </label>
                                        <ReactDatePicker
                                            selected={values.niz_zavisni_troskovi[0]?.datum_za_placanje}
                                            className="form__input w-100"
                                            placeholderText="Datum za placanje"
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(date) =>
                                                setFieldValue('niz_zavisni_troskovi[0].datum_za_placanje', date)
                                            }
                                        />
                                    </div>
                                    <div className="form__group">
                                        <label className="form__label bm-show">
                                            Iznos po sniženoj stopi
                                        </label>
                                        <Field
                                            name={`niz_zavisni_troskovi[0].snizena_stopa`}
                                            type="text"
                                            value={values.niz_zavisni_troskovi[0]?.snizena_stopa ?? ''}
                                            className="form__input"
                                            placeholder="Iznos po sniženoj stopi"
                                            onBlur={(event) => setSnizenaStopa(event.target.value)}
                                        />
                                    </div>

                                </div>
                                <div className="el">
                                    <div className="form__group">
                                        <label className="form__label bm-show">
                                            Datum izdavanja
                                        </label>
                                        <ReactDatePicker
                                            selected={values.niz_zavisni_troskovi[0]?.datum_izdavanja}
                                            className="form__input w-100"
                                            placeholderText="Datum izdavanja"
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(date) =>
                                                setFieldValue('niz_zavisni_troskovi[0].datum_izdavanja', date)
                                            }
                                        />
                                    </div>
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            Iznos po stopi 0
                                        </label>
                                        <Field
                                            name={`niz_zavisni_troskovi[0].stopa_0`}
                                            type="text"
                                            value={values.niz_zavisni_troskovi[0]?.stopa_0 ?? ''}
                                            className="form__input"
                                            placeholder="Iznos po stopi 0"
                                            onBlur={(event) => setStopa0(event.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="el">
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            Bez PDV
                                        </label>
                                        <input
                                            name={`niz_zavisni_troskovi[0].cijena_bez_pdv`}
                                            type="number"
                                            value={values.niz_zavisni_troskovi[0]?.cijena_bez_pdv ?? ''}
                                            readOnly={! show}
                                            className="form__input"
                                            placeholder="Bez PDV"
                                            onChange={(event) => {
                                                setLastClickedInput('bez_pdv')
                                                setManualValues(values.niz_zavisni_troskovi[0], event, 'bez_pdv')
                                            }}
                                        />
                                    </div>
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            Iznos oslobođen
                                        </label>
                                        <Field
                                            name={`niz_zavisni_troskovi[0].oslobodjena_stopa`}
                                            type="text"
                                            value={values.niz_zavisni_troskovi[0]?.oslobodjena_stopa ?? ''}
                                            className="form__input"
                                            placeholder="Iznos oslobođen"
                                            onBlur={(event) => setOslobodjenaStopa(event.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="section-box__right--bottom-wrap">
                                <div className="price">
                                    <div className="form__box">
                                        <div>
                                            <p className="txt-light">Ukupan iznos PDV-a</p>
                                        </div>
                                        <div className="heading-secondary mb-0">
                                            { formatirajCijenu(getUkupanPdv(values.niz_zavisni_troskovi[0])) }
                                        </div>
                                    </div>
                                </div>
                                <div className="price">
                                    <div className="form__box">
                                        <div>
                                            <p className="txt-light">Ukupna cijena bez PDV-a</p>
                                        </div>
                                        <div className="heading-secondary mb-0" name="tekst">
                                            { formatirajCijenu(getUkupnaCijenaBezPdv(values.niz_zavisni_troskovi[0]))}
                                        </div>
                                    </div>
                                </div>
                                <div className="price">
                                    <div className="form__box">
                                        <div>
                                            <p className="txt-light">Ukupna cijena sa PDV-om</p>
                                        </div>
                                        <div className="heading-secondary mb-0">
                                            <del style={{marginRight: '10px'}}>
                                            </del>
                                            { formatirajCijenu(getUkupnaCijenaSaPdv(values.niz_zavisni_troskovi[0])) }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="hr-main" />
            <div onClick={() => dodajZavisniTrosak(values)} className="main-content__box--footer nova-stavka__hover">
                <span className="link">+ Dodaj zavisni trošak</span>
            </div>
        </>
    );
};

export default BezgotovinskiZavisniTroskovi;
