import {GET_IZVJESTAJ, SET_IZVJESTAJ} from "../actionTypes/IzvjestajiActionTypes";

export const getIzvjestaj = (payload) => ({
    type: GET_IZVJESTAJ,
    payload,
});

export const setIzvjestaj = (payload) => ({
    type: SET_IZVJESTAJ,
    payload,
});