import ApiService from './ApiService';

const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
};

const ENDPOINTS = {
    IZVJESTAJ: 'izvjestaji/{slug}',
    DNEVNI_IZVJESTAJ: 'izvjestaji/{slug}?datum={datum}',
    PERIODICNI_IZVJESTAJ: 'izvjestaji/{slug}?datum_od={datum_od}&datum_do={datum_do}',
};

class IzvjestajiService extends ApiService {

    getIzvjestaj = (data) =>{
        if(data.slug === 'fiskalni-presjek-stanja') {
            return this.apiClient.get(ENDPOINTS.IZVJESTAJ.replace('{slug}', data.slug));
        } else if (data.slug === 'fiskalni-dnevni-izvjestaj') {
            return this.apiClient.get(ENDPOINTS.DNEVNI_IZVJESTAJ.replace('{slug}', data.slug).replace('{datum}', data.datum));
        } else {
            return this.apiClient.get(ENDPOINTS.PERIODICNI_IZVJESTAJ.replace('{slug}', data.slug).replace('{datum_od}', data.datum_od).replace('{datum_do}', data.datum_do));
        }
    }
}

export const izvjestajiService = new IzvjestajiService();