import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  odabraniAtributGrupaSelector,
  stavkeRobeSelector,
  stavkeUslugeSelector,
} from '../../store/selectors/RacuniSelector';
import { getStavke } from '../../store/actions/RacuniActions';
import StavkeTable from './StavkeTable';

import { ReactComponent as ButtonPlusSvg } from '../../assets/icon/button-plus.svg';
import { Link, useLocation } from 'react-router-dom';
import { STAVKE } from '../../constants/routes';
import { debounce } from 'lodash';

import GridLoader from 'react-spinners/GridLoader';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { spinnerStyleGrid } from '../../constants/spinner';
import { getGrupe } from "../../store/actions/GrupeActions";
import { grupeSelector } from "../../store/selectors/GrupeSelector";
import Select from "react-select";

let filteri = {};
const searchDebounced = debounce((callback) => callback(), 200);

const Stavke = () => {
  const dispatch = useDispatch();
  const query = useLocation().search;
  const page = new URLSearchParams(query).get('page');

  const [filter, setFilter] = useState(null);

  const robe = useSelector(stavkeRobeSelector());
  const usluge = useSelector(stavkeUslugeSelector());
  const odabraniAtributGrupa = useSelector(odabraniAtributGrupaSelector());

  let uslugaGrupe = useSelector(grupeSelector());

  const [search, setSearch] = useState('');

  const options = [
    { value: 'sve', label: 'Sve' },
    { value: 'usluge', label: 'Usluge' },
  ]

  useEffect(() => {
    dispatch(getStavke({ page }));
    dispatch(getGrupe());
    filteri = {};
  }, [dispatch]);

  const resetFilters = () => {
    filteri.grupa_id = [];
    handleSearch(filteri);
  };

  // const handleAtributChange = (event) => {
  //   filteri.atribut_id = event;
  //   handleSearch(filteri);
  // };

  const handleGrupaChange = (event) => {
    if (filteri.grupa_id === undefined) {
      filteri.grupa_id = [];
    }
    if (filteri.grupa_id.includes(event)) {
      filteri.grupa_id.splice(filteri.grupa_id.indexOf(event), 1);
    } else {
      filteri.grupa_id.push(event);
    }
    handleSearch(filteri);
  };

  const handleSearch = (value) => {
    dispatch(getStavke(value));
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
    if (event.target.value) filteri.search = event.target.value;
    else filteri.search = undefined;
    searchDebounced(() => handleSearch(filteri));
  };

  return (
    <>
      <div className="title jc-sb">
        <h1 className="heading-primary">Stavke</h1>
        <div className="df w-50 jc-end">
          <Link exact to={STAVKE.CREATE_USLUGE}>
            <button className="btn btn__primary">
              <ButtonPlusSvg />
              Nova stavka
            </button>
          </Link>
        </div>
      </div>
      <div className="main-content__box">
        <div className="content" style={{ width: '100%' }}>
          <div className="main-content__search-wrapper df mob-fd-column">
            <div className="df jc-sb w-100 mob-fd-column">
              <form
                onSubmit={(e) => e.preventDefault()}
                className="search df ai-c mob-w-100"
              >
                <button className="search__button" type="button"></button>
                <input
                  name="search"
                  placeholder="Pretraži Usluge i Robe"
                  className="search__input mr-4"
                  value={search}
                  onChange={handleChange}
                />
              </form>
              <Select
                  options={options}
                  defaultValue={{label: 'Sve', value: 'sve'}}
                  onChange={(option) => setFilter(option)}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: '#F3F4F6',
                      borderRadius: 4,
                      height: '45px',
                      minHeight: 'unset',
                      width: '185px'
                    }),
                  }}
              />
            </div>

            {/* <NoviRacunFilteri /> */}
            <div className="filter">
              <div
                onClick={() => resetFilters()}
                className={
                  'filter__tab' + (! filteri?.grupa_id?.length ? ' active' : '')
                }
              >
                Sve
              </div>
              {robe?.data?.length === 0 &&
              usluge?.data?.length === 0 &&
              ! robe.path &&
              ! usluge.path ? (
                <PropagateLoader css={spinnerStyleGrid} size={15} />
              ) : (
                <>
                  {/*{robeAtributi.map((robeKat) => (*/}
                  {/*  <div*/}
                  {/*    onClick={() =>*/}
                  {/*      handleAtributChange(robeKat?.atribut_robe?.id)*/}
                  {/*    }*/}
                  {/*    className={*/}
                  {/*      'filter__tab' + (!odabraniAtributGrupa ? ' active' : '')*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    {robeKat?.atribut_robe?.naziv}*/}
                  {/*  </div>*/}
                  {/*))}*/}
                  {uslugaGrupe.map((uslugaGrupa) => (
                    <div
                      onClick={() => handleGrupaChange(uslugaGrupa?.id)}
                      className={
                        'filter__tab' + (filteri?.grupa_id?.includes(uslugaGrupa?.id) ? ' active' : '')
                      }
                    >
                      {uslugaGrupa?.naziv}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div>
            {robe?.data?.length === 0 &&
            usluge?.data?.length === 0 &&
            ! robe.path &&
            ! usluge.path ? (
              <GridLoader css={spinnerStyleGrid} size={15} />
            ) : robe?.data?.length === 0 && usluge?.data?.length === 0 ? (
              <div className="msg-center">
                <p> {'Nema stavki u listi'}</p>
              </div>
            ) : (
              <StavkeTable robe={robe} usluge={usluge} filter={filter} page={page} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Stavke;
