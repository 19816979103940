import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ERRORS, IZVJESTAJI } from '../constants/routes';
import Izvjestaji from '../components/Izvjestaji/Izvjestaji';
import Izvjestaj from '../components/Izvjestaji/Izvjestaj';

const IzvjestajiRouter = () => {
  return (
    <Switch>
        <Route exact path={IZVJESTAJI.INDEX}>
            <Izvjestaji />
        </Route>
        <Route exact path={IZVJESTAJI.SHOW}>
            <Izvjestaj />
        </Route>
        <Redirect to={ERRORS.NOT_FOUND} />
    </Switch>
  );
};

export default IzvjestajiRouter;