import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'react-qr-code';
import Moment from 'react-moment';
import 'moment/locale/me';
import noLogo from '../../../assets/img/no-logo.png';
import List from '../../shared/lists/List';
import { ReactComponent as LinkSvg } from '../../../assets/icon/link.svg';
import { BASE_URL } from '../../../config';

import { RACUNI, PREDRACUNI, ULAZNI_RACUNI } from '../../../constants/routes';
import BezgotovinskiShowTemplate from './BezgotovinskiShowTemplate';
import BezgotovinskiTableRow from './BezgotovinskiTableRow';
import BezgotovinskiStatusPodsjetnici from './BezgotovinskiStatusPodsjetnici';
import Select from 'react-select';
import { Form, Formik } from 'formik';
import {
  getRacun,
  getRacuni,
  updateRacun,
} from '../../../store/actions/RacuniActions';
import { racunSelector } from '../../../store/selectors/RacuniSelector';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import BezgotovinskiPoreziPreview from './BezgotovinskiPoreziPreview';
import { formatirajCijenu } from '../../../helpers/racuni';
import { userSelector } from '../../../store/selectors/UserSelector';
import { racuniService } from '../../../services/RacuniService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as ExternalLinkIcon } from "../../../assets/icon/external-link.svg";
import { renderStatus } from '../../../helpers/bezgotovinskiForm';
import { getUlazniRacun } from '../../../store/actions/UlazniRacuniActions';
import { ulazniRacunSelector } from '../../../store/selectors/UlazniRacuniSelector';
import BezgotovinskiFiles from './BezgotovinskiFiles';

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const BezgotovinskiPreview = ({ tip }) => {
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const testRef = useRef();
  const [editMode, setEditMode] = useState(false);
  const [editOpis, setEditOpis] = useState(false);
  const opisRef = useRef();

  const racun = useSelector(tip === 'ulazniRacun' ? ulazniRacunSelector() : racunSelector());
  const stavke = tip === 'ulazniRacun' ? racun.ulazne_stavke : racun.stavke;

  const getDodatniNacinPlacanja = () => {
    if (! racun.ikof_reference || ! racun.dodatni_nacin_placanja || racun.status === 'korektivni') {
      return null;
    }

    const referenceSuma = Object.values(racun.ikof_reference).reduce((r, { ukupna_cijena_sa_pdv_popust }) => r + parseFloat(ukupna_cijena_sa_pdv_popust) * -1.00, 0);
    const dodatniNacinPlacanjaSuma = racun.ukupna_cijena_sa_pdv_popust - referenceSuma;

    return (
        <div className="row">
          <div className="col-md-5">
            {`${racun.nacin_placanja}: ${formatirajCijenu(referenceSuma)} ${racun.dodatni_nacin_placanja}: ${formatirajCijenu(dodatniNacinPlacanjaSuma)}`}
          </div>
        </div>
    );
  }

  const user = useSelector(userSelector());

  const history = useHistory();

  let a = '';
  const ziroRacuni = () => {
    return racun?.preduzece?.ziro_racuni?.map((racun) => {
      a = racun.broj_racuna;
      if (a) {
        const prvaTri = a.substring(0, 3);

        if (prvaTri.includes('550')) {
          return <p>{'Podgorička: ' + a}</p>;
          // return <p>{a}</p>;
        } else if (prvaTri.includes('535')) {
          return <p>{'Prva Banka CG: ' + a}</p>;
          // return <p>{a}</p>;
        } else if (prvaTri.includes('555')) {
          return <p>{'Addiko: ' + a}</p>;
          // return <p>{a}</p>;
        } else if (prvaTri.includes('510')) {
          return <p>{'CKB: ' + a}</p>;
          // return <p>{a}</p>;
        } else if (prvaTri.includes('530')) {
          return <p>{'NLB: ' + a}</p>;
          // return <p>{a}</p>;
        } else if (prvaTri.includes('540')) {
          return <p>{'ERSTE: ' + a}</p>;
          // return <p>{a}</p>;
        } else if (prvaTri.includes('520')) {
          return <p>{'Hipotekarna: ' + a}</p>;
          // return <p>{a}</p>;
        } else if (prvaTri.includes('525')) {
          return <p>{'Komercijalna: ' + a}</p>;
          // return <p>{a}</p>;
        } else if (prvaTri.includes('560')) {
          return <p>{'Universal Capital: ' + a}</p>;
          // return <p>{a}</p>;
        } else if (prvaTri.includes('570')) {
          return <p>{'Zapad Banka: ' + a}</p>;
          // return <p>{a}</p>;
        } else if (prvaTri.includes('575')) {
          return <p>{'Ziraat Bank: ' + a}</p>;
          // return <p>{a}</p>;
        } else if (prvaTri.includes('565')) {
          return <p>{'Lovćen Banka: ' + a}</p>;
          // return <p>{a}</p>;
        } else if (prvaTri.includes('580')) {
          return <p>{'Adriatic Bank: ' + a}</p>;
          // return <p>{a}</p>;
        }
        return <p>{a}</p>;
      }
    });
  };

  let b = '';
  const ziroRacuniPartner = () => {
    let partnerTip = racun.partner?.fizicko_lice_id
      ? racun.partner?.fizicko_lice
      : racun.partner?.preduzece_partner;
    return partnerTip?.ziro_racuni?.map((racun) => {
      b = racun.broj_racuna;
      if (b) {
        const prvaTri = b.substring(0, 3);

        if (prvaTri.includes('550')) {
          return <p>{'Podgorička: ' + b}</p>;
          // return <p>{b}</p>;
        } else if (prvaTri.includes('535')) {
          return <p>{'Prva Banka CG: ' + b}</p>;
          // return <p>{b}</p>;
        } else if (prvaTri.includes('555')) {
          return <p>{'Addiko: ' + b}</p>;
          // return <p>{b}</p>;
        } else if (prvaTri.includes('510')) {
          return <p>{'CKB: ' + b}</p>;
          // return <p>{b}</p>;
        } else if (prvaTri.includes('530')) {
          return <p>{'NLB: ' + b}</p>;
          // return <p>{b}</p>;
        } else if (prvaTri.includes('540')) {
          return <p>{'ERSTE: ' + b}</p>;
          // return <p>{b}</p>;
        } else if (prvaTri.includes('520')) {
          return <p>{'Hipotekarna: ' + b}</p>;
          // return <p>{b}</p>;
        } else if (prvaTri.includes('525')) {
          return <p>{'Komercijalna: ' + b}</p>;
          // return <p>{b}</p>;
        } else if (prvaTri.includes('560')) {
          return <p>{'Universal Capital: ' + b}</p>;
          // return <p>{b}</p>;
        } else if (prvaTri.includes('570')) {
          return <p>{'Zapad Banka: ' + b}</p>;
          // return <p>{b}</p>;
        } else if (prvaTri.includes('575')) {
          return <p>{'Ziraat Bank: ' + b}</p>;
          // return <p>{b}</p>;
        } else if (prvaTri.includes('565')) {
          return <p>{'Lovćen Banka: ' + b}</p>;
          // return <p>{b}</p>;
        } else if (prvaTri.includes('580')) {
          return <p>{'Adriatic Bank: ' + b}</p>;
          // return <p>{b}</p>;
        }
        return <p>{b}</p>;
      }
    });
  };

  useEffect(() => {
    if (tip === 'ulazniRacun' && params.id) {
      dispatch(getUlazniRacun(params.id));
      return;
    }

    if (params.id) dispatch(getRacun(params.id));
  }, [params.id, dispatch]);

  const findWord = (word, str) => {
    return word.includes(str);
  };

  useEffect(() => {
    if (findWord(location.pathname, 'edit')) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [location]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleFiskalizuj = (e, id) => {
    e.stopPropagation();
    racuniService
        .fiskalizujRacun(id)
        .then((data) => {
          window.location.reload();
          toast.success(
              `Fiskalizacija računa je uspjela!`,
              toastSettings
          );
        })
        .catch((err) => {
          window.location.reload();
          let message = err?.response?.data?.error
              ? err.response.data.error
              : err.message;
          toast.error(
              'Fiskalizacija računa nije moguća: ' + message,
              toastSettings
          );
        });
  };

  const handleSubmit = (values) => {
    if (editMode && params.id) dispatch(updateRacun({ id: params.id, ...values }));
    history.push(`/racuni`);
  };

  let ukupnoBezPdvIpopusta = 0;
  let ukupnoBezPdv = 0;
  let ukupnoSaPdvIpopusta = 0;
  let ukupniPopust = 0;
  let ukupniPdv = 0;
  for (const i in stavke) {
    if (Object.hasOwnProperty.call(stavke, i)) {
      const stavka = stavke[i];
      ukupnoBezPdvIpopusta +=
        Math.round(
          Number(stavka.jedinicna_cijena_bez_pdv) *
            Number(stavka.kolicina) *
            100
        ) / 100;
      ukupnoBezPdv +=
        Math.round(
          Number(stavka.cijena_sa_pdv) * Number(stavka.kolicina) * 100
        ) / 100;
      ukupnoSaPdvIpopusta +=
        Math.round(
          Number(stavka.cijena_sa_pdv_popust) * Number(stavka.kolicina) * 100
        ) / 100;
      ukupniPdv += Math.round(Number(stavka.pdv_iznos) * 100) / 100;
      ukupniPopust += Math.round(Number(stavka.popust_iznos) * 100) / 100;
    }
  }

  let trenutniStatus = renderStatus(racun.status);

  const [valueStatus, setValueStatus] = useState();

  const options = [
    { value: 'placen', label: 'Plaćen' },
    { value: 'nenaplativ', label: 'Nenaplativ' },
    { value: 'nijeplacen', label: 'Nije Plaćen' },
  ];

  const predracunOptions = [
    { value: 'poslat', label: 'Poslat' },
    { value: 'kreiran', label: 'Kreiran' },
  ];

  const selectStyle = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#F3F4F6',
      borderRadius: 4,
      height: '45px',
      width: '150px',
      minHeight: 'unset',
    }),
  };

  let statusLabel = options.find((option) => option.value === racun.status);
  let predracunStatusLabel = predracunOptions.find((option) => option.value === racun.status);

  const getPovratak = () => {
    dispatch(getRacuni({tip: 'racun'}));
    if (tip === 'racun') {
      return (
          <Link to={RACUNI.INDEX} className="back-link df">
            <LinkSvg /> <p>Povratak na Račune</p>
          </Link>
      )
    } else if (tip === 'ulazniRacun') {
      return (
        <Link to={ULAZNI_RACUNI.INDEX} className="back-link df">
          <LinkSvg /> <p>Povratak na Ulazne račune</p>
        </Link>
      )
    } else {
      return (
          <Link to={PREDRACUNI.INDEX} className="back-link df">
            <LinkSvg /> <p>Povratak na Predračune</p>
          </Link>
      )
    }
  }

  const getTip = (redni_broj) => {
    if (racun.korektivni_racun) {
      return 'Korektivni račun';
    } else if (racun.tip_racuna === 'avans') {
      return 'Avansni račun';
    } else if (racun.tip_racuna === 'knjizno') {
      return 'Knjižno odobrenje';
    } else if (tip === 'ulazniRacun') {
      return 'Ulazni račun'
    } else if (! redni_broj) {
      return racun.tip_racuna === 'racun' ? 'Privremeni račun' : 'Predračun';
    }

    return racun.tip_racuna === 'racun' ? 'Račun' : 'Predračun';
  }

  const getButtons = (redni_broj) => {
    if (! redni_broj && tip === 'racun') {
      return (
          <>
            <button
                className="btn btn__primary mob-mb-20 ml-m mob-ml-0"
                onClick={(e) => handleFiskalizuj(e, racun.id)}
            >
              <svg className="icon icon__light lg mr-xs" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              Fiskalizuj
          </button>

          <button
              className="btn btn__primary mob-mb-20 ml-m mob-ml-0"
              onClick={handlePrint}
          >
            <svg
                className="icon icon__light lg mr-xs"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
              <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
              />
            </svg>
              Štampaj
           </button>
        </>
      )
    }

    return (
        <button
            className="btn btn__primary mob-mb-20 ml-m mob-ml-0"
            onClick={handlePrint}
        >
          <svg
              className="icon icon__light lg mr-xs"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
          >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
            />
          </svg>
          Štampaj
        </button>
    )
  }

  const renderIkofReferenca = () => {
    if (racun.ikof_referenca?.length && racun.tip_racuna !== 'avans') {
      return (
          <>
            <a
                href={`/racuni/bezgotovinski/show/${racun.ikof_referenca[0].id}`}
                target="_blank"
            >
            <p className="btn btn__link success">
              {racun.ikof_referenca[0].redni_broj}/
              <Moment locale="me" format="YYYY">
                {racun.ikof_referenca[0].datum_izdavanja}
              </Moment>
                <ExternalLinkIcon />
            </p>
            </a>
          </>
      )
    }

    return null;
  }

  const renderVezaTekst = () => {
    if (racun.ikof_referenca?.length && racun.tip_racuna !== 'avans') {
      return (
          <p className="txt-light">Veza</p>
      )
    }

    return null;
  }

  function izmijeniOpis() {
    racuniService
      .updateOpis({ opis: opisRef.current.value, id: racun.id })
      .then((data) => {
        toast.success(
            `Uspješno je izmjenjena napomena za račun sa ID-jem: ${racun.id}`,
            toastSettings
        );
        dispatch(getRacun(racun.id));
      })
      .catch((err) => {
        toast.error(
            `Neuspješno je izmjenjen status za račun sa ID-jem: ${racun.id}`,
            toastSettings
        );
      });
    setEditOpis(false);
  }

  return (
    <>
      <div className="screen-content">
        {getPovratak()}
        <div className="title">
          <h1 className="heading-primary">
            {getTip(racun.redni_broj)}: { racun.redni_broj === 0 ? '' : racun.redni_broj }/
            <Moment locale="me" format="YYYY">
              {racun.datum_fiskalizacije ? racun.datum_fiskalizacije : racun.datum_izdavanja}
            </Moment>
          </h1>

          {!editMode && (
            <div className="df jc-end">
              {racun.status !== 'storniran' && racun.status !== 'korektivni' && racun.status !== 'avansni' && racun.status !== 'knjizno' && (
                  <Select
                      options={tip === 'racun' || tip === 'ulazniRacun' ? options : predracunOptions}
                      name="status"
                      onChange={(option) => {
                        setValueStatus(option);
                        racuniService
                            .updateStatus({ status: option.value, id: racun.id, ikof: racun.ikof, jikr: racun.jikr })
                            .then((data) => {
                              toast.success(
                                  `Uspješno je izmijenjen status računa: ${racun.id}`,
                                  toastSettings
                              );
                              dispatch(getRacun(racun.id));
                            })
                            .catch((err) => {
                              toast.error(
                                  `Nije uspjela izmjena statusa računa: ${racun.id}. Pokušajte ponovo za nekoliko minuta.`,
                                  toastSettings
                              );
                            });
                      }}
                      value={valueStatus ? valueStatus : statusLabel || predracunStatusLabel}
                      styles={selectStyle}
                  />
              )}
              {getButtons(racun.redni_broj)}
            </div>
          )}
        </div>
      </div>
      <div className="main-content__box">
        <div style={{ display: 'none' }}>
          <BezgotovinskiShowTemplate
            ref={componentRef}
            ikof={racun.ikof}
            qr_url={racun.qr_url}
            bojaKlasa={trenutniStatus.color}
            itemStatus={trenutniStatus.value}
            redni_broj={racun.redni_broj}
            jikr={racun.jikr}
            broj_racuna={racun.broj_racuna}
            vrsta_racuna={racun.vrsta_racuna}
            nacin_placanja={racun.nacin_placanja}
            status={racun.status}
            preduzece={racun.preduzece}
            datum_izdavanja={racun.datum_izdavanja}
            datum_za_placanje={racun.datum_za_placanje}
            opis={racun.opis}
            partner={racun.partner}
            created_at={racun.created_at}
            stavke={stavke}
            ukupnoBezPdvIpopusta={ukupnoBezPdvIpopusta}
            ukupniPdv={ukupniPdv}
            ukupnoBezPdv={ukupnoBezPdv}
            ukupniPopust={racun.ukupna_cijena_sa_pdv - racun.ukupna_cijena_sa_pdv_popust}
            ukupnoSaPdvIpopusta={ukupnoSaPdvIpopusta}
            popust_ukupno={racun.popust_ukupno}
            ukupna_cijena_bez_pdv={racun.ukupna_cijena_bez_pdv}
            ukupna_cijena_sa_pdv={racun.ukupna_cijena_sa_pdv}
            ukupna_cijena_bez_pdv_popust={racun.ukupna_cijena_bez_pdv_popust}
            ukupna_cijena_sa_pdv_popust={racun.ukupna_cijena_sa_pdv_popust}
            ukupan_iznos_pdv={racun.ukupan_iznos_pdv}
            user={user}
            tip_racuna={getTip(racun.redni_broj)}
            iznos_povrata_sa_pdv={racun.iznos_povrata_sa_pdv ? racun.iznos_povrata_sa_pdv : 0}
          />
        </div>

        <>
          <div className="invoice" style={{ width: '100%' }}>
            <div className="invoice__header">
              <div className="row">
                {racun.preduzece && racun.preduzece.logotip ? (
                  <div className="col-md-4">
                    <p className="">
                      <img
                        src={
                          racun.preduzece && racun.preduzece.logotip
                            ? BASE_URL.slice(0, -3) +
                              'logotipi/' +
                              racun.preduzece.logotip
                            : noLogo
                        }
                        alt="logo"
                        style={{ maxWidth: 250, maxHeight: 200 }}
                      />
                    </p>
                  </div>
                ) : (
                  ''
                )}
                <div className="col-md-4">
                  <p className="txt-light">
                    {racun.preduzece && racun.preduzece.kratki_naziv
                      ? racun.preduzece.kratki_naziv + ' ' + racun.preduzece.oblik_preduzeca
                      : ''}
                  </p>
                  <p className="txt-light">
                    {racun.preduzece && racun.preduzece.djelatnost
                      ? racun.preduzece.djelatnost
                      : ''}
                  </p>
                  <p className="txt-light">
                    {racun.preduzece && racun.preduzece.opis ? racun.preduzece.opis : ''}
                  </p>
                  <p className="txt-light">
                    {racun.preduzece && racun.preduzece.adresa ? racun.preduzece.adresa : ''}
                  </p>
                  <p className="txt-light">
                    {racun.preduzece && racun.preduzece.grad ? racun.preduzece.grad : ''}, &nbsp;
                    {racun.preduzece && racun.preduzece.drzava ? racun.preduzece.drzava : ''}
                  </p>
                  <p className="txt-light">
                    {racun.preduzece && racun.preduzece.telefon ? racun.preduzece.telefon : ''},
                    &nbsp;
                    {racun.preduzece && racun.preduzece.fax ? racun.preduzece.fax : ''}
                  </p>
                  <p className="txt-light">
                    {racun.preduzece && racun.preduzece.email ? racun.preduzece.email : ''} &nbsp;
                  </p>
                  <p className="txt-light">
                    {racun.preduzece && racun.preduzece.website ? racun.preduzece.website : ''}{' '}
                    &nbsp;
                  </p>
                </div>
                <div className="col-md-4">
                  <div className="df jc-sb">
                    <div className="df fd-column">
                      <p className="txt-light">
                        {racun.preduzece && racun.preduzece.pib ? 'PIB' : ''}
                      </p>
                      <p className="txt-light">
                        {racun.preduzece && racun.preduzece.pdv ? 'PDV' : ''}
                      </p>
                      <p className="txt-light">
                        {racun.preduzece && racun.preduzece.iban ? 'IBAN' : ''}
                      </p>
                      <p className="txt-light">
                        {racun.preduzece && racun.preduzece.bic_swift ? 'BIC/SWIFT' : ''}
                      </p>
                    </div>
                    <div className="df fd-column">
                      <p className="txt-right">
                        {racun.preduzece && racun.preduzece.pib ? racun.preduzece.pib : ''}
                      </p>
                      <p className="txt-right">
                        {racun.preduzece && racun.preduzece.pdv ? racun.preduzece.pdv : ''}
                      </p>
                      <p className="txt-right">
                        {racun.preduzece && racun.preduzece.iban ? racun.preduzece.iban : ''}
                      </p>
                      <p className="txt-right">
                        {racun.preduzece && racun.preduzece.bic_swift
                          ? racun.preduzece.bic_swift
                          : ''}
                      </p>
                    </div>
                  </div>
                  {/* {preduzece && preduzece.logotip ? ( */}
                  <p className="txt-right">{ziroRacuni()}</p>
                  {/* ) : ''} */}
                </div>
                {/* {preduzece && !preduzece.logotip ? ( */}
                {/* <div className="col-md-4">
                  <p className="txt-right">{ziroRacuni()}</p>
                </div> */}
                {/* ) : ''} */}
              </div>

              <div className="mt-50">
                <div className="row">
                  <div className="col-md-7">
                    <h2 className="heading-secondary">
                      {getTip(racun.redni_broj)}: {racun.redni_broj === 0 ? '' : racun.redni_broj }/
                      <Moment locale="me" format="YYYY">
                        {racun.datum_izdavanja}
                      </Moment>
                      <span className={`${trenutniStatus.color} ml-m vm`}>{trenutniStatus.value}</span>
                    </h2>
                    <p className="mb-20">
                      {racun.preduzece && racun.preduzece.grad ? racun.preduzece.grad : ''},
                      &nbsp;
                      {racun.datum_izdavanja && (
                        <Moment locale="me" format="DD. MMM YYYY.">
                          {racun.datum_izdavanja}
                        </Moment>
                      )}
                      {' / Rok za plaćanje: '}
                      {racun.datum_za_placanje && (
                        <Moment locale="me" format="DD. MMM YYYY.">
                          {racun.datum_za_placanje}
                        </Moment>
                      )}{' '}
                    </p>

                    <div className="row">
                      {/* ------------------ QR CODE ------------------ */}
                      {racun.jikr && racun.ikof ? (
                        <div className="col-md-3">
                          <QRCode value={racun.qr_url} size="128" />{' '}
                        </div>
                      ) : null}
                      {/*------------------ QR CODE ------------------*/}

                      <div className="col-md-9">
                        <div className="df">
                          <div className="df fd-column mr-m">
                            <p className="txt-light">{racun.jikr ? 'JIKR' : ''}</p>
                            <p className="txt-light">{racun.ikof ? 'IKOF' : ''}</p>
                            <p className="txt-light">
                              {racun.broj_racuna ? 'Broj' : ''}
                            </p>
                            <p className="txt-light">
                              {racun.created_at ? 'Datum' : ''}
                            </p>
                            <p className="txt-light">
                              {racun.nacin_placanja ? 'Vrsta' : ''}
                            </p>
                            {renderVezaTekst()}

                            {racun.ikof_reference?.length ? (
                                <p className="txt-light">Veze</p>
                            ) : null}
                          </div>
                          <div className="df fd-column">
                            <p>{racun.jikr ? racun.jikr : ''}</p>
                            <p>{racun.ikof ? racun.ikof : ''}</p>
                            <p>{racun.broj_racuna ? racun.broj_racuna : ''}</p>
                            <p>
                              {racun.created_at ? (
                                <Moment
                                  locale="me"
                                  format="Do MMMM YYYY, HH:mm:ss"
                                >
                                  {racun.created_at}
                                </Moment>
                              ) : (
                                ''
                              )}
                            </p>
                            <p>
                              {racun.nacin_placanja
                                ? racun.vrsta_racuna + ' / ' + racun.nacin_placanja
                                : ''}
                            </p>

                            {renderIkofReferenca()}

                            {racun.ikof_reference?.length ? (
                              racun.ikof_reference.map((racun) =>
                                <a
                                    className="btn btn__link b-mob-mb-20"
                                    href={`/racuni/bezgotovinski/show/${racun.id}`}
                                    target="_blank"
                                >
                                <p className="btn btn__link success">
                                  {racun.redni_broj}/
                                  <Moment locale="me" format="YYYY">
                                    {racun.datum_izdavanja}
                                  </Moment>
                                    <ExternalLinkIcon />
                                </p>
                                </a>
                              )
                            ) : null}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="invoice__header--box">
                      <h2 className="heading-secondary">
                        {racun.partner && racun.partner.preduzece_partner
                          ? racun.partner?.preduzece_partner?.kratki_naziv
                          : racun.partner?.fizicko_lice?.ime +
                            ' ' +
                            racun.partner?.fizicko_lice?.prezime}
                      </h2>
                      <div className="df jc-sb">
                        <div className="df fd-column">
                          <p className="txt-light">
                            {racun.partner && racun.partner.preduzece_partner
                              ? ''
                              : 'JMBG: '}
                          </p>
                          <p className="txt-light">
                            {racun.partner && racun.partner?.preduzece_partner?.pib
                              ? 'PIB: '
                              : ''}
                          </p>
                          <p className="txt-light">
                            {racun.partner && racun.partner?.preduzece_partner?.pdv
                              ? 'PDV: '
                              : ''}
                          </p>
                          <p className="txt-light">
                            {racun.partner &&
                            racun.partner?.preduzece_partner?.adresa &&
                            racun.partner?.preduzece_partner?.grad
                              ? 'Adresa: '
                              : ''}
                          </p>
                          <p className="txt-light">
                            {racun.partner && racun.partner?.preduzece_partner?.drzava
                              ? 'Država: '
                              : ''}
                          </p>
                        </div>
                        <div className="df fd-column">
                          <p className="txt-right">
                            {racun.partner && racun.partner.preduzece_partner
                              ? ''
                              : racun.partner?.fizicko_lice?.jmbg}
                          </p>
                          <p className="txt-right">
                            {racun.partner && racun.partner?.preduzece_partner?.pib
                              ? racun.partner?.preduzece_partner?.pib
                              : ''}
                          </p>
                          <p className="txt-right">
                            {racun.partner && racun.partner?.preduzece_partner?.pdv
                              ? racun.partner?.preduzece_partner?.pdv
                              : ''}
                          </p>
                          <p className="txt-right">
                            {racun.partner &&
                            racun.partner?.preduzece_partner?.adresa &&
                            racun.partner?.preduzece_partner?.grad
                              ? racun.partner?.preduzece_partner?.adresa +
                                ', ' +
                                racun.partner?.preduzece_partner?.grad
                              : ''}
                          </p>
                          <p className="txt-right">
                            {racun.partner && racun.partner?.preduzece_partner?.drzava
                              ? racun.partner?.preduzece_partner?.drzava
                              : ''}
                          </p>
                        </div>
                        {/* TODO: ubaciti prikaz ziro racuna partnera ili ne prikazivati ako  ih nema */}
                        {/* <div className="df fd-column">
                          <p className="txt-right">{ziroRacuniPartner()}</p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div ref={testRef} className="table-wrapper">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <span className="heading-quaternary">Opis</span>
                    </th>
                    <th>
                      <span className="heading-quaternary">Kol.</span>
                    </th>
                    <th>
                      <span className="heading-quaternary nowrap">Bez PDV</span>
                    </th>
                    <th>
                      <span className="heading-quaternary nowrap">sa PDV</span>
                    </th>
                    <th>
                        {racun.tip_racuna === 'knjizno' ? (
                            <span className="heading-quaternary">Povrat sa PDV</span>
                        ) : (
                            <span className="heading-quaternary">Pop. sa PDV</span>
                        )}
                    </th>
                    <th>
                      <span className="heading-quaternary nowrap">
                        Uk. bez PDV
                      </span>
                    </th>
                    <th>
                      <span className="heading-quaternary nowrap">
                        Uk. sa PDV
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <List
                    data={stavke ? stavke : []}
                    renderItem={BezgotovinskiTableRow}
                  />
                </tbody>
              </table>
            </div>
            <div className="invoice__footer">
              <div className="row mb-30">
                <div className="col-md-8 col-sm-7">
                  <div className="table-wrapper">
                    <table className="table">
                      <BezgotovinskiPoreziPreview stavke={stavke} />
                    </table>
                  </div>
                  {! racun.preduzece?.pdv_obveznik && (
                    <p className="mt-30">
                      Poreski obveznik nije u sistemu PDV-a.
                    </p>
                  )}
                </div>
                <div className="col-md-4 col-sm-5">
                  <div className="df jc-sb">
                    <div className="df fd-column">
                      <p className="fw-500">
                        {/* TODO: ovo je cijena bez PDV-a i sa popustom */}
                        {ukupnoBezPdvIpopusta && 'Bez PDV-a:'}
                      </p>

                      {/* <p className="fw-500">
                        {ukupnoBezPdv && 'Ukupno bez popusta:'}
                      </p> */}
                      {Number(racun.popust_ukupno) > 0 && (
                        <p className="fw-500">
                          {Number(racun.popust_ukupno) > 0 > 0 && 'Popust sa PDV-om:'}
                        </p>
                      )}
                      <p className="fw-500">
                        {racun.ukupan_iznos_pdv && 'PDV:'}
                      </p>
                      <p className="fw-500">
                        {racun.ukupna_cijena_sa_pdv_popust && 'Total:'}
                      </p>
                    </div>
                    <div className="df fd-column">
                      <p className="fw-500 txt-right nowrap">
                        {racun.ukupna_cijena_bez_pdv_popust
                          ? formatirajCijenu(racun.ukupna_cijena_bez_pdv_popust)
                          : ''}
                      </p>
                      {racun.ukupna_cijena_sa_pdv !== racun.ukupna_cijena_sa_pdv_popust && (
                        <p className="txt-right cd fw-500">
                          {'-'}
                          {Number(
                            racun.ukupna_cijena_sa_pdv - racun.ukupna_cijena_sa_pdv_popust
                          ).toFixed(2)}{' '}
                          <span className="txt-up">&euro;</span>
                        </p>
                      )}
                      <p className="fw-500 txt-right">
                        {racun.ukupan_iznos_pdv
                          ? formatirajCijenu(racun.ukupan_iznos_pdv)
                          : ''}
                      </p>
                      <p className="fw-500 txt-right">
                        {racun.ukupna_cijena_sa_pdv_popust
                          ? formatirajCijenu(racun.ukupna_cijena_sa_pdv_popust)
                          : ''}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  {racun.opis && editOpis && (
                    <>
                      <p className="fw-500">Napomena:</p>
                      <textarea
                        defaultValue={racun.opis}
                        ref={opisRef}
                        cols="30"
                        rows="6"
                        className="form__textarea df mb-12"
                      />
                      <button
                        className="btn btn__primary mb-3"
                        onClick={izmijeniOpis}
                      >
                        Sačuvaj
                      </button>
                    </>
                  )}

                  {racun.opis && ! editOpis && (
                    <>
                      <p className="fw-500">Napomena:</p>
                      <p className="txt-light mb-12 white-space-pre-line">
                        {racun.opis}
                      </p>
                      <button
                        className="btn btn__primary mb-3"
                        onClick={() => setEditOpis(true)}
                      >
                        <svg
                          className="icon icon__light md mr-xs"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          />
                        </svg>
                        Izmijeni napomenu
                      </button>
                    </>
                  )}
                </div>
              </div>

              {getDodatniNacinPlacanja()}

              <div className="row">
                <div className="col-md-5">
                  <p>
                    {/* TODO: prikazati korisnika koji je kreirao račun a ne trenutnog */}
                    {user?.ime && user?.prezime && (
                      <p>
                        {tip === 'racun' || tip === 'ulazniRacun' ? 'Račun' : 'Predračun'} izdao: {user?.ime + ' ' + user?.prezime} /{' '}
                        {user?.kod_operatera}
                      </p>
                    )}
                  </p>
                  <hr className="mt-50 bd__bottom" />
                </div>
                <div className="col-md-5 offset-md-2">
                  <p>{tip === 'racun' || tip === 'ulazniRacun' ? 'Račun' : 'Predračun'} Preuzeo:</p>
                  <hr className="mt-50 bd__bottom" />
                </div>
              </div>

              <p className="txt-light">
                PostFiskal by Restart IT d.o.o. /{' '}
                {user?.preduzeca[0]?.software_kod
                  ? user?.preduzeca[0]?.software_kod
                  : ''}
              </p>
            </div>
          </div>
        </>
      </div>

      {tip === 'ulazniRacun' && (
        <BezgotovinskiFiles files={racun?.dokumenta} />
      )}

      {editMode && (
        <Formik
          // initialValues={{
          //   ikof,
          //   jikr,
          //   broj_racuna,
          //   status,
          //   preduzece,
          //   opis,
          //   partner,
          //   created_at,
          //   stavke,
          //   ukupna_cijena_bez_pdv,
          //   ukupna_cijena_sa_pdv,
          //   ukupan_iznos_pdv,
          //   status_placanja,
          //   iznos_uplate,
          //   datum_izdavanja,
          // }}
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ values }) => (
            <Form>
              <div className="main-content__box">
                <div className="form">
                  <div className="content pt-12"></div>
                  <BezgotovinskiStatusPodsjetnici />
                  <div className="form__footer">
                    <button
                      onClick={() => handleSubmit(values)}
                      className="btn btn__primary"
                    >
                      Sačuvaj izmjene
                    </button>
                    <button className="btn btn__link ml-m">
                      <Link to={RACUNI.INDEX}>Obustavi</Link>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default BezgotovinskiPreview;
