import { call, put } from 'redux-saga/effects';
import { setGlobalError } from '../actions/ErrorActions';

import 'react-toastify/dist/ReactToastify.css';
import { setZavisniTrosak, setZavisniTroskovi } from '../actions/ZavisniTroskoviActions';
import { zavisniTroskoviService } from '../../services/ZavisniTroskoviService';

export function* zavisniTroskoviGet({ payload }) {
  try {
    const res = yield call(zavisniTroskoviService.getZavisniTroskovi, payload);
    yield put(setZavisniTroskovi(res.data));
  } catch (error) {
    yield put(setGlobalError(error.message));
  }
}

export function* zavisniTrosakGet({ payload }) {
  try {
    const res = yield call(zavisniTroskoviService.getZavisniTrosak, payload);
    yield put(setZavisniTrosak(res.data));
  } catch (error) {
    yield put(setGlobalError(error.message));
  }
}
