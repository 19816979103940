import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import ReactDatePicker from 'react-datepicker';

import {
  POSALJI_PODSJETNIK,
  VRIJEME_PODSJETNIKA,
  DAN_SLANJA_NEDELJA,
} from '../../../constants/racuni';
import DropDownStatic from '../../shared/forms/DropDownStatic';
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { authPreduzeceSelector } from "../../../store/selectors/PreduzecaSelector";
import { toast } from "react-toastify";

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const BezgotovinskiStatusPodsjetnici = (props = null) => {
  const { values, setFieldValue } = useFormikContext();
  const { params } = useRouteMatch();
  const pathName = window.location.pathname;

  const [tekstPodsjetnik, setTekstPodsjetnik] = useState('bez_slanja');
  const [vrijemePodsjetnik, setVrijemePodsjetnik] = useState('');

  const authPreduzece = useSelector(authPreduzeceSelector());

  const DAN_SLANJA_MJESEC = [];
  const VRIJEME = [];

  useEffect(() => {
    if (props?.odabraniRacun?.tip_slanja) {
      setVrijemePodsjetnik(props.odabraniRacun.tip_slanja);
    }

    if (props?.odabraniRacun?.podsjetnici) {
      setTekstPodsjetnik(props?.odabraniRacun?.podsjetnici[0]?.vrijeme_slanja)
    }
  }, [props.odabraniRacun]);

  for (let i = 0; i < 24; i++) {
    VRIJEME.push({ value: i, label: i < 10 ? `0${i}:00` : `${i}:00` });
  }

  if (vrijemePodsjetnik === 'mjesecni') {
    for (let i = 1; i < 32; i++) {
      DAN_SLANJA_MJESEC.push({ value: i, label: `Svakog ${i}. u mjesecu` });
    }
  }

  const setVrijemeSlanjaPodsjetnik = (option) => {
    if (authPreduzece.najjaci_paket === 1) {
      toast.error('Ova usluga nije dostupna korisnicima osnovnog paketa!',
          toastSettings
      );
      setFieldValue('vrijeme_slanja', 'bez_slanja');
      return;
    }
    setTekstPodsjetnik(option.value);
  }

  const getVrijemeSlanja = (vrijemeSlanja) => {
    switch (vrijemeSlanja) {
      case '2_dana_prije_isteka_roka':
        return {label: 'Pošalji 2 dana prije roka plaćanja'}
      case '5_dana_prije_isteka_roka':
        return {label: 'Pošalji 5 dana prije roka plaćanja'}
      case '10_dana_prije_isteka_roka':
        return {label: 'Pošalji 10 dana prije roka plaćanja'}
      case 'dan_isteka_roka':
        return {label: 'Pošalji na dan isteka roka plaćanja'}
      default:
    }
  }

  // automatsko slanje
  const getDanUSedmici = (dan) => {
    switch (dan) {
      case '1':
        return {label: 'Svakog ponedjeljka'}
      case '2':
        return {label: 'Svakog utorka'}
      case '3':
        return {label: 'Svake srijede'}
      case '4':
        return {label: 'Svakog četvrtka'}
      case '5':
        return {label: 'Svakog petka'}
      default:
    }
  }

  const getTipSlanja = (tip) => {
    switch (tip) {
      case 'dnevni':
        return 'Svaki dan'
      case 'sedmicni':
        return 'Svake nedelje'
      case 'mjesecni':
        return 'Svakog mjeseca'
      case 'godisnji':
        return 'Svake godine'
      default:
    }
  }

  const getDefaultValueDanUSedmici = (odabraniRacun) => {
    if (odabraniRacun?.datum_slanja && odabraniRacun?.tip_slanja === 'sedmicni') {
      return getDanUSedmici(odabraniRacun.datum_slanja);
    }

    return DAN_SLANJA_NEDELJA[0];
  }

  const getDefaultValueDanUMjesecu = (odabraniRacun) => {
    if (odabraniRacun?.datum_slanja && odabraniRacun?.tip_slanja === 'mjesecni') {
      return {label: `Svakog ${odabraniRacun.datum_slanja}. u mjesecu`};
    }

    return DAN_SLANJA_MJESEC[0];
  }

  const getDefaultValueDatum = (odabraniRacun) => {
    if (odabraniRacun?.datum_slanja && odabraniRacun?.tip_slanja === 'godisnji') {
      return new Date(odabraniRacun.datum_slanja);
    }

    return new Date();
  }

  const setTipSlanja = (option) => {
    setFieldValue('datum_slanja', null)
    setFieldValue('tip_slanja', option.value)
    setVrijemePodsjetnik(option.value)
  }

  const getStatusi = () => {
    if (pathName === '/predracuni/create' || pathName === '/predracuni/edit/' + params.id) {
      return (
          <div className="col-md-3">
            <div className="form__label">Status</div>
            <div className="form__group">
              <div className="form__radio-group">
                <input
                    type="radio"
                    className="form__radio-input"
                    id="notPaid"
                    value="nijeplacen"
                    name="status"
                    checked={values && values.status === 'kreiran'}
                    onChange={() => setFieldValue('status', 'kreiran')}
                    defaultChecked
                />
                <label htmlFor="notPaid" className="form__radio-label">
                  <span className="form__radio-button"></span>
                  Kreiran
                </label>
              </div>
              <div className="form__radio-group">
                <input
                    type="radio"
                    className="form__radio-input"
                    id="uncollectible"
                    value="nenaplativ"
                    name="status"
                    checked={values && values.status === 'poslat'}
                    onChange={() => setFieldValue('status', 'poslat')}
                />
                <label htmlFor="uncollectible" className="form__radio-label">
                  <span className="form__radio-button"></span>
                  Poslat
                </label>
              </div>
            </div>
          </div>
      )
    } else {
      return (
          <div className="col-md-3">
            <div className="form__label">Status</div>
            <div className="form__group">
              <div className="form__radio-group">
                <input
                    type="radio"
                    className="form__radio-input"
                    id="paid"
                    value="placen"
                    name="status"
                    checked={values && values.status === 'placen'}
                    onChange={() => setFieldValue('status', 'placen')}
                />
                <label htmlFor="paid" className="form__radio-label">
                  <span className="form__radio-button"></span>
                  Plaćen
                </label>
              </div>
              <div className="form__radio-group">
                <input
                    type="radio"
                    className="form__radio-input"
                    id="notPaid"
                    value="nijeplacen"
                    name="status"
                    checked={values && values.status === 'nijeplacen'}
                    onChange={() => setFieldValue('status', 'nijeplacen')}
                    defaultChecked
                />
                <label htmlFor="notPaid" className="form__radio-label">
                  <span className="form__radio-button"></span>
                  Nije plaćen
                </label>
              </div>
              <div className="form__radio-group">
                <input
                    type="radio"
                    className="form__radio-input"
                    id="uncollectible"
                    value="nenaplativ"
                    name="status"
                    checked={values && values.status === 'nenaplativ'}
                    onChange={() => setFieldValue('status', 'nenaplativ')}
                />
                <label htmlFor="uncollectible" className="form__radio-label">
                  <span className="form__radio-button"></span>
                  Nenaplativ
                </label>
              </div>
            </div>
          </div>
      )
    }
  }

  const renderPodsjetnici = () => {
    if (pathName !== '/predracuni/create' && pathName !== '/predracuni/edit/' + params.id && values?.tip_racuna !== 'avans' && values?.nacin_placanja !== 'ADVANCE' && values?.tip_racuna !== 'knjizno' && pathName !== '/ulazni-racuni/create' && pathName !== '/ulazni-racuni/edit/' + params.id) {
      return (
          <>
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <h2 className="heading-secondary mb-0">Podsjetnici za plaćanje</h2>
                  <span className="tag tag__neutral" title="Opcija dostupna korisnicima Start paketa">Start</span>
                  <p className="txt-light b-mob-mb-20">
                    Možete izabrati da se podsjetnici za plaćanje šalju kupcu ukoliko
                    račun nije označen kao plaćen.
                  </p>
                </div>
                <div className="col-md-4">
                  <div className="form__group">
                    <label className="form__label">Pošalji podsjetnik</label>
                    <DropDownStatic
                        name="vrijeme_slanja"
                        options={POSALJI_PODSJETNIK}
                        defaultValue={props?.odabraniRacun?.podsjetnici ? getVrijemeSlanja(props?.odabraniRacun?.podsjetnici[0]?.vrijeme_slanja) : POSALJI_PODSJETNIK[0]}
                        onChangeExtra={(option) => setVrijemeSlanjaPodsjetnik(option)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  {tekstPodsjetnik !== 'bez_slanja' ? (
                      <div className="form__group">
                        <label className="form__label">Tekst podsjetnika</label>
                        <textarea
                            name="tekst"
                            id=""
                            cols="30"
                            rows="6"
                            className="form__input"
                            defaultValue={props?.odabraniRacun?.podsjetnici[0] ? props?.odabraniRacun?.podsjetnici[0].tekst : ''}
                            onBlur={(event) =>
                                setFieldValue('tekst', event.target.value)
                            }
                        ></textarea>
                      </div>
                  ) : null}
                </div>
              </div>
            </div>
        <hr />
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h2 className="heading-secondary mb-0">
                Automatizovano slanje periodičnih računa
              </h2>
              <span className="tag tag__neutral" title="Opcija dostupna korisnicima Start paketa">Start</span>
              <p className="txt-light b-mob-mb-20">
                Možete izabrati da se novi račun sa gore navedenim stavkama šalje
                kupcu svakog dana, nedjelje, mjeseca ili godine za usluge koje se
                ponavljaju tako da ne morate ručno kreirati svaki put novi račun
              </p>
            </div>
            <div className="col-xl-4">
              <div className="form__group">
                <label className="form__label">
                  Kreiraj i pošalji novi račun
                </label>

                <DropDownStatic
                    name="tip_slanja"
                    options={VRIJEME_PODSJETNIKA}
                    onChangeExtra={(option) => setTipSlanja(option)}
                    defaultValue={{label: props?.odabraniRacun?.tip_slanja ? getTipSlanja(props?.odabraniRacun?.tip_slanja) : 'Select...'}}
                />
              </div>
            </div>
            {vrijemePodsjetnik !== 'dnevni' && vrijemePodsjetnik ? (
                <div className="col-xl-4">
                  <div className="form-group mb-20">
                    <label className="form__label">Dan za slanje</label>
                    {vrijemePodsjetnik === 'sedmicni' ? (
                        <DropDownStatic
                            name="datum_slanja"
                            options={DAN_SLANJA_NEDELJA}
                            defaultValue={getDefaultValueDanUSedmici(props.odabraniRacun)}
                            onChangeExtra={(option) => setFieldValue('datum_slanja', option.value)}
                        />
                    ) : null}

                    {vrijemePodsjetnik === 'mjesecni' ? (
                        <DropDownStatic
                            name="datum_slanja"
                            options={DAN_SLANJA_MJESEC}
                            defaultValue={getDefaultValueDanUMjesecu(props.odabraniRacun)}
                            onChangeExtra={(option) => setFieldValue('datum_slanja', option.value)}
                        />
                    ) : null}

                    {vrijemePodsjetnik === 'godisnji' ? (
                        <ReactDatePicker
                            selected={values.datum_slanja ? new Date(values.datum_slanja) : new Date()}
                            onChange={(date) =>
                                setFieldValue('datum_slanja', date)
                            }
                            className="form__input w-100"
                            placeholderText="Datum Slanja"
                            dateFormat="dd/MM/yyyy"
                        />
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label className="form__label">Vrijeme slanja</label>
                    <DropDownStatic
                        name="vrijeme"
                        options={VRIJEME}
                        defaultValue={props?.odabraniRacun?.vrijeme ? {label: props?.odabraniRacun?.vrijeme < 10 ? `0${props?.odabraniRacun?.vrijeme}:00` : `${props?.odabraniRacun?.vrijeme}:00`} : VRIJEME[0]}
                        onChangeExtra={(option) => setFieldValue('vrijeme', option.value)}
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label className="form__label">Važi do dana</label>
                    <ReactDatePicker
                        selected={values.vazi_do ? new Date(values.vazi_do) : new Date()}
                        onChange={(date) =>
                            setFieldValue('vazi_do', date)
                        }
                        className="form__input w-100"
                        placeholderText="Važi do"
                        dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
            ) : null}

            {vrijemePodsjetnik === 'dnevni' && vrijemePodsjetnik ? (
                <div className="col-xl-4">
                  <div className="form-group">
                    <label className="form__label">Vrijeme slanja</label>
                    <DropDownStatic
                        name="vrijeme"
                        options={VRIJEME}
                        defaultValue={props?.odabraniRacun?.vrijeme ? {label: props?.odabraniRacun?.vrijeme < 10 ? `0${props?.odabraniRacun?.vrijeme}:00` : `${props?.odabraniRacun?.vrijeme}:00`} : VRIJEME[0]}
                        onChangeExtra={(option) => setFieldValue('vrijeme', option.value)}
                    />
                  </div>

                  <div className="form-group mt-4">
                    <label className="form__label">Važi do dana</label>
                    <ReactDatePicker
                        selected={values.vazi_do ? new Date(values.vazi_do) : new Date()}
                        onChange={(date) =>
                            setFieldValue('vazi_do', date)
                        }
                        className="form__input w-100"
                        placeholderText="Važi do"
                        dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
            ) : null}
          </div>
        </div>
        <hr/>
        </>
      )
    }
  }

  const renderStatusi = () => {
    if (values?.nacin_placanja === 'ADVANCE' && ! values?.vise_nacina_placanja) {
        return;
    }

    if (values?.tip_racuna !== 'avans' && values?.tip_racuna !== 'knjizno') {
      return (
          <>
            <div className="col-md-4">
              <h2 className="heading-secondary">Status</h2>
              {/* <p className="txt-light">Označite status računa</p> */}
            </div>
            { getStatusi() }
          </>
      )
    }
  }

  return (
    <>
      {renderPodsjetnici()}
      <div className="container">
        <div className="row">
          {renderStatusi()}
          <div className="col-md-5">
          </div>
        </div>
      </div>
    </>
  );
};

export default BezgotovinskiStatusPodsjetnici;
