import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPartneri, setPartner } from '../../store/actions/PartneriActions';
import { partnerSelector } from '../../store/selectors/PartneriSelector';
import List from '../shared/lists/List';
import PartneriTableRow from './PartneriTableRow';
import { useHistory } from "react-router-dom";
import Pagination from "../shared/lists/Pagination";

const PartneriTable = ({ partneri, page, filters }) => {
  const dispatch = useDispatch();

  const selectedPartner = useSelector(partnerSelector());

  const history = useHistory();
  const { last_page } = partneri;

  const handlePageClick = (data) => {
    history.push(`/partneri?page=${++data.selected}`);
    dispatch(getPartneri({ page: data.selected, ...filters}));
  }

  return (
    <>
      <div className="table-wrapper">
        <table className="table cursor">
          <thead>
            <tr>
              <th>
                <span className="heading-quaternary">
                  Preduzeće/Fizičko lice
                </span>
              </th>
              <th>
                <span className="heading-quaternary">PIB/JMBG</span>
              </th>
              <th>
                <span className="heading-quaternary">Telefon</span>
              </th>
              <th>
                <span className="heading-quaternary">Obriši</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <List
              data={partneri?.data}
              renderItem={PartneriTableRow}
              onItemClick={(item) => {
                dispatch(setPartner({}));
                dispatch(setPartner(item));
              }}
              selectedId={selectedPartner?.id}
              key={partneri.preduzece?.id}
            />
          </tbody>
        </table>
      </div>
      <Pagination
          pageCount={last_page}
          page={page}
          handlePageClick={handlePageClick}
      />
    </>
  );
};

export default PartneriTable;
