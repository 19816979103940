import produce from 'immer';
import {
    SET_IZVJESTAJ,
} from '../actionTypes/IzvjestajiActionTypes';

const initialState = {
    izvjestaj: {},
};

const izvjestajiReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        /*eslint-disable indent */
        switch (action.type) {
            case SET_IZVJESTAJ:
                draft.izvjestaj = action.payload;
                break;
            default:
                break;
        }
    });

export default izvjestajiReducer;
