export const STORE_GRUPA = 'STORE_GRUPA';

export const GET_GRUPE = 'GET_GRUPE';
export const SET_GRUPE = 'SET_GRUPE';

export const GET_GRUPA = 'GET_GRUPA';
export const SET_GRUPA = 'SET_GRUPA';

export const UPDATE_GRUPA = 'UPDATE_GRUPA';
export const DELETE_GRUPA = 'DELETE_GRUPA';
