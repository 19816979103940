import ApiService from './ApiService';

const ENDPOINTS = {
  ZAVISNI_TROSKOVI: '/zavisni-troskovi',
  ZAVISNI_TROSAK: '/zavisni-troskovi/{id}',
};

class ZavisniTroskoviService extends ApiService {
  getZavisniTroskovi = (params) => this.apiClient.get(ENDPOINTS.ZAVISNI_TROSKOVI, { params });

  getZavisniTrosak = (id) => this.apiClient.get(ENDPOINTS.ZAVISNI_TROSAK.replace('{id}', id));
}

export const zavisniTroskoviService = new ZavisniTroskoviService();
