import React from 'react';
import {
  formatirajCijenu,
  formatirajCijenuBezE,
} from '../../../helpers/racuni';

const BezgotovinskiTableRow = ({ item }) => {
  return (
    <tr>
      <td className="cd">
        <p className="cd fw-500">{item && item.naziv ? item.naziv : ''}</p>
        {item && item.opis
        ? item.opis
        : null}
      </td>
      <td className="cd">
        {item && item.kolicina
          ? Number(item.kolicina).toString() + ' ' + item.jedinica_naziv
          : ''}
      </td>
      <td>
        {item && item.jedinicna_cijena_bez_pdv ?
            Number(item.jedinicna_cijena_bez_pdv)
              .toFixed(5)
              .replace('.', ',')
          : ''}
      </td>
      <td>
        {item && item.jedinicna_cijena_bez_pdv
          ?
            Number(item.cijena_sa_pdv).toFixed(5).replace('.', ',')
          : ''}
      </td>
      <td className="cd">
        {item.iznos_povrata_sa_pdv < 0 ? (
            item.iznos_povrata_sa_pdv
        ) : (
            item && item.popust_procenat && Number(item.popust_procenat) > 0
                ? formatirajCijenuBezE(item.popust_procenat) +
                  '% (-' +
                  formatirajCijenu(
                    (item.cijena_sa_pdv - item.cijena_sa_pdv_popust) * item.kolicina
                  ) +
                  ')'
                : item && item.popust_iznos && Number(item.popust_iznos) > 0
                ? '-' + formatirajCijenu(item.popust_iznos * item.kolicina)
                : '/ '
        )}

      </td>
      <td className="cd w-10">
        {item
          ? formatirajCijenu(item.ukupna_bez_pdv_popust)
          : ''}
      </td>
      <td className="cd w-10">
        {item
          ? formatirajCijenu(item.ukupna_sa_pdv_popust)
          : ''}
      </td>
    </tr>
  );
};

export default BezgotovinskiTableRow;
