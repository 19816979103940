import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ReactComponent as BoxCloseSvg } from '../../assets/icon/box-close.svg';
import { ReactComponent as ButtonPlusSvg } from '../../assets/icon/button-plus.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { racuniSelector } from '../../store/selectors/RacuniSelector';
import {
  setPredracun,
} from '../../store/actions/PredracuniActions';
import PredracuniTable from './PredracuniTable';
import {Link, useLocation} from 'react-router-dom';
import { PREDRACUNI } from '../../constants/routes';

import Moment from 'react-moment';
import 'moment/locale/me';

import GridLoader from 'react-spinners/GridLoader';
import { spinnerStyleGrid } from '../../constants/spinner';
import { getRacuni } from "../../store/actions/RacuniActions";

const options = [
  { value: null, label: 'Prikaži Sve' },
  { value: 'kreiran', label: 'Kreiran' },
  { value: 'poslat', label: 'Poslat' },
];

let searchParams = {};

const searchDebounced = debounce((callback) => callback(), 200);
const Predracuni = () => {
  const dispatch = useDispatch();

  const predracuni = useSelector(racuniSelector());
  const query = useLocation().search;
  const page = new URLSearchParams(query).get('page');

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [statusVisible, setStatusVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [dateStartVisible, setDateStartVisible] = useState(false);
  const [dateEndVisible, setDateEndVisible] = useState(false);

  useEffect(() => {
    dispatch(getRacuni({page, tip: 'predracun'}));
    searchParams = {};
  }, [dispatch]);

  useEffect(() => {
    if (predracuni.total > 0) dispatch(setPredracun(predracuni.data[0]));
  }, [predracuni, dispatch]);

  const handleSearch = (value) => {
    let filtered = value?.search?.replace(/[^0-9a-zA-Zžćšđč -]/gi, '');
    searchParams.search = filtered;
    dispatch(getRacuni({...searchParams, tip: 'predracun'}));
  };

  const resetDatePicker = () => {
    searchParams.startDate = null;
    searchParams.endDate = null;
    handleSearch(searchParams);
    setStartDate(null);
    setEndDate(null);
    setDateStartVisible(false);
    setDateEndVisible(false);
  };

  const resetSearch = () => {
    searchParams.search = null;
    setSearch('');
    handleSearch(searchParams);
    setSearchVisible(false);
  };

  const resetStatus = () => {
    searchParams.status = null;
    setStatus('');
    handleSearch(searchParams);
    setStatusVisible(false);
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
    setSearchVisible(true);
    const value = event.target.value;
    searchParams.search = value;
    searchDebounced(() => handleSearch(searchParams));

    if (! event.target.value) {
      setSearchVisible(false);
    }
  };

  const handleStatusChange = (selectedStatusOption) => {
    setStatus(selectedStatusOption.label);
    setStatusVisible(true);
    searchParams.status = selectedStatusOption.value;
    handleSearch(searchParams);
  };

  const handleStartDateChange = (date) => {
    setDateStartVisible(true);
    searchParams.startDate = date;
    setStartDate(date);
    handleSearch(searchParams);
  };

  const handleEndDateChange = (date) => {
    setDateEndVisible(true);
    searchParams.endDate = date;
    setEndDate(date);
    handleSearch(searchParams);
  };

  const selectStyle = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#F3F4F6',
      borderRadius: 4,
      height: '45px',
      minHeight: 'unset',
    }),
  };

  return (
    <>
      <div className="title jc-sb">
        <h1 className="heading-primary">Predračuni</h1>
        <Link exact to={PREDRACUNI.CREATE}>
          <button className="btn btn__primary">
            <ButtonPlusSvg />
            Novi predračun
          </button>
        </Link>
      </div>
      <div className="main-content__box">
        <div className="content" style={{ width: '100%' }}>
          <div className="main-content__search-wrapper df">
            <div className="df jc-sb w-100 tabp-flex-wrap mob-fd-column">
              {/* <div className="search df ai-c w-53"> */}
              <form className="search df ai-c w-45 mob-w-100 mr-15-tabp-0 tabp-w-49">
                <button className="search__button" type="submit"></button>
                <input
                  name="search"
                  placeholder="Pretraži Predračune"
                  className="search__input"
                  value={search}
                  onChange={handleChange}
                />
              </form>

              <Select
                options={options}
                onChange={handleStatusChange}
                value={status ? { label: status } : options[0]}
                className="mob-w-100 w-20 mob-mt-10 mr-15-tabp-0 tabp-w-49 no-outline"
                styles={selectStyle}
                placeholder="Status..."
              />
              <div className="df jc-sb tabp-w-100">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => handleStartDateChange(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="date-select mob-w-100 mob-mt-10"
                  placeholderText="Datum od:"
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    let actualDate = new Date(date);
                    let endOfDayDate = new Date(actualDate.getFullYear()
                        ,actualDate.getMonth()
                        ,actualDate.getDate()
                        ,23,59,59);
                    handleEndDateChange(endOfDayDate);
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="date-select mob-w-100 mob-mt-10"
                  placeholderText="Datum do:"
                />
              </div>
            </div>
            <ul className="box-wrapper">
              <li className="box visible ml-0">
                <p className="txt-light">Ukupan iznos na prikazanim predračunima</p>
                <h3 className="heading-tertiary">
                  {predracuni?.ukupna_cijena !== undefined
                      ? predracuni?.ukupna_cijena?.toFixed(2).replace('.', ',') + '€'
                      : '0,00€'}
                </h3>
              </li>

              {searchVisible && (
                  <li className={searchVisible ? 'box visible' : 'box'}>
                    <p className="txt-light">Pretraga</p>
                    <h3 className="heading-tertiary">{search}</h3>
                    <span onClick={resetSearch} className="box__close">
                    <BoxCloseSvg />
                  </span>
                  </li>
              )}

              {statusVisible && (
                  <li className={statusVisible ? 'box visible' : 'box'}>
                    <p className="txt-light">Status</p>
                    <h3 className="heading-tertiary">{status}</h3>
                    <span onClick={resetStatus} className="box__close">
                    <BoxCloseSvg />
                  </span>
                  </li>
              )}

              {dateStartVisible || dateEndVisible ? (
                  <li
                      className={
                        dateStartVisible || dateEndVisible ? 'box visible' : 'box'
                      }
                  >
                    <p className="txt-light">Datum</p>
                    <h3 className="heading-tertiary">
                      {startDate && (
                          <Moment locale="me" format="DD. MMM YYYY.">
                            {startDate}
                          </Moment>
                      )}
                      -
                      {endDate && (
                          <Moment locale="me" format="DD. MMM YYYY.">
                            {endDate}
                          </Moment>
                      )}
                    </h3>
                    <span onClick={resetDatePicker} className="box__close">
                    <BoxCloseSvg />
                  </span>
                  </li>
              ) : null}
            </ul>
          </div>
          {predracuni &&
          predracuni.data &&
          predracuni.data.length === 0 &&
          !predracuni.path ? (
              <GridLoader css={spinnerStyleGrid} size={15} />
          ) : predracuni && predracuni.data && predracuni.data.length === 0 ? (
              <div className="msg-center">
                <p> {'Nema sadržaja u listi'}</p>
              </div>
          ) : (
            <PredracuniTable predracuni={predracuni} searchParams={searchParams} page={page} />
          )}
        </div>
      </div>
    </>
  );
};
export default Predracuni;
