import React from 'react';
import { useFormikContext } from 'formik';
import { ReactComponent as DeleteIcon } from '../../../assets/icon/x-delete.svg';

import 'react-toastify/dist/ReactToastify.css';
import {
    getUkupanPopust,
    getUkupnaCijenaSaPdv,
    getUkupnaCijenaBezPdv, formatirajCijenu,
} from "../../../helpers/racuni";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const BezgotovinskiZavisniTroskoviFieldArray = ({ remove }) => {
    const { values, setFieldValue } = useFormikContext();

    const removeNiz = (ind) => {
        values.niz_zavisni_troskovi.splice(ind);
    };

    function makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }

       return result;
    }

    function handleOnDragEnd(result) {
        if (! result.destination) return;

        const items = Array.from(values.zavisni_troskovi);
        const [ reorderedItem ] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setFieldValue('zavisni_troskovi', items);
    }

    return (
        <>
            <h2 className="heading-secondary">Zavisni troškovi</h2>
            <div className="main-content__box mt-3">
                <div className="content">
                    <div className="table-wrapper">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="cd">
                                        <p className="heading-quaternary txt-left">
                                            Naziv
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary txt-left">
                                            Opis
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Broj dokumenta
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Datum izdavanja
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Datum za placanje
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary nowrap">
                                            Bez PDV
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary nowrap">
                                            PDV
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Sa PDV
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary" />
                                    </th>
                                </tr>
                            </thead>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="zavisni_troskovi">
                                    {(provided) => (
                                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                            {values.zavisni_troskovi.map((stavka, index) => {
                                                return (
                                                    <Draggable  key={stavka?.id} draggableId={stavka?.id ? stavka?.id?.toString() : makeId(20)} index={index}>
                                                        {(provided) => (
                                                            <tr {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                <td>
                                                                    <p className="cd fw-500">
                                                                        { stavka.naziv }
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mt-2 cl">
                                                                        {stavka?.opis ? stavka?.opis : ''}
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    {stavka?.broj_dokumenta}
                                                                </td>
                                                                <td>
                                                                    {new Date(stavka?.datum_izdavanja).toLocaleDateString([])}
                                                                </td>
                                                                <td>
                                                                    {new Date(stavka?.datum_za_placanje).toLocaleDateString([])}
                                                                </td>
                                                                <td>
                                                                    { formatirajCijenu(parseFloat(stavka?.cijena_bez_pdv)) }
                                                                </td>
                                                                <td>
                                                                    { formatirajCijenu(parseFloat(stavka?.pdv_iznos)) }
                                                                </td>
                                                                <td>
                                                                    { formatirajCijenu(parseFloat(stavka?.ukupna_cijena)) }
                                                                </td>
                                                                <td>
                                                                    <p
                                                                        onClick={() => {
                                                                            remove(index);
                                                                            removeNiz(index);
                                                                        }}
                                                                    >
                                                                        <span className="btn btn__close danger">
                                                                            <DeleteIcon />
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </tbody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </table>
                        <hr className="hr-main" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BezgotovinskiZavisniTroskoviFieldArray;
