import produce from 'immer';
import {
    SET_NOVOSTI,
    SET_NOVOST,
    SET_NEPROCITANE,
    SET_POSLEDNJE
} from '../actionTypes/NovostiActionTypes';

export const emptyPaginated = {
    current_page: 1,
    last_page: 1,
    total: 0,
    data: [],
};

const initialState = {
    preduzeca: { ...emptyPaginated },
    novost: {},
    neprocitane: {},
    poslednje: {}
};

const novostiReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        let id;
        /*eslint-disable indent */
        switch (action.type) {
            case SET_NOVOSTI:
                draft.novosti = action.payload;
                break;
            case SET_NOVOST:
                draft.novost = action.payload;
                break;
            case SET_NEPROCITANE:
                draft.neprocitane = action.payload;
                break;
            case SET_POSLEDNJE:
                draft.poslednje = action.payload;
                break;
            default:
                break;
        }
    });

export default novostiReducer;
